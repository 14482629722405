import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { Card, Icon, IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { useSnackbarCustom } from 'hooks/useSnackCustom';
import { TipoInformacaoAdicional } from 'pages/pagamento/pagamento.model';
import { ContaPagarValePresenteTO } from 'pages/vale-presente/vale-presente.model';
import ValePresenteService from 'pages/vale-presente/vale-presente.service';
import React, { useEffect, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setDadosValePresente, setUUIDsValesAdicionados } from 'redux/features/valePresenteSlice';
import { useAppSelector } from 'redux/hooks';
import { currencyOf, deepCopy } from 'utils/util';
import { FormaPagamentoDialogContentProps } from '../formapagamento.model';

const initialState = {
    codigoBarrasString: '',
};

const ValePresenteDialogContent = ({ isNew, error, valorRecebido, setPreventConfirmar, submitted, onUpdateHasEntered }: FormaPagamentoDialogContentProps) => {
    const { setHasError } = error;
    const { dadosPagamento, setDadosPagamento } = valorRecebido;
    const { faltaReceber } = useAppSelector(s => s.pagamento);
    const codigoBarrasRef = useRef<HTMLInputElement>(null);
    const service = ValePresenteService();
    const { addError } = useSnackbarCustom();
    const { tableRows, selectedRow } = useAppSelector(s => s.pagamento);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { dadosValePresente: dadosValePresenteGlobal } = useAppSelector(s => s.valePresente);
    const location = useLocation();

    const _errosLocais = useRef({
        hasValeVencido: false,
        hasValorMaiorQueFaltaReceber: false,
        hasValeComValorUtilizado0: false,
    });

    const [localState, setLocalState] = useState(initialState);

    const getValorTotal = (dadosValePresente: Array<ContaPagarValePresenteTO> = dadosValePresenteGlobal) => {
        return dadosValePresente.map(item => item.valorUtilizado).reduce((acc, at) => acc += at, 0);
    };

    const faltaReceberRef = useRef(faltaReceber);
    const valorRecebidoRef = useRef(0);

    const getFaltaReceberLocal = () => {
        return faltaReceberRef.current! - getValorTotal();
    };

    const getHasErrosLocal = () => {
        const err = _errosLocais.current!;
        for (let key in err) {
            const value = err[key as keyof typeof err];
            if (value) return true;
        }
        return false;
    };

    const valeJaAdicionadoNoStateGlobal = (uuidVale: string): boolean => {
        for (let row of tableRows) {
            if (row.infoAdicional?.dadosValePresente?.some(vale => vale.uuid === uuidVale)) {
                return true;
            }
        }
        return false;
    };

    const hasValeVencido = () => {
        return dadosValePresenteGlobal.some(item => item.isVencido);
    };

    const estourouValorFaltaReceber = () => {
        return getFaltaReceberLocal() < 0;
    };

    const hasValeComValorUtilizadoZerado = () => {
        return dadosValePresenteGlobal.some(item => item.valorUtilizado <= 0);
    };

    useEffect(() => {
        onUpdateHasEntered!()
        if (location.state?.openDialog) {
            const dadosVale = dadosValePresenteGlobal.map(item => setDefaultValues(item, !!selectedRow));
            onChangeValorRecebido(dadosVale);
            dispatch(setDadosValePresente(dadosVale));
            location.state = null;
        } else if (isNew) {
            setDadosPagamento(prev => ({
                valorRecebido: 0,
                infoAdicional: {
                    numeroParcelas: 1,
                    descritivo: '',
                    tipo: TipoInformacaoAdicional.VALE_PRESENTE,
                    dadosValePresente: []
                }
            }));
        } else {
            const prevDados = dadosPagamento.infoAdicional?.dadosValePresente?.slice() ?? [];
            dispatch(setDadosValePresente(prevDados));
            valorRecebidoRef.current! = dadosPagamento.valorRecebido;
        }

        let total = 0;
        const uuidsValesAdicionadosStateGlobal = tableRows.filter(f => f.name === 'VALE_PRESENTE')
            .flatMap(item => item.infoAdicional!.dadosValePresente!)
            .map(item => {
                total += item.valorAberto;
                return item.uuid;
            });
        dispatch(setUUIDsValesAdicionados({ uuids: uuidsValesAdicionadosStateGlobal, total }));
        codigoBarrasRef.current?.focus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const value = getValorTotal();
        setDadosPagamento(prev => {
            return {
                ...prev,
                valorRecebido: value,
                infoAdicional: {
                    ...prev.infoAdicional!,
                    dadosValePresente: dadosValePresenteGlobal,
                    numeroParcelas: dadosValePresenteGlobal.length,
                    tipo: TipoInformacaoAdicional.VALE_PRESENTE
                }
            };
        });

        _errosLocais.current! = {
            hasValeVencido: hasValeVencido(),
            hasValorMaiorQueFaltaReceber: estourouValorFaltaReceber(),
            hasValeComValorUtilizado0: hasValeComValorUtilizadoZerado(),
        };
        setHasError(getHasErrosLocal());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dadosValePresenteGlobal.length, valorRecebidoRef.current]);

    const getValorUtilizadoSugerido = (valorAberto: number) => {
        let realFaltaReceber = getFaltaReceberLocal();
        if (realFaltaReceber < 0) {
            realFaltaReceber = 0;
        }
        return valorAberto >= realFaltaReceber ? realFaltaReceber : valorAberto;
    };

    const setDefaultValues = (vale: ContaPagarValePresenteTO, jaTinhaValorUtilizado: boolean) => {
        const permiteParcial = vale.isVencido ? false : vale.permiteParcial;
        const valorAberto = vale.isVencido ? 0 : vale.valorAberto;
        let valorUtilizado = vale.valorUtilizado;
        if (!jaTinhaValorUtilizado || valorUtilizado === 0) {
            valorUtilizado = vale.permiteParcial ? getValorUtilizadoSugerido(valorAberto) : valorAberto;
        }
        return { ...vale, valorUtilizado, permiteParcial };
    };

    const onAddValePresente = (e: React.KeyboardEvent<HTMLDivElement>) => {
        const inputValue = codigoBarrasRef.current?.value;
        if (e.key !== 'Enter' || !inputValue) {
            return;
        }

        if (getFaltaReceberLocal() <= 0) {
            return addError({
                id: 'sem-valor-falta-receber-erro',
                message: 'Não é possível adicionar mais vales',
                closeable: true,
                hideDuration: 3000,
                persist: false,
                preventDuplicate: true
            });
        }

        service.getDadosValePresente(inputValue)
            .then(({ data }) => {
                const find = dadosValePresenteGlobal.find(item => item.uuid === data.uuid);
                if (find) {
                    return addError({
                        id: 'vale-presente-ja-adicionado-local-erro',
                        message: 'Vale presente já foi adicionado',
                        closeable: true,
                        hideDuration: 3000,
                        preventDuplicate: true,
                        persist: false,
                    });
                }

                const jaAdicionado = valeJaAdicionadoNoStateGlobal(data.uuid);
                if (jaAdicionado) {
                    return addError({
                        id: 'vale-presente-ja-adicionado-state-global-erro',
                        message: 'Vale presente já foi adicionado em outra forma de pagamento',
                        closeable: true,
                        hideDuration: 3000,
                        preventDuplicate: true,
                        persist: false,
                    });
                };

                const vale = setDefaultValues(data, false);
                const dadosValePresente = [...dadosValePresenteGlobal, vale];
                onChangeValorRecebido(dadosValePresente);
                dispatch(setDadosValePresente(dadosValePresente));

                setLocalState(prev => {
                    return {
                        ...prev,
                        codigoBarrasString: '',
                    };
                });
            })
            .catch(err =>
                addError({
                    id: 'vale-presente-nao-encontrado-erro',
                    message: 'Vale presente não encontrado',
                    closeable: true,
                    hideDuration: 3000,
                    preventDuplicate: true,
                    persist: false,
                }));
        codigoBarrasRef.current.value = '';
        codigoBarrasRef.current?.focus();
    };

    const onChangeValorRecebido = (dadosValePresente: Array<ContaPagarValePresenteTO>) => {
        const value = getValorTotal(dadosValePresente);
        valorRecebidoRef.current! = value;
    };

    const onChangeValorUtilizado = (floatValue: number = 0, index: number) => {
        const dadosValePresente = dadosValePresenteGlobal.slice();
        const copy = deepCopy(dadosValePresente[index]);
        copy.valorUtilizado = floatValue;
        dadosValePresente[index] = copy;
        onChangeValorRecebido(dadosValePresente);
        dispatch(setDadosValePresente(dadosValePresente));
    };

    const onRemoveRow = (index: number) => {
        const dadosValePresente = dadosValePresenteGlobal.slice()
            .filter((e, idx) => idx !== index);
        onChangeValorRecebido(dadosValePresente);
        dispatch(setDadosValePresente(dadosValePresente));
    };

    return (
        <>
            <TextField
                sx={{ width: '30rem', maxWidth: '100%' }}
                variant="standard"
                label='Código de barras | Crédito cliente'
                id="acSku-input"
                onFocus={() => setPreventConfirmar?.(true)}
                onBlur={() => setPreventConfirmar?.(false)}
                InputProps={{
                    endAdornment: (
                        <React.Fragment>
                            <IconButton
                                disabled={getFaltaReceberLocal() <= 0}
                                onClick={() => { navigate('/vale-presente/busca-avancada'); }}
                                sx={{ height: 0, padding: 0, paddingLeft: '5px', color: 'var(--laranja)' }}
                            >
                                <PersonSearchIcon />
                            </IconButton>
                        </React.Fragment>
                    )
                }}
                value={localState.codigoBarrasString}
                onChange={e => {
                    const value = e.target.value;
                    setLocalState(prev => ({ ...prev, codigoBarrasString: value }));
                }}
                inputRef={codigoBarrasRef}
                onKeyUp={onAddValePresente}
            />

            <TableContainer
                sx={{
                    height: '100%',
                    backgroundColor: '#FFF',
                    borderRadius: 1,
                    position: 'relative',
                    boxSizing: 'border-box',
                }}
                className="color-orange"
            >
                <Table size="small">
                    <TableHead>
                        <TableRow >
                            <TableCell>Descricao</TableCell>
                            <TableCell align="center">Emissão</TableCell>
                            <TableCell align="center">Vencimento</TableCell>
                            <TableCell align="center">Valor aberto</TableCell>
                            <TableCell align="center">Total utilizado</TableCell>
                            <TableCell width='30px' />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dadosValePresenteGlobal.length ?
                            dadosValePresenteGlobal.map((row, index) => {

                                return (
                                    <TableRow
                                        id={`vale-presente-row-${index}`}
                                        key={row.uuid}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            height: '50px'
                                        }}
                                    >
                                        <TableCell id={`vale-presente-descricao-row-${index}`}>{row.descricao}</TableCell>
                                        <TableCell id={`vale-presente-emissao-row-${index}`} align="center">{row.emissao.toString()}</TableCell>
                                        <TableCell id={`vale-presente-vencimento-row-${index}`}
                                            align="center"
                                            sx={{
                                                color: row.isVencido ? 'red' : 'initial'
                                            }}
                                        >
                                            {row.vencimento.toString()}
                                        </TableCell>
                                        <TableCell id={`vale-presente-valor-row-${index}`} align="center">{currencyOf(row.valorAberto)}</TableCell>
                                        <TableCell id={`vale-presente-total-utilizado-row-${index}`} align="center">
                                            <NumericFormat
                                                id={`vale-presente-total-utilizado-input-${index}`}
                                                style={{ width: '5rem', }}
                                                decimalScale={2}
                                                disabled={!row.permiteParcial}
                                                fixedDecimalScale
                                                defaultValue={0}
                                                allowedDecimalSeparators={[',']}
                                                customInput={TextField}
                                                decimalSeparator=","
                                                thousandsGroupStyle="thousand"
                                                thousandSeparator="."
                                                variant="standard"
                                                prefix={'R$ '}
                                                error={estourouValorFaltaReceber() || row.valorUtilizado <= 0}
                                                InputProps={{
                                                    error: estourouValorFaltaReceber() || row.valorUtilizado <= 0,
                                                    sx: {
                                                        color: estourouValorFaltaReceber() || row.valorUtilizado <= 0 ? 'var(--error)' : 'initial',
                                                        fontSize: '14px',
                                                    }
                                                }}
                                                allowNegative={false}
                                                inputProps={{ style: { textAlign: 'center' } }}
                                                isAllowed={({ floatValue }) => {
                                                    return !floatValue || floatValue <= row.valorAberto;
                                                }}
                                                value={row.valorUtilizado}
                                                onValueChange={({ floatValue }) => onChangeValorUtilizado(floatValue, index)}
                                            />

                                        </TableCell>
                                        <TableCell width='30px'>
                                            <IconButton
                                                onClick={() => onRemoveRow(index)}
                                            >
                                                <Icon className="fa-solid fa-times"></Icon>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            }) :
                            <TableRow id={`vale-presente-row-empty`}>
                                <TableCell colSpan={6} align='center' sx={{ userSelect: 'none' }}>Nenhum vale adicionado</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                    {(getValorTotal() > 0) &&
                        <TableFooter sx={{ borderTop: '1px solid #e1e1e1' }}>
                            <TableRow id={`footer`}>
                                <TableCell colSpan={4}>
                                    <Typography fontWeight={'bold'}>Total utilizado</Typography>
                                </TableCell>
                                <TableCell align='center'>
                                    <Typography
                                        fontWeight={'bold'}
                                        sx={{
                                            color: estourouValorFaltaReceber() ? 'var(--error)' : 'inherit'
                                        }}
                                    >
                                        {currencyOf(getValorTotal())}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    }
                </Table>
            </TableContainer>

            {hasValeVencido() &&
                <Typography sx={{ color: 'red', fontSize: '12px', marginTop: '-10px', alignSelf: 'flex-start' }}>
                    Crédito vencido, não é possível utilizar de acordo com a política de vendas definida em configurações
                </Typography>
            }

            {estourouValorFaltaReceber() &&
                <Typography sx={{ color: 'red', fontSize: '12px', marginTop: '-10px', alignSelf: 'flex-start' }}>
                    O valor da venda não pode ser menor que o crédito do cliente. Não é possível gerar troco :(
                </Typography>
            }

            {hasValeComValorUtilizadoZerado() &&
                <Typography sx={{ color: 'red', fontSize: '12px', marginTop: '-10px', alignSelf: 'flex-start' }}>
                    O valor utilizado não pode ser igual a zero
                </Typography>
            }

            {/* card falta a receber */}
            <Card sx={{ position: { xs: 'relative', md: 'absolute' }, bottom: { xs: 'none', md: '20px' }, borderRadius: 5, textAlign: 'center', height: '100px', aspectRatio: '7/3', padding: '0 !important' }} className="card-totalizador-pedido">
                <h2 id="outros_falta_receber_value" className="valor-totalizador">{currencyOf(getFaltaReceberLocal())}</h2>
                <h4 id="outros_falta_receber_titulo" className="label-totalizador">Falta receber</h4>
            </Card>
        </>
    );
};

export default ValePresenteDialogContent;
