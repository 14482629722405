import { AxiosResponse } from "axios";
import LoginService from "pages/login/login.service";
import { toLocalDateBackEndFriendly } from "utils/util";
import { axiosRequest } from "../../axios/axios.config";
import { BaseValePresenteTO, ContaPagarValePresenteTO, ValePresenteFiltroAvancadoTO, ValePresenteResponseTO, ValePresenteTO, VisualizacaoContaPagarValePresenteTO } from "./vale-presente.model";

const ValePresenteService = () => {
    const path = '/flow/creditocliente';
    const loginService = LoginService();

    return {
        gerarValePresente: (vale: ValePresenteTO): Promise<AxiosResponse<ValePresenteResponseTO>> => {
            return axiosRequest.post(`${path}/gerarvalepresente`, vale, { headers: loginService.getHeaders() });
        },
        getDadosValePresente(codigoBarras: string): Promise<AxiosResponse<ContaPagarValePresenteTO>> {
            return axiosRequest.get(`${path}/codigobarras/${codigoBarras}`, { headers: loginService.getHeaders() });
        },
        getAllVales(emissaoInicio: string | Date, emissaoFim: string | Date): Promise<AxiosResponse<Array<BaseValePresenteTO>>> {
            return axiosRequest.get(
                `${path}/listavalespresente`,
                {
                    headers: loginService.getHeaders(),
                    params: {
                        emissaoInicio: toLocalDateBackEndFriendly(emissaoInicio),
                        emissaoFim: toLocalDateBackEndFriendly(emissaoFim),
                    }
                });
        },
        getAllValesBuscaAvancada(filtro: ValePresenteFiltroAvancadoTO): Promise<AxiosResponse<Array<ContaPagarValePresenteTO>>> {
            filtro.vencimentoInicio = toLocalDateBackEndFriendly(filtro.vencimentoInicio);
            filtro.vencimentoFim = toLocalDateBackEndFriendly(filtro.vencimentoFim);

            return axiosRequest.post(`${path}/listavalespresente`, filtro, { headers: loginService.getHeaders(), });
        },
        visualizar(uuid: string): Promise<AxiosResponse<VisualizacaoContaPagarValePresenteTO>> {
            return axiosRequest.get(`${path}/visualizar/${uuid}`, { headers: loginService.getHeaders() });
        },
        cancelarVale(uuidVale: string, uuidMotivo: string): Promise<AxiosResponse<string>> {
            return axiosRequest.post(`${path}/cancelar/${uuidVale}`, uuidMotivo, { headers: loginService.getHeaders() });
        },
    };
};

export default ValePresenteService;
