import { Box, Divider, Grid, Icon, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { CustomButton } from "components/roundedbutton/CustomButton";
import { useSnackbarCustom } from "hooks/useSnackCustom";
import { CustomSwitch } from "pages/cadastro-pessoa/CadastroPessoa";
import { useEffect, useState } from "react";
import { Tipo, completeLoading, loadingWithOpt } from "redux/features/blockuiSlice";
import { useAppDispatch } from "redux/hooks";
import { ConfiguracaoValePresente, ConfiguracaoValePresenteState } from "./configuracaovalepresente.model";
import ConfiguracaoValePresenteService from "./configuracaovalepresente.service";
import CustomDoubleLeft from "pages/Venda/CustomDoubleLeft";

const initialState: ConfiguracaoValePresenteState = {
    configuracao: {
        utilizarParcial: false,
    }
};

const ConfigurarPoliticaDevolucaoValePresente = (props: { onGoBack: () => void; }) => {
    const [stateLocal, setStateLocal] = useState(initialState);
    const { addError, addSuccess } = useSnackbarCustom();
    const service = ConfiguracaoValePresenteService();
    const dispatch = useAppDispatch();

    const setInConfiguracaoState = <T extends ConfiguracaoValePresente>(key: keyof T, value: T[keyof T]) => {
        setStateLocal(s => ({ ...s, configuracao: { ...s.configuracao, [key]: value } }));
    };

    useEffect(() => {
        getConfiguracao();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getConfiguracao = () => {
        service.getConfiguracao()
            .then(({ data }) => {
                setStateLocal(s => ({ ...s, configuracao: data }));
            })
            .catch(() => { });
    };

    const onClickSalvar = () => {
        dispatch(loadingWithOpt({
            loading: true,
            tipo: Tipo.CIRCULAR,
        }));
        service.update(stateLocal.configuracao)
            .then(({ data }) => {
                props.onGoBack();
                addSuccess({
                    message: 'Configuração salva com sucesso',
                    persist: false,
                    closeable: true,
                    hideDuration: 3000,
                    id: 'salvar-configuracao-vale-presente-success',
                    preventDuplicate: true,
                });
            })
            .catch(err => {
                addError({
                    message: `Não foi possível ${stateLocal.configuracao.version ? 'editar' : 'salvar'} a configuração`,
                    persist: false,
                    closeable: true,
                    hideDuration: 3000,
                    id: 'salvar-configuracao-vale-presente-error',
                    preventDuplicate: true,
                });
            })
            .finally(() => {
                dispatch(completeLoading());
            });
    };

    return (
        <>
            <Box sx={{
                width: '100%',
                borderTop: '1px solid rgba(0, 0, 0, .1)',
                borderBottom: '1px solid rgba(0, 0, 0, .1)',
                padding: '15px 0',
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '20px'
            }}>
                <Box id='vendaScreenBox' sx={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <CustomDoubleLeft id="custom_double_left_icon_configuracoes" color="#666666" fontSize="60px" style={{ marginBottom: '5px' }} />
                    <Typography id='vendaScreenTitle' sx={{ paddingLeft: '25px', fontSize: '45px', fontWeight: '600', color: '#666666', userSelect: 'none' }}>
                        Configurar política de devolução e crédito cliente
                    </Typography>
                </Box>
            </Box>
            <Grid
                container
                spacing={2}
                alignContent={'flex-start'}
                sx={{
                    height: '85%',
                    position: 'relative',
                }}
            >
                <Grid item xs={12} >
                    <Typography
                        sx={{
                            fontSize: 22,
                            marginBottom: '10px'
                        }}
                    >
                        Configuração do crédito cliente
                    </Typography>
                    <Divider />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        label={'Validade do crédito (em dias)'}
                        error={false}
                        sx={{
                            width: '100%',
                            marginBottom: '10px'
                        }}
                        inputProps={{
                            inputMode: 'numeric',
                            min: 0,
                            style: {
                                maxHeight: '20px',
                                height: '100%',
                                textAlign: 'start',
                                padding: '7px 0',
                            },
                        }}
                        InputProps={{
                            endAdornment:
                                <Tooltip
                                    arrow
                                    title={
                                        <Typography
                                            fontSize={15}
                                            textAlign={'left'}
                                        >
                                            Ao emitir um vale presente,
                                            esta quandidade de dias determinará até
                                            quando poderá ser utilizado.
                                            O vencimento será a data atual +
                                            a quantidade de dias informada aqui!
                                        </Typography>}
                                >
                                    <IconButton>
                                        <Icon className="fa-solid fa-info" sx={{ color: 'var(--laranja)' }}></Icon>
                                    </IconButton>
                                </Tooltip>

                        }}
                        value={stateLocal.configuracao.validadeDias ?? ''}
                        onKeyDown={e => e.key === '.' && e.preventDefault()}
                        onChange={e => {
                            const targeValue = e.target.value;
                            let valueToSet = null;
                            if (targeValue !== '' && Number(targeValue) >= 0) {
                                valueToSet = Number(targeValue);
                            }
                            setInConfiguracaoState('validadeDias', valueToSet);
                        }}
                        variant="standard"
                        type="number"
                    />
                </Grid>

                <Grid item xs={0} md={6} />

                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                        }}>
                        <Typography
                            sx={{
                                fontSize: 18,
                                color: '#666'
                            }}
                        >
                            Permite utilizar parcial?
                        </Typography>

                        <CustomSwitch
                            id='permite-parcial-input'
                            checked={stateLocal.configuracao.utilizarParcial}
                            onChange={(e, value) => {
                                setInConfiguracaoState('utilizarParcial', value);
                            }}
                            name="permite-parcial" />
                    </Box>
                </Grid>

                <Grid item xs={0} md={6} />

                <Grid item
                    sx={{
                        position: 'absolute',
                        bottom: '15px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                    }}
                >
                    <CustomButton
                        sx={{ minWidth: '80px' }}
                        id="voltar-configuracao-vale-presente-btn"
                        onClick={props.onGoBack}
                        variant="contained"
                        color="error"
                        startIcon={<Icon className='fa-solid fa-arrow-left' />}>
                        Voltar
                    </CustomButton>

                    <CustomButton
                        sx={{ minWidth: '80px' }}
                        id="voltar-configuracao-vale-presente-btn"
                        onClick={onClickSalvar}
                        variant="contained"
                        color="success"
                        startIcon={<Icon className='fa-solid fa-check' />}>
                        Salvar
                    </CustomButton>
                </Grid>
            </Grid>
        </>

    );
};

export default ConfigurarPoliticaDevolucaoValePresente;
