import { Delete, RefreshOutlined } from "@mui/icons-material";
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Container, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FloatingActionsProps } from "components/floatingactions/FloatingActions";
import { useSnackbarCustom } from "hooks/useSnackCustom";
import Footer from "layout/footer/Footer";
import { IAbstractModel } from "model/abstract.model";
import CaixaService from "pages/Caixa/caixa.service";
import LoginService from "pages/login/login.service";
import { FormaPagamentoDialog } from "pages/pagamento/formapagamento/FormaPagamentoDialog";
import BoletoDialogContent from "pages/pagamento/formapagamento/dialogscontent/BoletoDialogContent";
import ChequeDialogContent from "pages/pagamento/formapagamento/dialogscontent/ChequeDialogContent";
import GenericDialogContent from "pages/pagamento/formapagamento/dialogscontent/GenericDialogContent";
import OutrosDialogContent from "pages/pagamento/formapagamento/dialogscontent/OutrosDialogContent";
import ValePresenteDialogContent from "pages/pagamento/formapagamento/dialogscontent/ValePresenteDialogContent";
import { FlowPedidoCieloIsPagoTO, FormasPagamentoData } from "pages/pagamento/formapagamento/formapagamento.model";
import { DadosPagamento, DialogsState, FormaPagamentoName, FormasPagamento, IPagamentoRow, TDadosCartao, TipoInformacaoAdicional } from "pages/pagamento/pagamento.model";
import PagamentoService from "pages/pagamento/pagamento.service";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tipo, loadingWithOpt, resetLoading } from "redux/features/blockuiSlice";
import { resetCaixa, setCaixa } from "redux/features/caixaSlice";
import { addRow, changeTotalLiquido, editRow, removeRow, resetFaltaReceber, resetRows, SelectedRow, setFaltaReceber, setFaltaReceberSemSoma, setSelectedRow, updateIsPago } from "redux/features/pagamentoSlice";
import { resetDadosValePresente } from "redux/features/valePresenteSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import useCustomLocation from "utils/useCustomLocation";
import { captalizeString, currencyOf, getByTitle, roundHalfToEven } from "utils/util";
import { axiosRequest } from "../../axios/axios.config";
import CustomGridOption from "pages/Caixa/CustomGridOption";
import GenericOutrasFormasPagamento from "pages/pagamento/formapagamento/dialogscontent/GenericOutrasFormasPagamento";
import RegistraVendaService from "pages/registra-venda/registra-venda.service";
import { FormaPagamentoCard } from '../../pages/pagamento/formapagamento/FormaPagamentoCard';
import CartoesDialogContent from "pages/pagamento/formapagamento/dialogscontent/CartoesDialogContent";
import { globalStyles } from "styles/global";

export type TelaFormasPagamentoComponentProps = {
    children?: ReactNode;
    refs: {
        faltaReceberRef: React.MutableRefObject<number>;
        canEscape: React.MutableRefObject<boolean>;
    };
    quantidadeTotal?: number;
    valorTotalLiquido: number;
    onInit?: () => void;
    footer: {
        onClickAvancar: () => void;
        onClickCancelar?: () => void;
        floatingActions?: FloatingActionsProps;
    };
    handleKeyPress?: (e: KeyboardEvent, data?: any) => void;
    setFormasPagamentoDefault?: (formasAceitas?: Array<IAbstractModel>) => void;
    editRow?: SelectedRow | null;
    onFinishEditRow?: () => void;
    showModalTroco?: () => void;
    openEditTroco?: () => void;
};

export const getFormaPagamentoAbstracModel = (name: FormaPagamentoName, formasPagamentoToSearch?: Array<IAbstractModel>): IAbstractModel => {
    const find = formasPagamentoToSearch?.find(f =>
        f.descritivo === FormasPagamento[name]
    );

    if (!find) {
        throw new Error('Forma de pagamento não disponível');
    }

    return find;
};

const isOutrasFormasPagamento = (tipoFormaPagamento: FormaPagamentoName) => {
    switch (tipoFormaPagamento) {
        case "CREDIARIO":
            return true;
        case "CREDITOLOJA":
            return true;
        case "DEPOSITOBANCARIO":
            return true;
        default: return false;
    }
};

const initialState = {
    showMaisFormasPagamentos: false as boolean,
    title: null as FormaPagamentoName | null,
    dadosPagamentoCartao: {
        bandeiras: [],
        credenciadoras: [],
        modosPagamento: []
    } as TDadosCartao
}


const TelaFormasPagamentoComponent = (props: TelaFormasPagamentoComponentProps) => {

    const service = PagamentoService();
    const dispatch = useAppDispatch();
    const estabelecimento = useAppSelector(s => s.estabelecimentoSelecionado);
    const estabelecimentoRef = useRef<string>(estabelecimento.uuid);
    const primeiroRender = useRef(true);
    const [preventConfirmar, setPreventConfirmar] = useState(false);
    const { isTelaPagamento, isTelaValePresente } = useCustomLocation();
    const location = useLocation();

    const { faltaReceber, tableRows, tableRowsDevolucao, tempCartaoRow, troco } = useAppSelector(s => s.pagamento);
    const { pedido } = useAppSelector(s => s.state);
    const { valorDesconto } = useAppSelector(e => e.state.pedido.pedidoDesconto);
    const isFirstRender = useRef(true);
    const valorTableRows = (tableRows.filter(e => e.formaPagamento.descritivo !== 'Nota de crédito' && e.formaPagamento.descritivo !== "OUTROS DEVOLUÇÃO").reduce((prev, current) => prev += current.valor, 0));
    const {
        valorTotalLiquidoTroca,
        valorTotalLiquido,
    } = useAppSelector(e => e.state.pedido);
    const valePresente = useAppSelector(e => e.valePresente.valePresente?.valor);
    const quantidadeTotalRef = useRef<number>(props.quantidadeTotal ?? -1);
    const [submitted, setSubmitted] = useState(false);
    const loginService = LoginService();
    const caixaService = CaixaService();
    const token = useAppSelector(e => e.token);
    const { tipo } = useAppSelector(e => e.blockui);
    const registravendaService = RegistraVendaService();
    const hasEnteredRef = useRef<boolean>(false)
    const selectedRow = useAppSelector(s => s.pagamento.selectedRow);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenuRow = Boolean(anchorEl);
    const rowValueRef = useRef<number | null>(null);

    // Dados internos dos dialogs
    const [dadosPagamento, setDadosPagamento] = useState<DadosPagamento>({ valorRecebido: 0 });
    const [isNew, setIsNew] = useState(true);
    const [hasError, setHasError] = useState(false);

    const onSincronizarRow = useCallback((row: IPagamentoRow) => {
        if ((row && row.credenciadoraUUID && row.uuidOrigem)) {
            onCloseMenuRow(false)
            const flowPedidoCieloIsPagoTO: FlowPedidoCieloIsPagoTO = {
                credenciadoraUUID: row.credenciadoraUUID,
                uuidOrigem: row.uuidOrigem
            }
            registravendaService.cartaoIntegracaoArePago([flowPedidoCieloIsPagoTO])
                .then(({ data }) => {
                    if (data.itensPagamentoTOS.length > 0 && data.itensPagamentoTOS.some(e => !e.isPago)
                        && tableRows.some(e => e.uuidOrigem === data.itensPagamentoTOS[0].uuidOrigem)) {
                        addInfo({
                            message: `Não identificamos o pagamento do ${row.descritivoFormaPagamento} , tente novamente`,
                            id: "",
                            closeable: true
                        })
                    }
                    onCloseMenuRow(true)
                    if (data && row.uuidOrigem) {
                        return dispatch(updateIsPago({
                            uuidOrigem: row.uuidOrigem,
                            itensPagamento: data.itensPagamentoTOS,
                            troco: data.troco
                        }))
                    }
                    return addError({
                        message: `Não identificamos o pagamento do ${row.descritivoFormaPagamento} , tente novamente`,
                        closeable: true,
                        persist: true
                    })
                }).catch(() => { })
                .finally(() => {
                    setTimeout(() => {
                        dispatch(loadingWithOpt({
                            loading: false,
                            message: "",
                            tipo: Tipo.CIRCULAR
                        }))
                    }, 200);
                })
        }
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (props.editRow) dispatch(setSelectedRow(props.editRow))
        //eslint-disable-next-line
    }, [props.editRow])

    useEffect(() => {
        if (props.editRow && selectedRow) onEditarRow(true)
        //eslint-disable-next-line
    }, [props.editRow, selectedRow])

    useEffect(() => {
        if (!primeiroRender.current) return;
        if (!troco && tempCartaoRow && tableRows.every(e => e.isPago)) {
            dispatch(loadingWithOpt({
                loading: true,
                tipo: Tipo.CIRCULAR,
                message: "Sincronizando pagamento máquina",
            }))
            onSincronizarRow(tempCartaoRow)
        }
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (troco?.valor && !troco.tipoTrocoPagar) props.showModalTroco!();
        //eslint-disable-next-line
    }, [troco])

    const onUpdateHasEntered = useCallback(() => {
        hasEnteredRef.current = true
        //eslint-disable-next-line
    }, [])

    const getDisabledDialogGeneric = () => {
        return hasError || dadosPagamento.valorRecebido === 0;
    };

    // Cartão de crédito 
    const getDisabledDialogCartaoCredito = () => {
        return getDisabledDialogGeneric();
    };

    const handleClickBoleto = () => {
        if (!pedido.cliente) return addError({ message: "Para pagar com boleto, é obrigatório informar cliente!" });
        return handleClickOpen("BOLETO");
    };

    const onCloseCartoes = useCallback(() => {
        onCloseGeneric('CARTOES');
        //eslint-disable-next-line
    }, [])


    const [stateLocal, setStateLocal] = useState(initialState);

    // Formas de pagamento
    const [formasPagamentoDisponiveis, setFormasPagamentoDisponiveis] = useState<Array<IAbstractModel>>([]);

    const handleGenericFormaPagamento = useCallback((title: FormaPagamentoName, handleOpen: boolean, disabled?: boolean) => {
        if (disabled) {
            setStateLocal(prevState => ({ ...prevState, showMaisFormasPagamentos: false }))
            return addError({ message: `Apenas uma forma de pagamento ${title} é permitida por pedido` })
        }
        setStateLocal(prevState => ({ ...prevState, showMaisFormasPagamentos: false, title }));
        if (handleOpen) handleClickOpen(title);
        //eslint-disable-next-line
    }, []);

    const getDisabledCheque = () => {
        if (dadosPagamento?.infoAdicional?.dadosCheque?.length !== undefined) {
            const parcelaZerada = dadosPagamento.infoAdicional?.dadosCheque?.filter(s => s.valor === 0).length > 0 || dadosPagamento.infoAdicional?.numeroParcelas < 1;
            return hasError || dadosPagamento.valorRecebido === 0 || parcelaZerada;
        } else {
            return hasError || dadosPagamento.valorRecebido === 0;
        }
    };

    const formasPagamento: FormasPagamentoData = [
        {
            id: "CARTOES",
            label: 'Cartões',
            card: {
                icon: { name: 'fa-credit-card' },
            },
            dialog: {
                maxWidth: 'md',
                fullWidth: true,
                disableDefaultButtons: true,
                disableTitle: true,
                confirmButton: {
                    onConfirm(e) {
                        const formaPagamentoCartao: FormaPagamentoName =
                            dadosPagamento.infoAdicional?.dadosCartao?.modoPagamento?.modoPagamento.includes("CRÉDITO") ? "CREDITO"
                                : dadosPagamento.infoAdicional?.dadosCartao?.modoPagamento?.modoPagamento.includes("DÉBITO") ? "DEBITO"
                                    : "PIX";
                        handleGenericFormaPagamento(formaPagamentoCartao, false);
                        onConfirmGeneric(e!, formaPagamentoCartao);
                    },
                    disabled: getDisabledDialogCartaoCredito(),
                },
                onFocus: () => { },
                children: (
                    <CartoesDialogContent
                        valorRecebido={{ dadosPagamento, setDadosPagamento }}
                        error={{ hasError, setHasError }}
                        isNew={isNew}
                        onClose={onCloseCartoes}
                        handleGenericFormaPagamento={handleGenericFormaPagamento}
                        dadosPagamentoCartao={stateLocal.dadosPagamentoCartao}
                        formasPagamentoCartao={formasPagamentoDisponiveis.filter(e => e.descritivo.toUpperCase().includes("CARTÃO"))}
                        onSincronizarPagamento={onSincronizarRow}
                    />
                )
            }
        },
        {
            id: 'DINHEIRO',
            label: 'Dinheiro',
            card: {
                icon: { name: 'fa-money-bill-1-wave', },
            },
            dialog: {
                onFocus: () => { },
                disableDefaultButtons: false,
                disableTitle: false,
                children: (
                    <GenericDialogContent
                        valorRecebido={{ dadosPagamento, setDadosPagamento }}
                        error={{ hasError, setHasError }}
                        isNew={isNew}
                        id="DINHEIRO"
                    />
                ),
            }
        },
        {
            id: 'CHEQUE',
            label: 'Cheque',
            card: {
                icon: { name: 'fa-money-check-dollar', },
                disabled: isTelaValePresente(),
            },
            dialog: {
                onFocus: () => { },
                fullWidth: true,
                maxWidth: 'lg',
                disableDefaultButtons: false,
                disableTitle: false,
                confirmButton: {
                    disabled: getDisabledCheque(),
                    onConfirm(e) {
                        if (dadosPagamento?.infoAdicional?.dadosCheque?.every(item => item.vencimento)) {
                            onConfirmGeneric('CHEQUE', null);
                        } else {
                            addError({ message: 'Preencha todas as datas de vencimento', closeable: true });
                        }
                    },
                },
                onClose: () => {
                    setDadosPagamento({ valorRecebido: 0, infoAdicional: undefined });
                    onCloseGeneric('CHEQUE');
                },
                children: (
                    <ChequeDialogContent
                        valorRecebido={{ dadosPagamento, setDadosPagamento }}
                        error={{ hasError, setHasError }}
                        isNew={isNew}
                    />
                ),
            }
        },
        {
            id: 'PIX',
            label: 'Pix',
            card: {
                icon: { type: 'fa-brands', name: 'fa-pix', },
            },
            dialog: {
                disableDefaultButtons: false,
                disableTitle: false,
                onFocus: () => { },
                children: (
                    <GenericDialogContent
                        valorRecebido={{ dadosPagamento, setDadosPagamento }}
                        error={{ hasError, setHasError }}
                        isNew={isNew}
                        id="PIX"
                    />
                ),
            }
        },
        {
            id: 'BOLETO',
            label: 'Boleto',
            card: {
                onClick: handleClickBoleto,
                icon: { name: 'fa-file-invoice-dollar', },
                disabled: isTelaValePresente(),
            },
            dialog: {
                disableDefaultButtons: false,
                disableTitle: false,
                onFocus: () => { },
                fullWidth: true,
                maxWidth: 'sm',
                confirmButton: {
                    onConfirm(e) {
                        if (dadosPagamento?.infoAdicional?.dadosGeneric?.every(item => item.vencimento)) return onConfirmGeneric('BOLETO', null);
                        return addError({ message: 'Preencha todas as datas de vencimento', closeable: true });
                    },
                },
                onClose: () => {
                    setDadosPagamento({ valorRecebido: 0, infoAdicional: undefined });
                    onCloseGeneric('BOLETO');
                },
                children: (
                    <BoletoDialogContent
                        valorRecebido={{ dadosPagamento, setDadosPagamento }}
                        error={{ hasError, setHasError }}
                        isNew={isNew}
                    />
                ),
            }
        },
        {
            id: 'VALE_PRESENTE',
            label: 'Crédito cliente',
            card: {
                icon: {
                    component: <CardGiftcardIcon />
                },
                visible: isTelaPagamento(),
            },
            dialog: {
                disableDefaultButtons: false,
                disableTitle: false,
                maxWidth: 'lg',
                fullWidth: true,
                onFocus: () => { },
                confirmButton: {
                    onConfirm: (id) => {
                        onConfirmGeneric(id!, null);
                        setTimeout(() => {
                            dispatch(resetDadosValePresente());
                        }, 200);
                    }
                },
                onClose: (id) => {
                    onCloseGeneric(id!);
                    setTimeout(() => {
                        dispatch(resetDadosValePresente());
                    }, 200);
                },
                children: (
                    <ValePresenteDialogContent
                        submitted={submitted}
                        valorRecebido={{ dadosPagamento, setDadosPagamento }}
                        error={{ hasError, setHasError }}
                        isNew={isNew}
                        setPreventConfirmar={setPreventConfirmar}
                        onUpdateHasEntered={onUpdateHasEntered}
                    />
                ),
            }
        },
        {
            id: 'OUTROS',
            label: 'Outras formas de pagamento',
            card: {
                icon: { name: 'fa-hand-holding-dollar', },
                disabled: isTelaValePresente(),
                onClick: () => {
                    setIsNew(true);
                    setStateLocal(prevState => ({ ...prevState, showMaisFormasPagamentos: !stateLocal.showMaisFormasPagamentos }));
                }
            },
            dialog: {
                disableDefaultButtons: false,
                disableTitle: false,
                onFocus: () => { },
                children: (
                    <OutrosDialogContent
                        valorRecebido={{ dadosPagamento, setDadosPagamento }}
                        error={{ hasError, setHasError }}
                        isNew={isNew}
                    />
                ),
            }
        },
    ];




    useEffect(() => {
        if (!primeiroRender.current) {
            return;
        }
        registravendaService.getDadosPagamentoCartao()
            .then(({ data }) => {
                setStateLocal(prevState => ({ ...prevState, dadosPagamentoCartao: data }))
            })
            .catch(() => { });

        //eslint-disable-next-line
    }, [])

    const handleKeyPress = (e: KeyboardEvent) => {
        switch (e.key) {
            case 'Escape':
                if (props.refs.canEscape.current) {
                    e.preventDefault();
                    props.footer.onClickCancelar?.();
                    onClickVoltar();
                }
                break;
            case 'Enter':
                if (e.ctrlKey) {
                    e.preventDefault();
                    props.footer.onClickAvancar();
                }
                break;
        }
        props.handleKeyPress?.(e);
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.refs.canEscape.current]);

    useEffect(() => {
        if (!primeiroRender.current) {
            return;
        }

        if (props.refs.faltaReceberRef.current === 0 && quantidadeTotalRef.current === 0 && !pedido.itens.every(e => e.isBrinde)) {
            handleNavigate('registra-venda');
        }

        props.onInit?.();

        // toFixed para evitar que o valor liquido fique menor doq o valor padrão das formas de pagamento
        dispatch(changeTotalLiquido(parseFloat(props.valorTotalLiquido.toFixed(2))));

        if (tipo === Tipo.CIRCULAR) {
            dispatch(resetLoading());
        }

        getFormasPagamentoList();

        if (location.state?.openDialog) {
            setOpenDialogs(location.state.openDialog, true);
            if (selectedRow) {
                dispatch(setFaltaReceber(selectedRow?.row.valor));
            }
        }
        primeiroRender.current = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (estabelecimentoRef.current !== estabelecimento.uuid) {
            axiosRequest.get(`/flow/existeconfiguracao`, { headers: loginService.getHeaders() })
                .then(e => {
                    caixaService.getCaixas()
                        .then(({ data }) => {
                            const caixa = data.find(e => e.caixaAberto && e.operadorAtual === token.USER_INFO?.username);
                            if (!caixa) {
                                addError({ message: 'Sem caixa aberto neste estabelecimento, redirecionando para tela de caixas para abrir', closeable: true });
                                dispatch(resetCaixa());
                                navigate('/caixa');
                            }
                            else {
                                caixaService.getSaldoConta(caixa.uuid)
                                    .then(({ data }) => {
                                        dispatch(setCaixa({
                                            caixa: data.caixa,
                                            contaSelecionada: null,
                                            saldo: data.saldo,
                                            dataAbertura: data.dataAbertura,
                                            caixaAberto: data.caixaAberto
                                        }));
                                        navigate('/registra-venda');
                                    })
                                    .catch(error => {
                                        addError({ message: 'Falha ao pegar o saldo da conta informada.', closeable: true });
                                        dispatch(resetCaixa());
                                        navigate('/caixa');
                                    });
                            }
                        }).catch(error => {
                            addError({ message: 'Falha ao pegar caixas disponiveis.', closeable: true });
                            dispatch(resetCaixa());
                            navigate('/caixa');
                        });
                })
                .catch(error => {
                    addError({ message: error.response.data.extra[0], closeable: true });
                    dispatch(resetCaixa());
                    navigate('/caixa');
                });


            if (props.refs.faltaReceberRef.current === 0) {
                navigate('/registra-venda');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estabelecimento.uuid]);

    useEffect(() => {
        if (props.refs.faltaReceberRef.current !== faltaReceber) {
            props.refs.faltaReceberRef.current = faltaReceber;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [faltaReceber]);

    useEffect(() => {
        if (!isFirstRender.current) {
            const valorTotal = - roundHalfToEven(valorTotalLiquido - valorTotalLiquidoTroca - valorTableRows, 2);
            if (isTelaValePresente()) {
                dispatch(setFaltaReceberSemSoma((valePresente ?? 0) - (valorTotal)));
            }
            else {
                dispatch(setFaltaReceberSemSoma(-valorTotal));
            }
        }
        //eslint-disable-next-line
    }, [valorDesconto, tableRows]);

    useEffect(() => {
        setTimeout(() => {
            isFirstRender.current = false;
        }, 300);
        //eslint-disable-next-line
    }, []);

    const navigate = useNavigate();
    const { addError, addInfo } = useSnackbarCustom();

    const handleNavigate = useCallback((url: string) => {
        navigate(`/${url}`);
        //eslint-disable-next-line
    }, []);

    // Cards 
    const getDisabledCard = useCallback((tableRows: Array<IPagamentoRow>, faltaReceber: number): boolean => {
        const pendentePagar = tableRows.filter(e => !e.isPago).reduce((prev, act) => prev += act.valor, 0)
        return faltaReceber - pendentePagar <= 0;
    }, []);

    // Dialogs
    const [_openDialogs, _setOpenDialogs] = useState<DialogsState>({
        DINHEIRO: false,
        CREDITO: false,
        DEBITO: false,
        PIX: false,
        CHEQUE: false,
        OUTROS: false,
        NOTA: false,
        BOLETO: false,
        VALE_PRESENTE: false,
        CREDIARIO: false,
        CREDITOLOJA: false,
        DEPOSITOBANCARIO: false,
        CARTOES: false,
    });

    useEffect(() => {
        if (Object.values(_openDialogs).every(e => e === false)) {
            setStateLocal(prevState => ({ ...prevState, title: null }));
        }
    }, [_openDialogs]);


    const getOpenDialog = (key: FormaPagamentoName): boolean => {
        return _openDialogs[key];
    };

    const setOpenDialogs = (key: FormaPagamentoName, value: boolean) => {
        _setOpenDialogs({ ..._openDialogs, [key]: value });
    };

    const handleClickOpen = (key: FormaPagamentoName) => {
        setOpenDialogs(key, true);
    };

    const handleClose = (key: FormaPagamentoName) => {
        setOpenDialogs(key, false);
        dispatch(setSelectedRow(null));
    };




    // Generic Dialogs
    const onConfirmGeneric = (name: FormaPagamentoName, cartaoName: FormaPagamentoName | null) => {
        handleClose(name);
        setTimeout(() => {
            if (selectedRow) {
                dispatch(editRow({
                    row: {
                        name: selectedRow.row.name,
                        descritivoFormaPagamento: (cartaoName && getDescritivoFormaPagamento(cartaoName)) ?? selectedRow.row.descritivoFormaPagamento,
                        formaPagamento: (cartaoName && getFormaPagamentoAbstracModel(cartaoName, formasPagamentoDisponiveis)) ?? selectedRow.row.formaPagamento,
                        valor: dadosPagamento.valorRecebido,
                        infoAdicional: dadosPagamento.infoAdicional,
                        isEditavel: true,
                        isPago: true
                    },
                    index: selectedRow.index
                }));
                setIsNew(true);
            } else if (isNew) {
                dispatch(addRow({
                    name: name,
                    descritivoFormaPagamento: (cartaoName && getDescritivoFormaPagamento(cartaoName)) ?? getDescritivoFormaPagamento(name),
                    formaPagamento: (cartaoName && getFormaPagamentoAbstracModel(cartaoName, formasPagamentoDisponiveis)) ?? getFormaPagamentoAbstracModel(name, formasPagamentoDisponiveis),
                    valor: dadosPagamento.valorRecebido,
                    infoAdicional: dadosPagamento.infoAdicional,
                    isEditavel: true,
                    isPago: true
                }));
            }
        }, 100);
    };

    const getDescritivoFormaPagamento = (formaPagamento: FormaPagamentoName) => {
        switch (formaPagamento) {
            case "CREDIARIO": return "CREDIÁRIO";
            case "DEPOSITOBANCARIO": return "DÉPOSITO BANCÁRIO";
            case "CREDITOLOJA": return "CRÉDITO LOJA";
            case "OUTROS": return "OUTROS";
            case "CREDITO": return "CRÉDITO";
            case "DEBITO": return "DÉBITO";
        }
        const find = formasPagamento.find(s => s.id === formaPagamento)!;
        return find.label.toUpperCase();
    };

    const onCloseGeneric = (id: FormaPagamentoName) => {
        setIsNew(true);
        dispatch(resetFaltaReceber());
        handleClose(id);
    };

    const onClickMenuRow = (event: React.MouseEvent<HTMLButtonElement>, row: { row: IPagamentoRow, index: number; }) => {
        setAnchorEl(event.currentTarget);
        dispatch(setSelectedRow(row));
    };

    const onCloseMenuRow = useCallback((resetSelectedRow: boolean) => {
        setAnchorEl(null);
        if (resetSelectedRow) {
            dispatch(setSelectedRow(null));
        }
        //eslint-disable-next-line
    }, [])

    const onExcluirRow = useCallback(() => {
        dispatch(removeRow(selectedRow?.index));
        onCloseMenuRow(false);
        //eslint-disable-next-line
    }, [selectedRow]);


    const getTipoInformacaoAdicionalGeneric = (tipo: FormaPagamentoName): TipoInformacaoAdicional => {
        switch (tipo) {
            case "CREDIARIO":
                return TipoInformacaoAdicional.CREDIARIO;
            case "DEPOSITOBANCARIO":
                return TipoInformacaoAdicional.DEPOSITOBANCARIO;
            case "CREDITOLOJA":
                return TipoInformacaoAdicional.CREDITOLOJA;
            default:
                throw new Error("TipoInformação não cadastrada");
        }
    };

    const onEditarRow = (informaManual: boolean) => {
        if (informaManual) props.onFinishEditRow!();
        if (selectedRow) {
            if (selectedRow.row.descritivoFormaPagamento === "TROCO") {
                onCloseMenuRow(true);
                return props.openEditTroco!()
            }
            const dados = {
                valorRecebido: selectedRow.row.valor,
                infoAdicional: selectedRow.row.infoAdicional,
            };
            if (isOutrasFormasPagamento(selectedRow.row.name)) {
                setStateLocal(prevState => ({
                    ...prevState,
                    showMaisFormasPagamentos: false,
                    title: selectedRow.row.name
                }));
            }
            rowValueRef.current = selectedRow.row.valor;
            if (selectedRow.row.isPago) dispatch(setFaltaReceber(selectedRow.row.valor));
            setDadosPagamento(dados);
            setIsNew(false);
            handleClickOpen(selectedRow.row.name);
            onCloseMenuRow(false);
        };
    };



    const getFormasPagamentoList = () => {
        service.getFormasPagamento()
            .then(({ data }) => {
                const formasAceitas = data.filter(filter =>
                    Object.keys(FormasPagamento)
                        .find(key =>
                            FormasPagamento[key as FormaPagamentoName] === filter.descritivo
                        )
                );
                setFormasPagamentoDisponiveis(formasAceitas);
                props.setFormasPagamentoDefault?.(formasAceitas);

            });
    };

    const isCrediarioDisabled = useCallback(() => {
        return tableRows.some(e => e.name.toUpperCase().includes("CREDIARIO"))
    }, [tableRows])


    const onClickVoltar = useCallback(() => {
        if (tableRows.filter(e => e.descritivoFormaPagamento !== "TROCO").filter(e => !e.isPago).length > 0) {
            return addError({
                message: "Não é possivel editar o pedido com pagamento na máquininha pendente, sincronize ou exclua.",
                closeable: true,
                preventDuplicate: true,
                id: ""
            })
        }
        if (!pedido.isVisualizar) dispatch(resetRows());
        if (hasEnteredRef.current) {
            hasEnteredRef.current = false
            return navigate("/registra-venda")
        }
        return navigate(-1)
        //eslint-disable-next-line
    }, [tableRows])

    return (
        <Container
            maxWidth="xl"
            sx={{
                marginTop: '20px',
            }}>
            <Box sx={{
                height: 'calc(100vh - 64px - 76px - 20px - 20px)', // 100vh da tela - topbar h - stepper h - content margintop 
                display: 'grid',
                gridTemplateColumns: { md: '2fr 1fr', xs: '1fr' },
                gridTemplateRows: { md: 'auto 100px 50px', xs: 'auto' },
                gap: '20px',
            }}>
                <Box>
                    <TableContainer
                        sx={{
                            height: { md: '100%', xs: '450px' },
                            backgroundColor: '#FFF',
                            borderRadius: 1,
                            position: 'relative',
                            boxSizing: 'border-box',
                            border: (location.state && location.state.isVisualizar) ? "3px solid #9F9F9F" : {}
                        }}
                        className="color-orange"
                    >
                        <Table
                            stickyHeader
                            size="small"
                        >
                            <TableHead>
                                <TableRow >
                                    <TableCell>Formas de pagamento</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell align="center">Valor</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {tableRowsDevolucao.map((row, index) => (
                                    <TableRow
                                        id={`devolucao_row_${index}`}
                                        key={row.name + index}
                                        sx={{
                                            height: '50px',
                                        }}
                                    >
                                        <TableCell id={`devolucao_descritivo_row_${index}`} align="left" >{`${++index} - ${row.descritivoFormaPagamento}`}</TableCell>
                                        <TableCell id={`devolucao_infoadicional_row_${index - 1}`} align="center">{`${row.infoAdicional?.descritivo ?? ''}`}</TableCell>
                                        <TableCell id={`devolucao_value_row_${index - 1}`} align="center">{`${currencyOf(row.valor)}`}</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                ))}

                                {tableRows.map((row, index) => (
                                    <TableRow
                                        id={`pagamento_row_${index}`}
                                        key={row.name + index}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            height: '50px',
                                        }}
                                    >
                                        <TableCell sx={{
                                            color: row.isPago ? globalStyles.PRETO : globalStyles.LARANJA
                                        }}
                                            id={`pagamento_descritivo_row_${index}`}
                                            align="left">
                                            {`${tableRowsDevolucao.length ? index + 2 : ++index} - ${row.descritivoFormaPagamento}`}
                                        </TableCell>
                                        <TableCell sx={{
                                            color: row.isPago ? globalStyles.PRETO : globalStyles.LARANJA
                                        }}
                                            id={`pagamento_infoadicional_row_${index - 1}`}
                                            align="center">
                                            {`${row.infoAdicional?.descritivo ?? ''}`}
                                        </TableCell>
                                        <TableCell sx={{
                                            color: row.isPago ? globalStyles.PRETO : globalStyles.LARANJA
                                        }}
                                            id={`pagamento_value_row_${index - 1}`}
                                            align="center">
                                            {`${currencyOf(row.valor)}`}
                                        </TableCell>
                                        <TableCell sx={{
                                            color: row.isPago ? globalStyles.PRETO : globalStyles.LARANJA
                                        }} align="right">
                                            {(row.infoAdicional?.descritivo !== "VENDA" && row.isEditavel) &&
                                                <IconButton
                                                    id={`pagamento_ellipsis_row_${index - 1}`}
                                                    color="warning"
                                                    onClick={(e) => onClickMenuRow(e, { row, index: tableRowsDevolucao.length === 0 ? --index : index })}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}

                                <Menu
                                    anchorEl={anchorEl}
                                    open={openMenuRow}
                                    onClose={() => onCloseMenuRow(true)}
                                >
                                    <MenuList>
                                        {selectedRow?.row && selectedRow.row.descritivoFormaPagamento !== "TROCO" &&
                                            <MenuItem onClick={onExcluirRow}>
                                                <ListItemIcon>
                                                    <Delete fontSize="small" />
                                                </ListItemIcon>

                                                <ListItemText>Excluir</ListItemText>
                                            </MenuItem>
                                        }

                                        <MenuItem onClick={() => onEditarRow(false)}>
                                            <ListItemIcon>
                                                <EditIcon fontSize="small" />
                                            </ListItemIcon>

                                            <ListItemText>Editar</ListItemText>
                                        </MenuItem>
                                        {selectedRow?.row && !selectedRow?.row.isPago && selectedRow.row.descritivoFormaPagamento !== "TROCO" &&
                                            <MenuItem onClick={() => onSincronizarRow(selectedRow.row)}>
                                                <ListItemIcon>
                                                    <RefreshOutlined fontSize="small" />
                                                </ListItemIcon>

                                                <ListItemText>Sincronizar</ListItemText>
                                            </MenuItem>
                                        }
                                    </MenuList>
                                </Menu>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                <Box
                    sx={{
                        gridRowStart: { xs: 0, sm: 0, md: 1 },
                        gridRowEnd: { xs: 1, sm: 1, md: 2 },
                        gridColumnStart: { xs: 1, sm: 1, md: 2 },
                        gridColumnEnd: { xs: 2, sm: 2, md: 3 },
                        display: 'grid',
                        gridTemplateColumns: { xs: '1fr 1fr', sm: '1fr 1fr 1fr', md: '1fr 1fr' },
                        columnGap: '20px',
                        rowGap: '15px',
                        height: 'fit-content',
                        position: 'relative'
                    }}>
                    {stateLocal.showMaisFormasPagamentos &&
                        <Box sx={{ position: 'absolute', width: "99%", zIndex: 1, bottom: "-225px", left: 5 }}>
                            <CustomGridOption
                                disabled={isCrediarioDisabled()}
                                onClick={() => handleGenericFormaPagamento("CREDIARIO", true, isCrediarioDisabled())}
                                icon={<Icon className="fa-regular fa-file-lines" sx={{ color: '#000', fontSize: '22px', width: '10%' }} />}
                                title="Crediário"
                            />
                            <CustomGridOption
                                onClick={() => handleGenericFormaPagamento("CREDITOLOJA", true)}
                                icon={<Icon className="fa-regular fa-file-lines" sx={{ color: '#000', fontSize: '22px', width: '10%' }} />}
                                marginTop="5px"
                                title="Crédito de loja"
                            />
                            <CustomGridOption
                                onClick={() => handleGenericFormaPagamento("DEPOSITOBANCARIO", true)}
                                icon={<Icon className="fa-regular fa-file-lines" sx={{ color: '#000', fontSize: '22px', width: '10%' }} />}
                                marginTop="5px"
                                title="Depósito bancário"
                            />
                            <CustomGridOption
                                onClick={() => handleGenericFormaPagamento("OUTROS", true)}
                                icon={<Icon className="fa-regular fa-file-lines" sx={{ color: '#000', fontSize: '22px', width: '10%' }} />}
                                marginTop="5px"
                                title="Outros"
                            />

                        </Box>
                    }
                    {formasPagamento
                        .filter(item => item.card.visible !== false)
                        .map(({ card, dialog, id, label }) => {
                            return (
                                <div key={id}>
                                    <FormaPagamentoCard
                                        id={id}
                                        icon={card.icon}
                                        label={card.label ? card.label : label}
                                        disabled={card.disabled ? card.disabled : getDisabledCard(tableRows, faltaReceber)}
                                        onClick={() => {
                                            if (stateLocal.showMaisFormasPagamentos) setStateLocal(prevstate => ({ ...prevstate, showMaisFormasPagamentos: false }));
                                            card.onClick ? card.onClick(id) : handleClickOpen(id);
                                        }}
                                    />
                                    <FormaPagamentoDialog
                                        preventConfirmar={{
                                            value: preventConfirmar,
                                            setPreventConfirmar
                                        }}
                                        disableDefaultButtons={dialog.disableDefaultButtons}
                                        disableTitle={dialog.disableTitle}
                                        open={dialog.open ? dialog.open : getOpenDialog(id)}
                                        confirmButton={{
                                            onConfirm: () => dialog.confirmButton?.onConfirm ? dialog.confirmButton.onConfirm(id) : onConfirmGeneric(id, null),
                                            disabled: dialog.confirmButton?.disabled ? dialog.confirmButton.disabled : getDisabledDialogGeneric()
                                        }}
                                        setSubmitted={setSubmitted}
                                        onClose={dialog.onClose ? () => dialog.onClose?.(stateLocal.title ?? id) : () => onCloseGeneric(id)}
                                        title={stateLocal.title ? captalizeString(stateLocal.title) : dialog.title ?? label}
                                        fullWidth={dialog.fullWidth}
                                        maxWidth={dialog.maxWidth}
                                    >
                                        {!stateLocal.showMaisFormasPagamentos ? dialog.children : <></>}
                                    </FormaPagamentoDialog>
                                </div>
                            );
                        })}
                    {stateLocal.title && (
                        stateLocal.title === "CREDIARIO"
                        || stateLocal.title === "CREDITOLOJA"
                        || stateLocal.title === "DEPOSITOBANCARIO"
                    ) &&
                        <FormaPagamentoDialog
                            disableDefaultButtons={false}
                            disableTitle={false}
                            preventConfirmar={{
                                value: preventConfirmar,
                                setPreventConfirmar
                            }}
                            open={true}
                            confirmButton={{
                                onConfirm: () => {
                                    if (!dadosPagamento.infoAdicional?.dadosGeneric?.every(item => item.vencimento)) {
                                        return addError({ message: 'Preencha todas as datas de vencimento', closeable: true });
                                    }
                                    onConfirmGeneric(stateLocal.title!, null);
                                    setStateLocal(prevState => ({ ...prevState, showMaisFormasPagamentos: false, title: null }));
                                },
                                disabled: getDisabledDialogGeneric()
                            }}
                            maxWidth="sm"
                            fullWidth={true}
                            title={getByTitle(stateLocal.title)}
                            onClose={() => {
                                if (rowValueRef.current) {
                                    rowValueRef.current = null;
                                    dispatch(resetFaltaReceber());
                                }
                                setStateLocal(prevState => ({ ...prevState, showMaisFormasPagamentos: false, title: null }));
                            }}
                        >
                            <GenericOutrasFormasPagamento
                                isNew={isNew}
                                error={{ hasError, setHasError }}
                                valorRecebido={{ dadosPagamento, setDadosPagamento }}
                                tipo={getTipoInformacaoAdicionalGeneric(stateLocal.title)}
                            />
                        </FormaPagamentoDialog>
                    }
                </Box>

                <Footer
                    onClickAvancar={props.footer.onClickAvancar}
                    onClickCancelar={() => {
                        props.footer.onClickCancelar?.();
                        onClickVoltar();
                    }}
                    floatingActionsProps={props.footer.floatingActions}
                    isVisualizar={(location.state && location.state.isVisualizar) ?? false}
                    showModalTroco={props.showModalTroco}
                />
            </Box>

            {props.children}
        </Container >
    );
};

export default TelaFormasPagamentoComponent;
