import { AxiosResponse } from "axios";
import { IAbstractModel } from "model/abstract.model";
import { IConsumidor } from "model/cliente.model";
import LoginService from "pages/login/login.service";
import { useAppSelector } from "redux/hooks";
import { axiosRequest } from "../../axios/axios.config";
import { EstoqueTO, FlowConsultaCartaoPagoTO, FlowCreatePedidoCieloTOResponse, FlowVendaTO, IProduto } from "./registra-venda.model";
import { FlowCreatePedidoCieloTO, FlowPedidoCieloIsPagoTO } from "pages/pagamento/formapagamento/formapagamento.model";

const RegistraVendaService = () => {
    const path = '/flow';
    const pessoa = '/pessoa'
    const loginService = LoginService();
    const pedido = useAppSelector(s => s.state.pedido);
    const depositoUUID = useAppSelector(s => s.configuracoes.configuracaoPedidoBalcao.deposito.uuid);
    const { tableRows, tableRowsDevolucao, troco } = useAppSelector(s => s.pagamento);
    const { token: tokenAutorizador } = useAppSelector(e => e.configuracoes.configuracaoDescontoAutorizador);

    const getPedido = (): FlowVendaTO => {
        return { ...pedido, itensPagamento: tableRows, itensPagamentoDevolucao: tableRowsDevolucao, troco };
    };

    return {
        novoPedido(): Promise<AxiosResponse<FlowVendaTO>> {
            return axiosRequest.get(`${path}/novo`, { headers: loginService.getHeaders() });
        },
        getConsumidoresAc(toSearch: string): Promise<AxiosResponse<Array<IConsumidor>>> {
            return axiosRequest.get(`${path}/consumidores`, { headers: loginService.getHeaders(), params: { toSearch } });
        },
        getVendedoresAc(toSearch: string): Promise<AxiosResponse<Array<IAbstractModel>>> {
            return axiosRequest.get(`${path}/vendedores`, { headers: loginService.getHeaders(), params: { toSearch } });
        },
        getSkusAc(toSearch: string, tabelaPrecoUUID: string | null): Promise<AxiosResponse<Array<IProduto>>> {
            return axiosRequest.get(`${path}/descricaosku`, {
                headers: loginService.getHeaders(),
                params: {
                    toSearch,
                    tabelaPrecoUUID: tabelaPrecoUUID !== '' ? tabelaPrecoUUID : null
                }
            });
        },
        getProdutoByCodigo(codigoBarras: string, tabelaPrecoUUID: string | null): Promise<AxiosResponse<IProduto>> {
            return axiosRequest.get(`${path}/leitorproduto/`, {
                headers: loginService.getHeaders(),
                params: {
                    codigo: codigoBarras ?? null,
                    tabelaPrecoUUID: tabelaPrecoUUID !== '' ? tabelaPrecoUUID : null
                }
            });
        },
        salvarVenda(): Promise<AxiosResponse<FlowVendaTO>> {
            return axiosRequest.put(`${path}/salvarvenda`, getPedido(), { headers: tokenAutorizador ? loginService.getHeadersWithAutorizadorToken() : loginService.getHeaders(), params: { depositoUUID } });
        },
        finalizarVenda(isForcedNfe: boolean): Promise<AxiosResponse<{ pedido: FlowVendaTO, urlDanfe: string }>> {
            return axiosRequest.post(`${path}/finalizarvenda`, getPedido(), {
                headers: tokenAutorizador ? loginService.getHeadersWithAutorizadorToken() : loginService.getHeaders(),
                params: { optionalModelo: null, outros: false, depositoUUID, isForcedNfe }
            });
        },
        finalizarVendaNFE(isForcedNfe: boolean): Promise<AxiosResponse<{ pedido: FlowVendaTO, urlDanfe: string; }>> {
            return axiosRequest.post(`${path}/finalizarvenda`, getPedido(), {
                headers: tokenAutorizador ? loginService.getHeadersWithAutorizadorToken() : loginService.getHeaders(),
                params: { optionalModelo: 'NFE', outros: false, depositoUUID, isForcedNfe }
            });
        },
        finalizarVendaOutros(isForcedNfe: boolean): Promise<AxiosResponse<{ pedido: FlowVendaTO; }>> {
            return axiosRequest.post(`${path}/finalizarvenda`, getPedido(), {
                headers: tokenAutorizador ? loginService.getHeadersWithAutorizadorToken() : loginService.getHeaders(),
                params: { optionalModelo: null, outros: true, depositoUUID, isForcedNfe }
            });
        },
        getEstoque(uuids: string): Promise<AxiosResponse<Array<EstoqueTO>>> {
            return axiosRequest.get(`${path}/estoque`, { headers: loginService.getHeaders(), params: { uuids, depositoUUID } });
        },
        getTransportadoras(): Promise<AxiosResponse<Array<IAbstractModel>>> {
            return axiosRequest.get(`${pessoa}/transportadoras`, { headers: loginService.getHeaders() });
        },
        getDadosPagamentoCartao(): Promise<AxiosResponse<any>> {
            return axiosRequest.get(`${pessoa}/dadospagamentocartao`, { headers: loginService.getHeaders() })
        },
        createFlowPagamentoCartao(flowCreatePedidoCieloTO: FlowCreatePedidoCieloTO): Promise<AxiosResponse<FlowCreatePedidoCieloTOResponse>> {
            return axiosRequest.post(`pedidopagamentocartao/createflowpagamentocartao`, flowCreatePedidoCieloTO, { headers: loginService.getHeaders() })
        },
        cartaoIntegracaoArePago(itens: Array<FlowPedidoCieloIsPagoTO>): Promise<AxiosResponse<FlowConsultaCartaoPagoTO>> {
            return axiosRequest.post(`pedidopagamentocartao/cartaointegracaoarepago`, itens, { headers: loginService.getHeaders() })
        },
    };
};

export default RegistraVendaService;
