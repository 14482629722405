import { AxiosResponse } from "axios";
import { GenericTO } from "model/generic.model";
import LoginService from "pages/login/login.service";
import { axiosRequest } from "../axios/axios.config";


const PlanoContaService = () => {
    const path = '/planoconta';
    const loginService = LoginService();

    return {
        getContaTroco(): Promise<AxiosResponse<GenericTO | null>> {
            return axiosRequest.get(`${path}/contatroco`, { headers: loginService.getHeaders() });
        },
        getContaValePresente(): Promise<AxiosResponse<GenericTO | null>> {
            return axiosRequest.get(`${path}/contavalepresente`, { headers: loginService.getHeaders() });
        },

    };

};

export default PlanoContaService;
