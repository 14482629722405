import { Icon, Paper, Typography } from "@mui/material";
import React from "react";
import { FormaPagamentoCardProps } from "./formapagamento.model";

export const FormaPagamentoCard = (props: FormaPagamentoCardProps) => {
    return (
        <Paper
            id={props.id ? props.id.toLowerCase() + '-card' : undefined}
            elevation={2}
            sx={{
                minWidth: { xs: 100, sm: 100, md: 200 },
                height: props.height ?? 80,
                background: !props.disabled ? '' : 'rgba(0, 0,0,0.07)',
                '&:hover': {
                    background: props.disabled ? 'rgba(0, 0,0,0.07)' : 'rgba(0, 0,0,0.025)',
                },
                color: '#444',
                cursor: props.disabled ? 'auto' : 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '15px',
                padding: '15px',
                borderRadius: '20px'
            }}
            onClick={!props.disabled ? () => props.onClick!(props.id) : () => { }}
        >
            {props.icon.component
                ? React.cloneElement(props.icon.component, {
                    sx: {
                        fontSize: '36px',
                        width: '40px',
                        color: props.disabled ? 'rgba(0, 0,0,0.25)' : 'var(--laranja)'
                    }
                })
                : <Icon
                    className={`${props.icon.type ? props.icon.type : 'fa-solid'} ${props.icon.name}`}
                    sx={{
                        fontSize: '30px',
                        width: '40px',
                        color: props.disabled ? 'rgba(0, 0,0,0.25)' : 'var(--laranja)'
                    }}
                ></Icon>
            }
            <Typography
                sx={{
                    width: '100%',
                    textAlign: 'center',
                    userSelect: 'none',
                }}
            >{props.label}</Typography>
        </Paper >
    );
};
