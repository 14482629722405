import { IconButton } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { Document, PDFViewer, Page, Text, View } from '@react-pdf/renderer';
import { currencyOf, limpaString, showOnlyDate, showOnlyDateTwoDigts, toLocaleDateTimeString } from "utils/util";
import { FlowVendaTO } from "pages/registra-venda/registra-venda.model";
import { useAppSelector } from "redux/hooks";
import { PessoaSimplesTO, TDadosPedido, TDadosPessoa } from "./comprovante-nota-fiscal-outros.model";
import { useCallback, useEffect, useState } from "react";
import ComprovanteNFOutrosService from "./comprovante-nota-fiscal-outros.service";
import { mascaraCpfCnpj } from "pages/cpfNota/CpfNota";
import { s } from "./comprovanteCss";
import { formatPhoneNumber } from '../../utils/util';

export type PdfComprovanteCrediarioProps = {
    onCloseComprovante: () => void;
    flowVendaTO: FlowVendaTO;
    emissaoNota?: React.MutableRefObject<Date | undefined>
}

const initialState = {
    cnpj: '' as string,
    inscricaoEstadual: '' as string,
    enderecoCompleto: '' as string,
    cidadeEstado: '' as string,
    cliente: '' as string,
    documentoNumero: 0 as number,
    emissao: '' as string,
    observacao: '' as string,
    dadosSimplesTO: null as PessoaSimplesTO | null
};


const PdfComprovanteCrediario = (props: PdfComprovanteCrediarioProps) => {
    const { estabelecimentoSelecionado, token } = useAppSelector(e => e);
    const comprovanteService = ComprovanteNFOutrosService();
    const [stateLocal, setStateLocal] = useState(initialState)

    const getTotal = useCallback(() => {
        return currencyOf(props.flowVendaTO.parcelasCrediario.reduce((prev, act) => prev += act.valor, 0))
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        let dadosPessoa: TDadosPessoa = {
            cidadeEstado: '',
            cnpj: '',
            enderecoCompleto: '',
            inscricaoEstadual: ''
        };

        let dadosPedido: TDadosPedido = {
            cliente: '',
            documentoNumero: 0,
            emissao: '',
            observacao: '',
            pedidoNumero: 0
        };

        let dadosSimplesTO: PessoaSimplesTO = {
            nome: "",
            identificacao: "",
            celular: "",
            comercial: "",
            email: "",
            endereco: ""
        }

        comprovanteService.getDadosPessoa()
            .then(({ data }: { data: TDadosPessoa; }) => {
                dadosPessoa = data;
                return comprovanteService.getDadosPedido(props.flowVendaTO.numero);
            }).then(({ data }: { data: TDadosPedido; }) => {
                dadosPedido = data;
                if (props.flowVendaTO.cliente?.descritivo) {
                    const cpfCnpj: string = limpaString(props.flowVendaTO.cliente.descritivo.split(' - ')[0])
                    return comprovanteService.getPessoaSimples(cpfCnpj).then(({ data }) => {
                        dadosSimplesTO = data
                    })
                }
            })
            .catch(() => { }).finally(() => {
                if (dadosPessoa.cnpj) {
                    setStateLocal(prevState => ({
                        ...prevState,
                        cnpj: dadosPessoa.cnpj,
                        inscricaoEstadual: dadosPessoa.inscricaoEstadual,
                        enderecoCompleto: dadosPessoa.enderecoCompleto,
                        cidadeEstado: dadosPessoa.cidadeEstado,
                        cliente: dadosPedido.cliente ? dadosPedido.cliente : 'CONSUMIDOR',
                        emissao: dadosPedido.emissao,
                        observacao: dadosPedido.observacao ? dadosPedido.observacao : '',
                        documentoNumero: dadosPedido.documentoNumero,
                        dadosSimplesTO: dadosSimplesTO ?? null
                    }));
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getLocalTime = useCallback(() => {
        const localTime = new Date();
        const hours = localTime.getHours().toString().padStart(2, '0');
        const minutes = localTime.getMinutes().toString().padStart(2, '0');
        const seconds = localTime.getSeconds().toString().padStart(2, '0');

        return ` às ${hours}:${minutes}:${seconds}`
    }, [])

    const getEmissaoDate = () => {
        if (props.emissaoNota?.current) return toLocaleDateTimeString(props.emissaoNota.current)
        if (stateLocal.emissao) return showOnlyDate(stateLocal.emissao).concat(getLocalTime())
        if (props.flowVendaTO.emissao) return showOnlyDate(props.flowVendaTO.emissao.toString())
        //eslint-disable-next-line
    }

    return (
        <>
            <IconButton
                sx={{
                    zIndex: 100000,
                    position: 'absolute',
                    bottom: 20,
                    right: 30,
                    backgroundColor: 'var(--laranja)',
                    ':hover': {
                        backgroundColor: 'var(--laranja)',
                        filter: 'grayscale(.2) brightness(.8) contrast(1.5)'
                    }
                }}
                onClick={props.onCloseComprovante}
            >
                <CloseIcon sx={{ color: '#fff', fontSize: 45 }} />
            </IconButton>

            <PDFViewer
                style={s.pdfView}
                showToolbar={true}
            >
                <Document
                    title='Crediário'
                    author={''}
                    language='pt-BR'
                >
                    <Page
                        size={'A7'}
                        orientation='portrait'
                        wrap={false}
                        style={s.page}
                    >
                        <View style={{ ...s.tableRow }} fixed>
                            <View>
                                <View style={{
                                    display: "flex",
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    borderBottom: "1px solid black",
                                    paddingBottom: '5px',
                                    paddingTop: '5px',
                                    marginHorizontal: '10px'
                                }}>
                                    <Text id="comprovante-nao-fiscal-pessoa-descritivo" style={{ fontSize: '8px', fontWeight: 'medium' }}>{estabelecimentoSelecionado.descritivo.substring(8)}</Text>
                                    <Text id="comprovante-nao-fiscal-pessoa-cnpj" style={{ fontSize: '8px' }}>CNPJ: {mascaraCpfCnpj(stateLocal.cnpj)} </Text>
                                    <Text id="comprovante-nao-fiscal-pessoa-inscricao-estadual" style={{ fontSize: '8px' }}>I.E {stateLocal.inscricaoEstadual} </Text>
                                    <Text id="comprovante-nao-fiscal-pessoa-endereco-completo" style={{ fontSize: '8px' }}>{stateLocal.enderecoCompleto}</Text>
                                    <Text id="comprovante-nao-fiscal-pessoa-cidade-estado" style={{ fontSize: '8px' }}>{stateLocal.cidadeEstado}</Text>
                                </View>
                                <View style={{ display: "flex", alignItems: 'center', flexDirection: 'column', borderBottom: "1px solid black", paddingBottom: '5px', paddingTop: '5px', marginHorizontal: '10px' }}>
                                    <Text id="comprovante-nao-fiscal-info-0" style={{ fontSize: '8px' }}>CARNÊ CONFISSÃO DE DÍVIDA</Text>
                                </View>
                            </View>
                        </View>
                        <View style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                            <Text id="comprovante-nao-fiscal-pedido-cliente" style={{ fontSize: '8px' }}>Cliente: {stateLocal.cliente} </Text>
                            {stateLocal.dadosSimplesTO?.celular &&
                                <Text id="comprovante-nao-fiscal-pedido-celular" style={{ fontSize: '8px' }}>Celular: {formatPhoneNumber(stateLocal.dadosSimplesTO?.celular)} </Text>
                            }
                            {stateLocal.dadosSimplesTO?.comercial &&
                                <Text id="comprovante-nao-fiscal-pedido-comercial" style={{ fontSize: '8px' }}>Comercial: {formatPhoneNumber(stateLocal.dadosSimplesTO.comercial)} </Text>
                            }
                            {stateLocal.dadosSimplesTO?.email &&
                                <Text id="comprovante-nao-fiscal-pedido-celular" style={{ fontSize: '8px' }}>Email: {stateLocal.dadosSimplesTO.email} </Text>
                            }
                            {stateLocal.dadosSimplesTO?.endereco &&
                                <Text id="comprovante-nao-fiscal-pedido-celular" style={{ fontSize: '8px' }}>Endereço: {stateLocal.dadosSimplesTO.endereco} </Text>
                            }
                            <Text style={{
                                borderBottom: "1px solid black",
                                marginTop: '3px',
                                marginBottom: '3px'
                            }} />

                            <Text id="comprovante-nao-fiscal-pedido-numero-documento" style={{ fontSize: '8px' }}>Pedido número: {props.flowVendaTO.numero} </Text>
                            <Text id="comprovante-nao-fiscal-pedido-numero-documento" style={{ fontSize: '8px' }}>Documento número: {stateLocal.documentoNumero} </Text>
                            <Text id="comprovante-nao-fiscal-emissao" style={{
                                fontSize: '8px',
                            }}>Processado em: {getEmissaoDate()}
                                {""} pelo usuário {token.USER_INFO?.username.toUpperCase()}
                            </Text>
                            {props.flowVendaTO.parcelasCrediario.length &&
                                <View style={{ backgroundColor: 'transparent' }}>
                                    <View style={{
                                        border: '1px solid black',
                                        borderRadius: '5px',
                                        paddingVertical: '10px',
                                        width: '100%',
                                        marginTop: '10px'

                                    }}>
                                        <View style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                            <Text style={{
                                                fontSize: '8px',
                                                marginBottom: '10px'
                                            }}>
                                                PEDIDO N° {props.flowVendaTO?.numero}
                                            </Text>
                                        </View>
                                        <View style={{
                                            flexDirection: 'row',
                                            justifyContent: 'space-evenly',
                                            paddingLeft: '5px'
                                        }}>
                                            <Text style={{ fontSize: '8px' }}>Vencimento</Text>
                                            <Text style={{ fontSize: '8px' }}>Valor</Text>
                                            <Text style={{ fontSize: '8px' }}>Assinatura</Text>
                                        </View>
                                        {props.flowVendaTO.parcelasCrediario.map(e => {
                                            return (
                                                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '10px' }}>
                                                    <Text style={{ fontSize: '8px' }}>{e.numeroParcela}x</Text>
                                                    <Text style={{ fontSize: '8px' }}>{showOnlyDateTwoDigts(e.vencimento.toString())}</Text>
                                                    <Text style={{ fontSize: '8px' }}>{currencyOf(e.valor)}</Text>
                                                    <Text style={{ fontSize: '8px' }}>____________</Text>
                                                </View>
                                            )
                                        })}
                                        <Text style={{
                                            fontSize: '8px',
                                            marginTop: '10px',
                                            textAlign: 'center',
                                            paddingRight: '25px'
                                        }}>
                                            Total:   {getTotal()}
                                        </Text>
                                    </View>
                                    <Text style={{
                                        fontSize: '8px',
                                        marginTop: '10px',
                                        textAlign: 'justify'
                                    }}>
                                        &nbsp; &nbsp; &nbsp; &nbsp; Reconheço e confesso que possuo o débito de {getTotal()} e que pagarei
                                        meu crediário ou a quem estabelecer nas datas discriminadas.
                                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;Aceito que o não pagamento das parcelas no vencimento gerará multas e juros sobre o débito, e serão
                                        efetuados procedimentos de cobrança, negativação e protesto. Autorizo se necessário contato telefônico, whatsapp, e-mail e sms.
                                        {"\n"}  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Confirmo a veracidade das informações e que resido no endereço acima.
                                    </Text>
                                    <View style={{
                                        display: 'flex',
                                        width: '100%'
                                    }}>
                                        <View>
                                            <Text style={{ textAlign: 'center' }}>_______________</Text>
                                            <Text style={{ textAlign: 'center', fontSize: '8px', marginTop: '5px' }}>{props.flowVendaTO.cliente?.descritivo ?? "CONSUMIDOR"}</Text>
                                        </View>
                                    </View>
                                </View>
                            }
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        </>
    )
}

export default PdfComprovanteCrediario