import { Container, FormControl, FormHelperText, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { IAbstractModel } from 'model/abstract.model';
import { IDadosCheque, IInfoAdicional, TipoInformacaoAdicional } from 'pages/pagamento/pagamento.model';
import PagamentoService from 'pages/pagamento/pagamento.service';
import { FormaPagamentoDialogContentProps } from '../formapagamento.model';
import LinhaDadosCheque from '../LinhaDadosCheque';
import FaltaReceberComponent from 'components/FaltaReceberComponent';
import { roundHalfToEven } from 'utils/util';

const ChequeDialogContent = (props: FormaPagamentoDialogContentProps) => {
    const tipo = TipoInformacaoAdicional.CHEQUE;
    const { faltaReceber } = useAppSelector(s => s.pagamento);
    const { dadosPagamento, setDadosPagamento } = props.valorRecebido;
    const { tableRows } = useAppSelector(e => e.pagamento)

    const tableRowsValue = useMemo(() => {
        return tableRows.filter(e => !e.isPago).reduce((prev, act) => prev += act.valor, 0)
    }, [tableRows])

    if (!props.valorRecebido.dadosPagamento.infoAdicional) {
        props.valorRecebido.dadosPagamento.infoAdicional = {
            numeroParcelas: 1, descritivo: '', dadosCheque: [{
                banco: null,
                agencia: '',
                numeroCheque: null,
                vencimentoDisplay: '',
                vencimento: '',
                valor: faltaReceber - tableRowsValue,
                emitente: null
            }], tipo
        };
    }
    const { hasError, setHasError } = props.error;
    const service = PagamentoService();
    const [bancos, setBancos] = useState<IAbstractModel[]>([]);

    const updateNumeroCheque = useCallback((numeroCheque: string | null, index: number) => {
        if (!numeroCheque || isNaN(Number(numeroCheque))) return null
        if (index === 0) return numeroCheque
        let numero = Number(numeroCheque)
        numero += index
        return numero.toString()
        //eslint-disable-next-line
    }, [])

    const rateiaFaltaReceberEntreParcelas = (numeroParcela: number) => {
        if (dadosPagamento.infoAdicional) {
            const faltaReceberCentavos = faltaReceber * 100;
            const sobraCentavos = faltaReceberCentavos % numeroParcela;
            const valorRateadoCentavos = (faltaReceberCentavos - sobraCentavos) / numeroParcela;
            const ultimaParcelaCentavos = valorRateadoCentavos + sobraCentavos;
            const updatedDadosCheque = dadosPagamento.infoAdicional.dadosCheque!;

            for (let index = 0; index < numeroParcela; index++) {
                const isUltimaParcela = index === numeroParcela - 1;
                updatedDadosCheque[index] = {
                    banco: updatedDadosCheque[0]?.banco ? updatedDadosCheque[0]?.banco : { uuid: '', descritivo: '' },
                    agencia: updatedDadosCheque[0]?.agencia ?? '',
                    numeroCheque: updateNumeroCheque(updatedDadosCheque[0]?.numeroCheque, index) ?? null,
                    vencimentoDisplay: updatedDadosCheque[index]?.vencimentoDisplay ?? '',
                    vencimento: updatedDadosCheque[index]?.vencimento ?? "",
                    valor: (isUltimaParcela ? ultimaParcelaCentavos : valorRateadoCentavos) / 100,
                    emitente: updatedDadosCheque[0]?.emitente ?? null
                };
            }

            const updatedInfoAdicional = Object.assign({}, dadosPagamento.infoAdicional, {
                dadosCheque: updatedDadosCheque,
            });

            setDadosPagamento((prevDadosPagamento) => ({
                ...prevDadosPagamento,
                infoAdicional: updatedInfoAdicional,
            }));
        }
    };

    useEffect(() => {
        if (props.isNew) {
            setDadosPagamento({
                valorRecebido: faltaReceber, infoAdicional: {
                    numeroParcelas: 1, descritivo: '', dadosCheque: [{
                        banco: { uuid: '', descritivo: '' },
                        agencia: '',
                        numeroCheque: null,
                        vencimentoDisplay: '',
                        valor: faltaReceber,
                        vencimento: '',
                        emitente: null
                    }], tipo
                }
            });
        }
        getBancos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isNew]);

    useEffect(() => {
        const total = roundHalfToEven(somaCheque((dadosPagamento.infoAdicional as IInfoAdicional)), 2);
        setDadosPagamento(prev => ({ ...prev, valorRecebido: total }));
        faltaReceber - total >= 0 ? setHasError(false) : setHasError(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(dadosPagamento.infoAdicional as IInfoAdicional).dadosCheque]);

    useEffect(() => {
        handleTableRow((dadosPagamento.infoAdicional as IInfoAdicional).numeroParcelas);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(dadosPagamento.infoAdicional as IInfoAdicional).numeroParcelas]);

    const somaCheque = useCallback((cheques: IInfoAdicional) => {
        let soma = 0;
        cheques.dadosCheque?.forEach((cheque: IDadosCheque) => {
            soma += cheque.valor;
        });
        return soma;
    }, []);

    const getBancos = () => {
        service.getBancos().then(r => {
            setBancos(prev => r.data);
        });
    };

    const handleTableRow = (numeroParcela: number) => {
        const dadosCheque = Array.from((dadosPagamento.infoAdicional as IInfoAdicional).dadosCheque ?? []);

        if (dadosCheque.length > numeroParcela && numeroParcela > 0) {
            const parcelasExtra = dadosCheque.length - numeroParcela;
            for (let i = 0; i < parcelasExtra; i++) {
                dadosCheque.pop();
            }
        }

        for (let i = dadosCheque.length; i < numeroParcela; i++) {
            dadosCheque.push({
                banco: { uuid: '', descritivo: '' },
                agencia: '',
                numeroCheque: null,
                vencimentoDisplay: '',
                vencimento: '',
                valor: faltaReceber,
                emitente: null
            });
        }

        setDadosPagamento(prevDadosPagamento => ({
            ...prevDadosPagamento,
            infoAdicional: {
                ...prevDadosPagamento.infoAdicional!,
                dadosCheque: dadosCheque,
            },
        }));
    };

    const handleBlurFirstRow = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>, campo: string) => {
        const novosDadosCheque = (dadosPagamento.infoAdicional as IInfoAdicional).dadosCheque?.map((row, index) => {
            let novoValor: string | IAbstractModel = event.target.value;
            if (index === 0) return row;

            if (campo === 'banco') {
                novoValor = bancos.find(banco => banco.descritivo === event.target.value) ?? { descritivo: '', uuid: '' };
            }

            if (campo === 'numeroCheque') {
                const numeroCheque = acrescentaNumeroCheque(event.target.value, (dadosPagamento.infoAdicional as IInfoAdicional).numeroParcelas);
                numeroCheque.forEach(e => {
                    if (!Number.isNaN(e)) {
                        novoValor = numeroCheque[index].toString().padStart(6, "0");
                    }
                });
            }

            return {
                ...row,
                [campo]: novoValor,
            };
        });

        setDadosPagamento((prevDadosPagamento) => ({
            ...prevDadosPagamento,
            infoAdicional: {
                ...prevDadosPagamento.infoAdicional!,
                dadosCheque: novosDadosCheque,
            },
        }));
    };

    const acrescentaNumeroCheque = (numeroCheque: string, qtdParcelas: number) => {
        const numerosCheque = [];
        let numeroAtual = parseInt(numeroCheque);

        for (let i = 0; i < qtdParcelas; i++) {
            numerosCheque.push(numeroAtual);
            numeroAtual++;
        }

        return numerosCheque;
    };

    const handleChangeParcela = (value: number) => {
        if (value < 0 || value === 0 || Number.isNaN(value)) return;

        rateiaFaltaReceberEntreParcelas(value);

        setDadosPagamento(prev => {
            return ({ ...prev, infoAdicional: { descritivo: '', numeroParcelas: value, dadosCheque: prev.infoAdicional?.dadosCheque, tipo }, });
        });
    };

    const handleChangeValor = (index: number, valor: number = 0) => {
        if (valor > faltaReceber) {
            setHasError(true);
        } else {
            setHasError(false);
        }
        if ((dadosPagamento.infoAdicional as IInfoAdicional)) {
            const updatedDadosCheque = [...(dadosPagamento.infoAdicional as IInfoAdicional).dadosCheque!];

            if (index >= 0 && index < updatedDadosCheque.length) {
                updatedDadosCheque[index] = { ...updatedDadosCheque[index], valor };

                setDadosPagamento(prevDadosPagamento => ({
                    ...prevDadosPagamento,
                    infoAdicional: {
                        ...prevDadosPagamento.infoAdicional!,
                        dadosCheque: updatedDadosCheque,
                    },
                }));
            }
        }
    };

    const handleChangeBanco = (index: number, value: IAbstractModel) => {
        if ((dadosPagamento.infoAdicional as IInfoAdicional)) {
            const updatedDadosCheque = [...(dadosPagamento.infoAdicional as IInfoAdicional).dadosCheque!];

            if (index >= 0 && index < updatedDadosCheque.length) {
                updatedDadosCheque[index] = { ...updatedDadosCheque[index], banco: value };

                setDadosPagamento(prevDadosPagamento => ({
                    ...prevDadosPagamento,
                    infoAdicional: {
                        ...prevDadosPagamento.infoAdicional!,
                        dadosCheque: updatedDadosCheque,
                    },
                }));
            }
        }
    };

    const handleChangeAgencia = (index: number, agencia: string) => {
        if (Number.isNaN(Number(agencia))) return;
        if ((dadosPagamento.infoAdicional as IInfoAdicional)) {
            const updatedDadosCheque = [...(dadosPagamento.infoAdicional as IInfoAdicional).dadosCheque!];

            if (index >= 0 && index < updatedDadosCheque.length) {
                updatedDadosCheque[index] = { ...updatedDadosCheque[index], agencia };

                setDadosPagamento(prevDadosPagamento => ({
                    ...prevDadosPagamento,
                    infoAdicional: {
                        ...prevDadosPagamento.infoAdicional!,
                        dadosCheque: updatedDadosCheque,
                    },
                }));
            }
        }
    };

    const handleChangeNumeroCheque = (index: number, numeroCheque: string) => {
        if (Number.isNaN(Number(numeroCheque))) return;
        if ((dadosPagamento.infoAdicional as IInfoAdicional)) {
            const updatedDadosCheque = [...(dadosPagamento.infoAdicional as IInfoAdicional).dadosCheque!];

            if (index >= 0 && index < updatedDadosCheque.length) {
                updatedDadosCheque[index] = { ...updatedDadosCheque[index], numeroCheque };

                setDadosPagamento(prevDadosPagamento => ({
                    ...prevDadosPagamento,
                    infoAdicional: {
                        ...prevDadosPagamento.infoAdicional!,
                        dadosCheque: updatedDadosCheque,
                    },
                }));
            }
        }
    };

    const handleDatavencimento = (index: number, vencimentoDisplay: string) => {
        if ((dadosPagamento.infoAdicional as IInfoAdicional)) {
            const novosDadosCheque = [...(dadosPagamento.infoAdicional as IInfoAdicional).dadosCheque!];
            if (index === 0) {
                novosDadosCheque[0] = { ...novosDadosCheque[0], vencimentoDisplay: vencimentoDisplay };
                let primeiraData = calcularNovaData(dayjs(vencimentoDisplay));

                for (let i = 1; i < novosDadosCheque.length; i++) {
                    novosDadosCheque[i] = { ...novosDadosCheque[i], vencimentoDisplay: primeiraData.toString() };
                    primeiraData = calcularNovaData(primeiraData);
                }
            } else {
                novosDadosCheque[index] = { ...novosDadosCheque[index], vencimentoDisplay: vencimentoDisplay };
            }

            for (let i = 0; i < novosDadosCheque.length; i++) {
                if (vencimentoDisplay.length === 58 || vencimentoDisplay.length === 29) {
                    novosDadosCheque[i] = { ...novosDadosCheque[i], vencimento: new Date(novosDadosCheque[i].vencimentoDisplay).toISOString() };
                }
            }

            setDadosPagamento((prevDadosPagamento) => ({
                ...prevDadosPagamento,
                infoAdicional: {
                    ...prevDadosPagamento.infoAdicional!,
                    dadosCheque: novosDadosCheque,
                },
            }));
        }
    };

    const calcularNovaData = (dataAntiga: dayjs.Dayjs | Date) => {
        return dayjs(dataAntiga).add(1, 'month').toDate();
    };

    const handleChangeEmitente = (index: number, emitente: string) => {
        if ((dadosPagamento.infoAdicional as IInfoAdicional)) {
            const updatedDadosCheque = [...(dadosPagamento.infoAdicional as IInfoAdicional).dadosCheque!];

            if (index >= 0 && index < updatedDadosCheque.length) {
                updatedDadosCheque[index] = { ...updatedDadosCheque[index], emitente };
                setDadosPagamento(prevDadosPagamento => ({
                    ...prevDadosPagamento,
                    infoAdicional: {
                        ...prevDadosPagamento.infoAdicional!,
                        dadosCheque: updatedDadosCheque,
                    },
                }));
            }
        }
    };

    const hasRowError = useCallback((row: IDadosCheque) => {
        if (row.banco?.descritivo && row.agencia && row.numeroCheque && row.numeroCheque.length === 6 && row.emitente) return false;
        if (row.banco?.descritivo || row.agencia || row.numeroCheque || row.emitente) return true;
        return false;
    }, []);

    const handleInfoAdicionalObservacao = (observacao: string) => {
        if ((dadosPagamento.infoAdicional as IInfoAdicional)) {
            setDadosPagamento(prevDadosPagamento => ({
                ...prevDadosPagamento,
                infoAdicional: {
                    ...prevDadosPagamento.infoAdicional!,
                    observacao
                },
            }));
        }
    };

    return (
        <>
            <FormControl
                sx={{ width: '5rem' }}
            >
                <TextField
                    variant="standard"
                    inputProps={{
                        style: {
                            textAlign: 'center',
                            fontSize: '24px',
                            fontWeight: 700,
                            fontFamily: 'Work Sans, sans-serif'
                        },
                    }}
                    id={'parcelas'}
                    name='parcelas'
                    label="Parcelas"
                    type='number'
                    inputMode='numeric'
                    value={(dadosPagamento.infoAdicional as IInfoAdicional).numeroParcelas ?? 1}
                    onChange={e => { handleChangeParcela(parseInt(e.target.value)); }}
                />
            </FormControl>

            <Grid container >
                {(dadosPagamento.infoAdicional as IInfoAdicional).numeroParcelas > 0 &&
                    <TableContainer
                        sx={{
                            backgroundColor: "#FFFFFF",
                            borderRadius: 1,
                            position: 'relative',
                            boxSizing: 'border-box',
                            height: '100%',
                            maxHeight: '320px',
                            minHeight: '320px',
                        }}
                        className="color-orange"
                    >
                        <Table
                            id="content-table"
                            stickyHeader
                            sx={{
                                borderRadius: 1,
                            }}
                            size="small"
                        >

                            <TableHead>
                                <TableRow >
                                    <TableCell sx={{ width: '3rem' }} align="center">
                                        Valor
                                    </TableCell>

                                    <TableCell sx={{ width: '3rem' }} align="center">
                                        Banco
                                    </TableCell>

                                    <TableCell sx={{ width: '1rem' }} align="center">
                                        Agencia
                                    </TableCell>

                                    <TableCell sx={{ width: '3rem' }} align="center">
                                        Nº do cheque
                                    </TableCell>

                                    <TableCell sx={{ width: '10rem' }} align="center">
                                        Emitente
                                    </TableCell>

                                    <TableCell sx={{ width: '3rem' }} align="center">
                                        Vencimento
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {(dadosPagamento.infoAdicional as IInfoAdicional).dadosCheque?.map((row, index) => (
                                    <LinhaDadosCheque
                                        bancos={bancos}
                                        handleBlurFirstRow={handleBlurFirstRow}
                                        handleChangeAgencia={handleChangeAgencia}
                                        handleChangeBanco={handleChangeBanco}
                                        handleChangeEmitente={handleChangeEmitente}
                                        handleChangeNumeroCheque={handleChangeNumeroCheque}
                                        handleChangeValor={handleChangeValor}
                                        handleDatavencimento={handleDatavencimento}
                                        hasError={hasError}
                                        hasRowError={hasRowError}
                                        index={index}
                                        row={row}
                                        key={`linha-dado-cheque-${index}`}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
                <Container>
                    {hasError && <FormHelperText sx={{ textAlign: 'center', fontWeight: 600, fontSize: 15, color: '#c62828' }}>O valor não pode ser maior que a venda :(</FormHelperText>}
                    {!hasError && <FormHelperText sx={{ textAlign: 'center', fontWeight: 600, fontSize: 15 }}> </FormHelperText>}
                </Container>
            </Grid>

            <TextField
                label="Observação"
                multiline
                fullWidth
                value={dadosPagamento.infoAdicional?.observacao ?? ""}
                id="cheque-dadospagamento-infoadicional-observacao"
                onChange={(e) => handleInfoAdicionalObservacao(e.target.value)}
                inputProps={{ maxLength: 200 }}
                color='warning'
                focused
            />
            <FaltaReceberComponent
                valorRecebido={dadosPagamento.valorRecebido}
                tableRowsValue={tableRowsValue}
                isAbsolute
            />
        </>
    );
};

export default ChequeDialogContent;
