import { DialogContent, DialogTitle, Tooltip, Typography } from "@mui/material";
import CustomDialog from "components/custom/CustomDialog";
import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { currencyOf, roundHalfToEven } from "utils/util";
import { FormaPagamentoCard } from "./formapagamento/FormaPagamentoCard";
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { globalStyles } from "styles/global";
import { setTrocoPago } from "redux/features/pagamentoSlice";
import { TipoTrocoPagar } from "pages/registra-venda/registra-venda.model";

export type ModalTrocoProps = {
    onShowModalTroco: () => void;
}

const ModalTroco = (props: ModalTrocoProps) => {
    const { tableRows, troco } = useAppSelector(e => e.pagamento)
    const { cliente } = useAppSelector(e => e.state.pedido)
    const dispatch = useAppDispatch()

    const valorTrocoAguardandoPagamento = useMemo(() => {
        return roundHalfToEven((troco?.valor ?? 0), 2)
        //eslint-disable-next-line
    }, [tableRows])

    const onPagaTroco = useCallback((formaPagamento: TipoTrocoPagar) => {
        dispatch(setTrocoPago(formaPagamento))
        props.onShowModalTroco()
        //eslint-disable-next-line
    }, [])

    const isConsumidorSelected = useCallback(() => {
        return !cliente
        //eslint-disable-next-line
    }, [])

    return (
        <CustomDialog
            id="cadastro-pessoa-dialog"
            open={true}
            fullWidth={true}
            maxWidth={'md'}
            onClose={props.onShowModalTroco}
        >
            <DialogTitle sx={{
                position: 'absolute',
                left: 0,
                top: 0
            }}>
                <Typography
                    textAlign="center"
                    fontSize={26}
                    sx={{
                        fontWeight: 'bold',
                        userSelect: "none"
                    }}>
                    Atenção
                </Typography>
            </DialogTitle>

            <DialogContent sx={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                marginTop: '30px'
            }}>
                <Typography
                    textAlign="center"
                    fontSize={20}
                    sx={{
                        userSelect: "none"
                    }}
                >
                    O valor pago com o cartão é superior ao valor dos itens. Gerar o troco ou conceder crédito ao cliente?
                </Typography>
            </DialogContent>

            <Typography
                textAlign="center"
                fontSize={30}
                sx={{
                    color: globalStyles.LARANJA,
                    fontWeight: 'bold',
                    marginTop: '10px',
                    marginBottom: '25px',
                    userSelect: "none"
                }}>
                Troco: {currencyOf(valorTrocoAguardandoPagamento)}
            </Typography>

            <div style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                marginBottom: '10px'
            }}>
                <FormaPagamentoCard
                    height="40px"
                    id="DINHEIRO"
                    icon={{ name: 'fa-money-bill-1-wave' }}
                    label={"Dinheiro"}
                    onClick={() => onPagaTroco(TipoTrocoPagar.DINHEIRO)}
                />
                <Tooltip
                    disableHoverListener={!isConsumidorSelected()}
                    title="Informe um cliente na tela anterior para usar esta forma de troco"
                >
                    <div>
                        <FormaPagamentoCard
                            height="40px"
                            id="NOTA"
                            icon={{ component: <CardGiftcardIcon /> }}
                            label="Gerar crédito cliente"
                            onClick={() => onPagaTroco(TipoTrocoPagar.NOTA_DE_CREDITO)}
                            disabled={isConsumidorSelected()}
                        />
                    </div>
                </Tooltip>
            </div>
        </CustomDialog>
    )
}

export default ModalTroco;