import { Box, Container, FormControl, Grid, Icon, InputLabel, ListItemIcon, ListItemText, Menu, MenuItem, Select } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef, GridRowId, GridRowModesModel, ptBR } from "@mui/x-data-grid";
import { useCallback, useEffect, useRef, useState } from "react";
import CaixaAutoComplete from "./CaixaAutoComplete";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import CaixaService from "pages/Caixa/caixa.service";
import { ICaixasDisponivel, TRelatorioFechamentoCaixa, TResgateRelatorioTO, TResgateTipo } from "pages/Caixa/caixa.model";
import RealtorioCaixa from "pages/Caixa/relatorio-caixa/RealtorioCaixa";
import { RelatorioCaixa, StateRelatorioCaixa, initialSateRelatorioCaixa } from "pages/Caixa/relatorio-caixa/relatorio-caixa.model";
import { useSnackbarCustom } from "hooks/useSnackCustom";
import { CustomButton } from "components/roundedbutton/CustomButton";
import { useNavigate } from "react-router-dom";

export type TRelatorioInitialState = {
    rows: Array<TRelatorioFechamentoCaixa>;
    caixas: Array<string>;
    caixasOptions: Array<ICaixasDisponivel>;
    dataInicial: Date;
    dataFechamento: Date;
    tipo: TResgateTipo;
};

const initialState: TRelatorioInitialState = {
    rows: [],
    caixas: [],
    caixasOptions: [],
    dataInicial: new Date(),
    dataFechamento: new Date(),
    tipo: TResgateTipo.ABERTURA
};

const tipos: Array<TResgateTipo> = [
    TResgateTipo.ABERTURA,
    TResgateTipo.FECHAMENTO
];

const RelatorioFechamento = () => {
    const [stateLocal, setStateLocal] = useState(initialState);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const caixaService = CaixaService();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [relatorioCaixaState, setRelatorioCaixaState] = useState<StateRelatorioCaixa>(initialSateRelatorioCaixa);
    const { addError } = useSnackbarCustom();

    const navigate = useNavigate();

    useEffect(() => {
        caixaService.getCaixas()
            .then(({ data }: { data: Array<ICaixasDisponivel>; }) => {
                setStateLocal(prevState => ({ ...prevState, caixasOptions: data }));
            })
            .catch(() => { });
        //eslint-disable-next-line
    }, []);

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleSetCaixa = useCallback((caixas: Array<string>) => {
        setStateLocal(prevState => ({ ...prevState, caixas }));
    }, []);

    const addAll = () => {
        setStateLocal(prevState => ({ ...prevState, caixas: prevState.caixasOptions.map(e => e.descritivo) }));
    };

    const addUsuario = (caixas: Array<string>) => {
        setStateLocal(prevState => ({ ...prevState, caixas }));
    };

    const removeAll = () => {
        setStateLocal(prevState => ({ ...prevState, caixas: initialState.caixas }));
    };

    const handleDataChange = (state: string, value: string) => {
        setStateLocal(prevState => ({ ...prevState, [state]: value }));
    };

    const onChangeTextField = useCallback((state: string, e: any) => {
        if (!e) return;

        if (e.$D > 0 && e.$y > 0 && e.$d > 0) {
            handleDataChange(state, e.$d);
        }
        // eslint-disable-next-line
    }, []);

    const rowRef = useRef<TRelatorioFechamentoCaixa | null>(null);

    const handleOption = useCallback((id: GridRowId, row: TRelatorioFechamentoCaixa, event: React.MouseEvent<HTMLButtonElement>) => {
        rowRef.current = row;
        setAnchorEl(event.currentTarget);
    }, []);

    const handleImprimir = () => {
        setRelatorioCaixaState(prev => ({ ...prev, open: true, loading: true }));
        caixaService.getResgataFechamento(rowRef.current!)
            .then(({ data }: { data: RelatorioCaixa; }) => {
                setRelatorioCaixaState(prev => ({ ...prev, info: data, loading: false }));
            })
            .catch(err => {
                setRelatorioCaixaState(prev => ({ ...prev, open: false }));
                addError({ message: 'Não foi possível gerar o relatório', closeable: true, hideDuration: 7000 });
            })
            .finally(() => {
                rowRef.current = null;
                setAnchorEl(null);
            });
    };


    useEffect(() => {
        if (stateLocal.caixas.length) {
            const data: TResgateRelatorioTO = {
                caixa: stateLocal.caixas,
                dataInicial: stateLocal.dataInicial.toISOString(),
                dataFechamento: stateLocal.dataFechamento.toISOString(),
                tipo: stateLocal.tipo
            };

            caixaService.getFechamentosCaixa(data)
                .then(({ data }: { data: Array<TRelatorioFechamentoCaixa>; }) => {
                    const rows: Array<TRelatorioFechamentoCaixa> = [];
                    data.forEach((e, index) => {
                        rows.push({
                            caixa: e.caixa,
                            dataAbertura: e.dataAbertura,
                            dataFechamento: e.dataFechamento,
                            status: e.status,
                            usuario: e.usuario,
                            uuidAbertura: e.uuidAbertura,
                            uuidFechamento: e.uuidFechamento ?? null,
                            id: index
                        });
                    });
                    setStateLocal(prevState => ({ ...prevState, rows }));
                })
                .catch(() => { });
        } else {
            setStateLocal(prevState => ({ ...prevState, rows: [] }));
        }
        // eslint-disable-next-line
    }, [stateLocal.caixas.length, stateLocal.dataInicial, stateLocal.dataFechamento, stateLocal.tipo]);

    const columns: GridColDef[] = [
        {
            field: 'caixa',
            headerName: 'Caixa',
            type: 'string',
            width: 400,
            align: 'left',
            headerAlign: 'left',
            editable: false,
        },
        {
            field: 'usuario',
            headerName: 'Usuário',
            type: 'string',
            width: 246.5,
            align: 'center',
            headerAlign: 'center',
            editable: false,
        },
        {
            field: 'dataAbertura',
            headerName: 'Data abertura',
            type: 'string',
            width: 246.5,
            align: 'center',
            headerAlign: 'center',
            editable: false,
        },
        {
            field: 'dataFechamento',
            headerName: 'Data fechamento',
            type: 'string',
            width: 246.5,
            align: 'center',
            headerAlign: 'center',
            editable: false,
        },
        {
            field: 'status',
            headerName: 'Status',
            type: 'string',
            width: 246.5,
            align: 'center',
            headerAlign: 'center',
            editable: false,
        },
        {
            field: 'acoes',
            type: 'actions',
            headerName: 'Ações',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id, row }) => {
                if (!row.dataFechamento) {
                    return [];
                }
                return [
                    <GridActionsCellItem
                        icon={<Icon className="fa-solid fa-ellipsis-vertical" />}
                        label="Editar"
                        className="textPrimary"
                        onClick={(e: any) => handleOption(id, row, e)}
                        color="inherit"
                    />,
                ];
            },
        }
    ];

    return (
        <Container maxWidth="xl" sx={{ marginTop: '20px' }} >
            <Box>
                <CaixaAutoComplete
                    onSetCaixas={handleSetCaixa}
                    caixas={stateLocal.caixas}
                    caixasOptions={stateLocal.caixasOptions}
                    key={"caixasAutoComplete"}
                    onAddAll={addAll}
                    onAddUsuario={addUsuario}
                    onRemoveAll={removeAll}
                />
                <FormControl sx={{
                    width: "25%",
                    marginLeft: "1.5%"
                }} variant="standard" error={false}>
                    <InputLabel id="tipo-pessoa-label" htmlFor="tipo-pessoa-input">Tipo da consulta</InputLabel>
                    <Select
                        id="tipo-pessoa-component"
                        inputProps={{ id: 'tipo-pessoa-input' }}
                        value={stateLocal.tipo}
                        onChange={(e) => setStateLocal(prevState => ({ ...prevState, tipo: e.target.value as TResgateTipo }))}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 48 * 4.5 + 8,
                                    width: 100
                                },
                            },
                        }}
                    >
                        {tipos.map(tp => (
                            <MenuItem
                                key={tp}
                                value={tp}
                            >
                                Data de {(tp.toLocaleLowerCase() as TResgateTipo)}
                            </MenuItem>
                        ))
                        }
                    </Select>
                </FormControl>
                <DatePicker
                    sx={{ width: "13%", marginLeft: '26px' }}
                    slotProps={{
                        textField: {
                            id: `vencimento_${2}`,
                            variant: 'standard',
                            onChange: e => onChangeTextField("dataInicial", e)
                        }
                    }}
                    label="Data Inicial"
                    format='DD/MM/YYYY'
                    maxDate={dayjs(new Date())}
                    onChange={(e: any) => setStateLocal(prevState => ({ ...prevState, dataInicial: e.$d ?? dayjs(new Date().toString()) }))}
                    value={dayjs(stateLocal.dataInicial.toString())}
                />

                <DatePicker
                    sx={{ width: "13%", marginLeft: '26px' }}
                    slotProps={{
                        textField: {
                            id: `vencimento_${2}`,
                            variant: 'standard',
                            onChange: e => onChangeTextField("dataFechamento", e)
                        }
                    }}
                    label="Data fechamento"
                    format='DD/MM/YYYY'
                    maxDate={dayjs(new Date())}
                    onChange={(e: any) => setStateLocal(prevState => ({ ...prevState, dataFechamento: e.$d ?? dayjs(new Date().toString()) }))}
                    value={dayjs(stateLocal.dataFechamento.toString())}
                />
            </Box>
            <Box
                sx={{
                    marginTop: '10px',
                    height: 520,
                    width: '100%',
                    '& .actions': {
                        color: 'text.secondary',
                    },
                    '& .textPrimary': {
                        color: 'text.primary',
                    },
                }}
            >
                <DataGrid
                    rows={stateLocal.rows}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    slotProps={{
                        toolbar: { setStateLocal, setRowModesModel },
                    }}
                    localeText={{
                        ...ptBR.components.MuiDataGrid.defaultProps.localeText,
                        noRowsLabel: "Nenhum registro encontrado"
                    }}
                />
                {anchorEl &&
                    <Menu key="vendas_table_menu"
                        anchorEl={anchorEl}
                        open={true}
                        onClose={setAnchorEl.bind(null, null)}
                    >
                        <MenuItem key="menu-item-aberto-cancelar-pedido" onClick={handleImprimir}>
                            <ListItemIcon>
                                <Icon className="fa-solid fa-print" sx={{ fontSize: "16px" }} />
                            </ListItemIcon>
                            <ListItemText>Imprimir</ListItemText>
                        </MenuItem>
                    </Menu>
                }
                <RealtorioCaixa
                    state={relatorioCaixaState}
                    setState={setRelatorioCaixaState}
                />
                <Grid item
                    sx={{
                        position: 'absolute',
                        bottom: '25px',
                        width: '80%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                    }}
                >
                    <CustomButton
                        sx={{ minWidth: '80px' }}
                        id="voltar-configuracao-vale-presente-btn"
                        onClick={() => navigate(-1)}
                        variant="contained"
                        color="error"
                        startIcon={<Icon className='fa-solid fa-arrow-left' />}>
                        Voltar
                    </CustomButton>

                </Grid>
            </Box>
        </Container >
    );
};

export default RelatorioFechamento;
