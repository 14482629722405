import { Card } from "@mui/material"
import { memo } from "react"
import { useAppSelector } from "redux/hooks"
import { currencyOf } from "utils/util"

export type FaltaReceberComponentProps = {
    valorRecebido: number;
    tableRowsValue: number;
    isAbsolute?: boolean;
    marginTop?: string
}

const FaltaReceberComponent = (props: FaltaReceberComponentProps) => {
    const { faltaReceber } = useAppSelector(e => e.pagamento)

    return (

        <Card sx={{
            borderRadius: 5,
            textAlign: 'center',
            height: '100px',
            aspectRatio: '7/3',
            padding: '0 !important',
            position: props.isAbsolute ? 'absolute' : undefined,
            bottom: '20px',
            marginTop: props.marginTop ?? undefined
        }}
            className="card-totalizador-pedido"
        >
            <h2
                id="generic_forma_pagamento_value"
                className="valor-totalizador">
                {props.valorRecebido <= faltaReceber
                    ? currencyOf(Math.abs(faltaReceber - props.valorRecebido - props.tableRowsValue))
                    : currencyOf(Math.abs(faltaReceber - props.tableRowsValue))
                }
            </h2>
            <h4
                id="generic_forma_pagamento_titulo"
                className="label-totalizador">
                Falta receber
            </h4>
        </Card>
    )
}

export default memo(FaltaReceberComponent)