import { Backdrop, CircularProgress, Typography } from "@mui/material";
import LinearFinalizaVenda from "pages/pagamento/LinearFinalizaVenda";
import { Tipo } from "redux/features/blockuiSlice";
import { useAppSelector } from "redux/hooks";

const BlockUI = () => {
    const { loading, tipo, progress, message, zIndexCustom } = useAppSelector(s => s.blockui);
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + (zIndexCustom ?? 1) }}
            open={loading}
        >
            {tipo === Tipo.CIRCULAR &&
                <div style={{
                    flexDirection: 'column',
                    display: 'flex'
                }}>
                    <CircularProgress sx={{ color: 'var(--laranja)', alignSelf: 'center' }} />
                    <Typography
                        className="LinearFinalizaVenda"
                        sx={{
                            color: '#FFF',
                            textAlign: 'center',
                            paddingTop: '20px',
                            fontSize: '20px'
                        }}>
                        {message}
                        <span id="dotdotdot"></span>
                    </Typography>
                </div>
            }
            {tipo === Tipo.LINEAR &&
                <LinearFinalizaVenda progress={progress!} message={message!} />
            }
        </Backdrop>
    );
};

export default BlockUI;
