import { Box, DialogActions, DialogContent, DialogTitle, Icon, Typography } from '@mui/material';
import CustomDialog from 'components/custom/CustomDialog';
import { CustomButton } from 'components/roundedbutton/CustomButton';
import { BaseValePresenteTO, VisualizacaoContaPagarValePresenteTO, labelsDialogVisualizarProps } from 'pages/vale-presente/vale-presente.model';
import ValePresenteService from 'pages/vale-presente/vale-presente.service';
import { useEffect, useState } from 'react';
import { currencyOf, toLocaleDateString } from 'utils/util';

type VisualizarDialogProps = {
    open: boolean;
    onClose: () => void;
    row: BaseValePresenteTO;
};

const VisualizarDialog = (props: VisualizarDialogProps) => {

    const [dialogVisualizar, setDialogVisualizar] = useState<{ data: null | VisualizacaoContaPagarValePresenteTO; }>({ data: null });
    const service = ValePresenteService();

    const getDados = () => {
        service.visualizar(props.row.uuid)
            .then(({ data }) => {
                setDialogVisualizar(prev => ({
                    ...prev,
                    data,
                }));
            })
            .catch(() => { });
    };

    useEffect(() => {
        if (props.open) {
            getDados();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);


    return (
        <CustomDialog
            open={props.open}
            onClose={props.onClose}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>
                <Typography textAlign="center" fontSize={28}>Vale presente</Typography>
            </DialogTitle>

            <DialogContent sx={{ paddingX: 0, display: 'flex', flexDirection: 'column', gap: '16px' }}>
                {Object.keys(dialogVisualizar.data ?? {})
                    .filter(prop => prop !== 'uuid')
                    .map((prop, idx) => {

                        const itemProps = labelsDialogVisualizarProps[prop.toUpperCase()];
                        let value = dialogVisualizar.data?.[prop as keyof VisualizacaoContaPagarValePresenteTO];

                        if (itemProps.type === 'currency') {
                            value = currencyOf(value as number);
                        }

                        if (itemProps.type === 'date') {
                            value = toLocaleDateString(value as string);
                        }

                        return <Box key={idx} sx={{ display: 'flex' }}>
                            <Typography fontWeight={'bold'} component='h5' flex={.7}>{itemProps.label}</Typography>
                            <Typography flex={1}>{value?.toString().toUpperCase()}</Typography>
                        </Box>;
                    })}
            </DialogContent>

            <DialogActions sx={{ paddingX: '0' }}>
                <CustomButton
                    onClick={props.onClose}
                    variant="contained"
                    color="error"
                    startIcon={
                        <Icon className={`fa-solid fa-xmark`} />}
                >
                    Voltar
                </CustomButton>
            </DialogActions>
        </CustomDialog>
    );
};

export default VisualizarDialog;
