import { IAbstractModel } from "model/abstract.model";
import { IConsumidor } from "model/cliente.model";
import { ICpfNota } from "pages/cpfNota/CpfNota";
import { TDadosTransportador } from "pages/DadosTransportador";
import { IPagamentoRow } from "pages/pagamento/pagamento.model";

export interface FlowVendaTO {
    uuid: string,
    valorTotalBruto: number,
    valorTotalLiquido: number,
    valorTotalLiquidoTroca: number,
    quantidadeTotal: number,
    quantidadeTotalTroca: number;
    emissao: Date | string,
    entrega: Date | string,
    numero: string,
    numeroEntrega: number,
    statusPedido: StatusPedido,
    cliente: IAbstractModel | IConsumidor | null,
    vendedores: IAbstractModel[],
    formaComissao: IAbstractModel | null,
    finalidadePedido: IAbstractModel | null;
    parcelas: Array<IAbstractModel>,
    condicoesPagamento: Array<IAbstractModel>,
    itensPagamento: Array<IPagamentoRow>;
    itensPagamentoDevolucao: Array<IPagamentoRow>;
    itens: Array<IProdutoRow>;
    itensTroca: Array<IProdutoRow>;
    cpfNota: ICpfNota | null;
    pedidoDesconto: PedidoItemDesconto;

    // novosParams
    estabelecimento: {
        nomeFantasia: string;
        telefone: string;
        endereco: string;
        inscricaoEstadual: string;
        complemento: string;
        municipio: string;
        email: string;
        cep: string;
    };
    flowItens: {
        produtos: Array<TFlowProdutoImpressaoTO>;
        condicaoPagamentos: Array<string>;
    };
    transportador: TDadosTransportador;
    parcelasCrediario: Array<DadosPagamentoParcelaTO>
    observacao: string;
    isVisualizar: boolean
    // Especificos Flow
    consultados: string[];
    tabelaPreco: IAbstractModel | null;
    deposito: IAbstractModel | null;
    troco: FlowTrocoTO | null
}

export type DadosPagamentoParcelaTO = {
    formaPagamento: IAbstractModel,
    numeroParcela: number;
    numeroDias: number;
    vencimento: Date;
    valor: number
}

export type TFlowTamanhoImpressaoTO = {
    tamanho: string,
    quantidade: number;
};

export type TFlowCorImpressaoTO = {
    cor: string,
    valorUnitario: string,
    desconto: string,
    valorTotal: number,
    quantidade: number,
    tamanhos: Array<TFlowTamanhoImpressaoTO>;
};

export type TFlowProdutoImpressaoTO = {
    nome: string,
    cores: Array<TFlowCorImpressaoTO>;
    tamanhos: Array<TFlowTamanhoImpressaoTO>
};

export type PedidoItemDesconto = {
    uuidAutorizador: string | null;
    valorDesconto: number;
    percentualDesconto: number;
    tipoDesconto: TipoDesconto;
};

export interface IProdutoRow {
    produto: IProduto;
    estoque: number,
    quantidade: number;
    valorTotal: number;
    itemDesconto: PedidoItemDesconto;
    isBrinde?: boolean;
    isTroca: boolean;
    uuid: string
}

export interface IProduto {
    uuid: string,
    codigoSku: string;
    nome: string;
    cor: string;
    tamanho: string;
    preco: number,
    tipo: TipoItem;
}

export enum TipoDesconto {
    PERCENTUAL = 'PERCENTUAL',
    VALOR = 'VALOR'
}

export enum StatusPedido {
    ABERTO = 'ABERTO',
    FATURADO = 'FATURADO',
    CANCELADO = 'CANCELADO',
}

export enum TipoItem {
    PRINCIPAL = 'PRINCIPAL',
    LOJA = 'LOJA',
    OUTROS = 'OUTROS',
    SKU = 'SKU'
}

export interface IPedidoFooter {
    quantidade: number,
    valorTotal: number,
    desconto: number,
    tipoDesconto: TipoDesconto,
    valorTotalLiquido: number;
}

export type TPagamentoReceivedProps = {
    receivedParams: boolean
    isVisualizar: boolean
};

export type EstoqueTO = {
    sku: IAbstractModel;
    deposito: IAbstractModel;
    saldo: string;
    reserva: string;
};

export const headerWidths = {
    produtoVenda: "580px",
    estoque: "125px",
    quantidade: "100px",
    valorUnitario: "150px",
    desconto: "150px",
    total: "175px",
    brinde: "50px",
    lixeira: "50px"
};

export type FlowCreatePedidoCieloTOResponse = {
    uuidOrigem: string;
    reference: string;
    credenciadoraUUID: string;
}

export enum TipoTrocoPagar {
    DINHEIRO = "DINHEIRO",
    NOTA_DE_CREDITO = "NOTA_DE_CREDITO"
}

export type FlowTrocoTO = {
    valor: number,
    tipoTrocoPagar: TipoTrocoPagar
}

export type FlowConsultaCartaoPagoTO = {
    itensPagamentoTOS: Array<IPagamentoRow>,
    troco: FlowTrocoTO
}