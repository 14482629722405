import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Autocomplete, Box, Button, Checkbox, Chip, DialogActions, DialogContent, DialogTitle, FilledInputProps, FormControl, FormControlLabel, FormHelperText, Grid, Icon, InputLabel, InputProps, ListItemText, MenuItem, OutlinedInputProps, Select, Switch, Tab, TextField, Tooltip, Typography, styled } from '@mui/material';
import CustomDialog from 'components/custom/CustomDialog';
import { CustomButton } from 'components/roundedbutton/CustomButton';
import { useFormValidator } from 'hooks/useFormValidator';
import { useGenerateUuid } from 'hooks/useGenerateUuid';
import { useSnackbarCustom } from 'hooks/useSnackCustom';
import { usePessoaValidator } from 'hooks/validators/usePessoaValidator';
import { IAbstractEnum, IAbstractModel } from 'model/abstract.model';
import React, { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { Tipo, completeLoading, loadingWithOpt } from 'redux/features/blockuiSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { deepCopy, isValidEmail } from 'utils/util';
import { CadastroPessoaService } from './cadastro-pessoa.service';
import { Contato, Endereco, Pessoa, PessoaKey, TImpostosDeduzidos } from './pessoa.model';
import { setCliente } from 'redux/features/stateSlice';
import RegistraVendaService from 'pages/registra-venda/registra-venda.service';


type Props = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const initialStateContato = {
    inputValue: '',
    inputChipsValue: [],
    enviarNotaFiscal: true,
    contato: {
        uuid: '',
        tipo: 'PRINCIPAL',
        nome: 'PRINCIPAL',
        telefones: [
            {
                uuid: '',
                tipo: 'COMERCIAL',
                telefone: '',
            },
            {
                uuid: '',
                tipo: 'CELULAR',
                telefone: '',
            },

            {
                uuid: '',
                tipo: 'RESIDENCIAL',
                telefone: '',
            },
        ],
        enderecoEletronicos: []
    }
};

const tabHeaderStyle = {
    '&.Mui-selected': {
        color: 'var(--laranja)'
    }
};

const tabContentStyle = {
    minHeight: 'auto',
    padding: '24px 0 0 0',
};

const CadastroPessoa = (props: Props) => {
    const service = CadastroPessoaService();
    const [randomUuid] = useGenerateUuid();
    const dispatch = useAppDispatch();
    const { addError, addSuccess } = useSnackbarCustom();
    const { baseValidators, cnpfCnpj, contribuinteInscricaoEstadualValidate } = usePessoaValidator();
    const { required, minSize } = baseValidators;
    const userName = useAppSelector(s => s.token.USER_INFO?.username);

    const [tipoPessoaAC, setTipoPessoaAC] = useState<Array<IAbstractEnum>>([]);
    const [papeisAc, setPapeisAC] = useState<Array<IAbstractEnum>>([]);
    const [tipoContribuinteAC, setTipoContribuinteAC] = useState<Array<IAbstractEnum>>([]);
    const [municipioAC, setMunicipioAC] = useState<Array<IAbstractModel>>([]);
    const registraVendaService = RegistraVendaService();

    const [localState, setLocalState] = useState({ selectTab: 'endereco', submitted: false, existIdentification: false, invalidCpfCnpj: false });
    const [contatoState, setContatoState] = useState<{ inputValue: string, inputChipsValue: string[]; enviarNotaFiscal: boolean, contato: Contato; }>(initialStateContato);
    const impostosAc = useRef<Array<TImpostosDeduzidos>>([
        TImpostosDeduzidos.PIS,
        TImpostosDeduzidos.ICMS,
        TImpostosDeduzidos.COFINS
    ]);

    const {
        form,
        getControl,
        hasError,
        setFormValue,
        setControlValue,
        getRawValue,
        resetForm,
        formIsValid,
        resetControl,
        isDisabledControl,
        disableControl,
        enableControl,
    } = useFormValidator<Pessoa>({
        controls: [
            { inputName: 'uuid', value: '', },
            { inputName: 'tipoPessoa', value: 'FISICA', },
            { inputName: 'status', value: 'ATIVO', },
            { inputName: 'dataCadastro', value: null, },
            { inputName: 'nomeRazaoSocial', value: '', validators: [required], },
            { inputName: 'nomeFantasia', value: null, },
            { inputName: 'nascimento', value: null, },
            { inputName: 'identificacao', value: '', validators: [required], },
            { inputName: 'tipoContribuinte', value: 'NAO_CONTRIBUINTE', },
            { inputName: 'inscricaoEstadual', value: null, },
            { inputName: 'inscricaoMunicipal', value: null },
            { inputName: 'inscricaoSuframa', value: null, },
            { inputName: 'papeis', value: ["CLIENTE"], validators: [required], },
            { inputName: 'consumidorFinal', value: true, disabled: true },
            { inputName: 'rg', value: null },

            { inputName: 'enderecos', value: [], },
            { inputName: 'contatos', value: [], },
            { inputName: 'observacoes', value: [], },

            { inputName: 'formasComissao', value: [], },
            { inputName: 'formaComissaoPrincipal', value: null },

            { inputName: 'deduzIr', value: false, },
            { inputName: 'documentos', value: [], },
            { inputName: 'impostosBeneficioSuframa', value: [] },
            { inputName: 'representantes', value: [], },
            { inputName: 'outrosComissionados', value: [], },
        ],
        validators: [
            contribuinteInscricaoEstadualValidate,
            cnpfCnpj,
        ],
    });

    const {
        getRawValue: getRawEnderecoValue,
        setControlValue: setEnderecoValue,
        hasError: hasEnderecoError,
        resetForm: resetEnderecoForm,
        formIsValid: formEnderecoIsValid,
        setFormValue: setEnderecoFormValue,
    } = useFormValidator<Endereco>({
        controls: [
            { inputName: 'uuid', value: '', },
            { inputName: 'tipo', value: 'PRINCIPAL', },
            { inputName: 'codigoPostal', value: null, },
            { inputName: 'logradouro', value: '', validators: [required, minSize(3)], },
            { inputName: 'numero', value: '', validators: [required], },
            { inputName: 'complemento', value: null, },
            { inputName: 'bairro', value: '', validators: [required], },
            { inputName: 'municipio', value: { uuid: '', descritivo: '' }, validators: [required], },
        ],
        validators: [],
    });

    const {
        cliente,
    } = useAppSelector(s => s.state.pedido);

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const isEditingRef = useRef(false);

    const setConsumidorDados = useCallback((data: Pessoa) => {
        // Dados Pessoa
        setControlValue('tipoPessoa', data.tipoPessoa!);
        onChangeIdentificacao(data.identificacao!);
        setControlValue('nomeRazaoSocial', data.nomeRazaoSocial!.toUpperCase());
        setControlValue('nomeFantasia', data.nomeFantasia ? data.nomeFantasia.toUpperCase() : null);
        setControlValue('tipoContribuinte', data.tipoContribuinte ?? null);
        setControlValue('inscricaoEstadual', data.inscricaoEstadual ?? null);
        setControlValue('inscricaoMunicipal', data.inscricaoMunicipal ?? null);
        setControlValue('inscricaoSuframa', data.inscricaoSuframa ?? null);
        setControlValue('impostosBeneficioSuframa', data.impostosBeneficioSuframa ?? []);
        setControlValue('papeis', data.papeis.filter(s => s !== 'REPRESENTANTE'));
        setControlValue('consumidorFinal', data.consumidorFinal ?? false);
        setControlValue("uuid", data.uuid);
        setControlValue("nascimento", data.nascimento);
        setControlValue("rg", data.rg);

        setControlValue("formasComissao", data.formasComissao);
        setControlValue("formaComissaoPrincipal", data.formaComissaoPrincipal);

        // Endereços
        setEnderecoValue('codigoPostal', data.enderecos[0].codigoPostal ?? null);
        setEnderecoValue('logradouro', data.enderecos[0].logradouro ?? null);
        setEnderecoValue('numero', data.enderecos[0].numero ?? null);
        setEnderecoValue('complemento', data.enderecos[0].complemento ?? null);
        setEnderecoValue('bairro', data.enderecos[0].bairro ?? null);
        setEnderecoValue('municipio', data.enderecos[0].municipio ?? null);

        const contatos = data.contatos ?? [];
        const contato0: Contato | undefined = contatos[0];

        const newContato: Contato = {
            telefones: [
                {
                    tipo: "COMERCIAL",
                    telefone: contato0 ? findTelefoneByTipo('COMERCIAL', contato0) : '',
                    uuid: ""
                },
                {
                    tipo: "CELULAR",
                    telefone: contato0 ? findTelefoneByTipo('CELULAR', contato0) : '',
                    uuid: ""
                },
                {
                    tipo: "RESIDENCIAL",
                    telefone: contato0 ? findTelefoneByTipo('RESIDENCIAL', contato0) : '',
                    uuid: ""
                }

            ],
            enderecoEletronicos: contato0?.enderecoEletronicos ?? [],
            nome: contato0?.nome ?? "PRINCIPAL",
            tipo: contato0?.tipo ?? "PRINCIPAL",
            uuid: contato0?.uuid ?? "",
        };

        setContatoState(prevState => ({
            ...prevState,
            inputChipsValue: contato0?.enderecoEletronicos?.map(e => e.endereco) ?? [],
            contato: newContato,
            enviarNotaFiscal: contato0?.enderecoEletronicos?.[0]?.enviaNfe ?? false
        }));

        setControlValue('observacoes', data.observacoes ?? []);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const editing = !!cliente;
        isEditingRef.current! = editing;
        setIsEditing(prev => editing);

        if (editing) {
            service.getPessoa(cliente.uuid).then(({ data }) => {
                setConsumidorDados(data);
            }).catch(() => { });
        } else {
            resetAll();
        }
        //eslint-disable-next-line
    }, [cliente, props.open]);


    useEffect(() => {
        getACs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setLocalState(prev => ({ ...prev, invalidCpfCnpj: (form.errors as any)?.['cpfCnpjInvalid'] }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getControl()]);


    const getACs = () => {
        service.getTipoPessoaAC()
            .then(({ data }) => {
                data = data.filter(e => e.name !== "ESTRANGEIRO");
                setTipoPessoaAC(data);
            });

        service.getTipoContribuinte()
            .then(({ data }) => {
                setTipoContribuinteAC(data);
            });

        service.getPapelAC()
            .then(({ data }) => {
                setPapeisAC(data.filter(s => s.name !== 'REPRESENTANTE' && s.name !== "CREDENCIADORA"));
            });

        getMunicipiosAC();
    };

    const getMunicipiosAC = (toSearch: string = '') => {
        service.getMunicipios(toSearch)
            .then(({ data }) => {
                const municipios = data.values;
                municipios.unshift({ uuid: '', descritivo: '' });
                setMunicipioAC(municipios);
            });
    };

    const setNullableInPessoa = (key: PessoaKey, value: Pessoa[PessoaKey] | null | '', fnName?: string) => {
        let toSet = value === '' ? null : value;
        if (fnName && toSet) {
            toSet = (toSet as any)[fnName as string]();
        }
        setControlValue(key, toSet);
    };

    const setContatoInPessoa = (pessoa: Pessoa) => {
        const contatoPreenchido = contatoState.contato.telefones.some(s => s.telefone);
        const emailPreenchido = contatoState.contato.enderecoEletronicos.some(s => s.endereco);

        if (contatoPreenchido || emailPreenchido) {
            const contatoCopy: Contato = JSON.parse(JSON.stringify(contatoState.contato));
            contatoCopy.telefones = contatoCopy.telefones.filter(s => s.telefone);
            pessoa.contatos = [contatoCopy];
        } else {
            pessoa.contatos = [];
        }
    };

    const setEnderecoInPessoa = (pessoa: Pessoa) => {
        pessoa.enderecos = [getRawEnderecoValue()];
    };

    const resetAll = () => {
        resetForm();
        resetEnderecoForm();
        setContatoState(initialStateContato);
        setLocalState(prev => ({
            submitted: false,
            existIdentification: false,
            selectTab: 'endereco',
            invalidCpfCnpj: false,
        }));
    };

    const getValueByTipoPessoa = <T extends unknown>(juridicaValue: T, fisicaValue: T, identificacao: T): T => {
        if (isPessoaJuridica()) {
            return juridicaValue;
        }
        if (isPessoaEstrangeira()) {
            return identificacao;
        }
        return fisicaValue;
    };

    const isPessoaJuridica = () => {
        return getRawValue('tipoPessoa') === 'JURIDICA';
    };

    const isPessoaEstrangeira = () => {
        return getRawValue("tipoPessoa") === "ESTRANGEIRO";
    };

    const onConfirm = (e: FormEvent) => {
        e.preventDefault();
        setLocalState(prev => ({
            ...prev,
            submitted: true
        }));

        if (!formIsValid() || !formEnderecoIsValid() || localState.existIdentification || hasError('cpfCnpjInvalid')) {
            setLocalState(prev => ({ ...prev, selectTab: 'endereco' }));
            addError({ id: 'invalid-form-error', message: 'Preencha corretamente o formulário', preventDuplicate: false, closeable: true, persist: false });
            return;
        }

        const pessoaForm = getRawValue();
        const pessoa: Pessoa = deepCopy(pessoaForm);
        setEnderecoInPessoa(pessoa);
        setContatoInPessoa(pessoa);

        if (pessoa.formasComissao?.length && pessoa.formaComissaoPrincipal) {
            pessoa.papeis.push('REPRESENTANTE');
        }

        dispatch(loadingWithOpt({
            loading: true,
            tipo: Tipo.CIRCULAR,
            zIndexCustom: 1000
        }));
        service.save(pessoa)
            .then(s => {
                if (isEditingRef.current) {
                    addSuccess({ message: 'Pessoa alterada com sucesso', persist: false });
                } else {
                    addSuccess({ message: 'Pessoa cadastrada com sucesso', persist: false });
                }
                resetAll();
                registraVendaService.getConsumidoresAc(pessoa.nomeRazaoSocial)
                    .then(({ data }) => {
                        dispatch(setCliente({
                            descritivo: data[0].descritivo,
                            uuid: data[0].uuid
                        }));
                    })
                    .catch(err => { });
                props.setOpen(false);
            })

            .catch(err => {
                addError({ id: 'nao-foi-possivel-salvar-error', message: 'Não foi possível salvar', persist: false, hideDuration: 5000, closeable: true, preventDuplicate: true });
            })
            .finally(() => {
                dispatch(completeLoading());
            });
    };

    const onClose = () => {
        resetAll();
        props.setOpen(false);
    };

    const getDisabled = () => {
        return false;
    };

    const onChangeIdentificacao = (identify: any) => {
        setControlValue('identificacao', identify);
        if (identify.length <= 0 || isEditingRef.current) {
            return;
        }

        service.getExistidentification(identify)
            .then(({ data }) => {
                setLocalState(prev => ({ ...prev, existIdentification: data }));
            })
            .catch(err => {
                if (err.response.data.code === 'NonUniqueResultException') {
                    setLocalState(prev => ({ ...prev, existIdentification: true }));
                }
            });
    };

    const onChangeEmail = (value: Array<string>) => {
        const emailInputValue: Array<string> = value.filter(s => s.trim());
        setContatoState(prev => ({
            ...prev,
            inputChipsValue: emailInputValue,
            contato: {
                ...prev.contato,
                enderecoEletronicos: emailInputValue
                    .map(endereco => ({
                        uuid: '',
                        tipo: 'EMAIL',
                        endereco: endereco.trim(),
                        enviaNfe: prev.enviarNotaFiscal,
                    })),
            }
        }));
    };

    const onChangeEnviarNF = (e: unknown, value: boolean) => {
        setContatoState(prev => {
            const copyEmail = prev.contato.enderecoEletronicos.slice();
            copyEmail.forEach(s => s.enviaNfe = value);
            return {
                ...prev,
                enviarNotaFiscal: value,
                contato: {
                    ...prev.contato,
                    enderecoEletronicos: copyEmail,
                }
            };
        });
    };

    const getNascimento = (): string => {
        const nascimento = getRawValue('nascimento');
        if (nascimento) {
            return new Date(nascimento).toLocaleDateString('pt-BR');
        }
        return '';
    };

    const onChangeDataNascimento = (value: string, formattedValue: string) => {
        if (value.length === 8) {
            const data = formattedValue.split('/').reverse().join('/');
            try {
                setControlValue('nascimento', new Date(data).toISOString());
            } catch (err) {
                setControlValue('nascimento', null);
                addError({ id: 'data-invalida-error', message: 'Data inválida', closeable: true, hideDuration: 3000 });
            }
        }
        if (value === '') {
            setControlValue('nascimento', null);
        }
    };

    const onChangeTipoContribuinte = (value: string) => {
        setControlValue('tipoContribuinte', value);
        switch (getRawValue('tipoContribuinte')) {
            case 'CONTRIBUINTE_ISENTO':
                resetControl('inscricaoEstadual');
                setControlValue('consumidorFinal', false);
                enableControl('consumidorFinal');
                break;
            case 'NAO_CONTRIBUINTE':
                setControlValue('consumidorFinal', true);
                disableControl('consumidorFinal');
                break;
            default:
                setControlValue('consumidorFinal', false);
                enableControl('consumidorFinal');
        }
    };

    const onChangeCep = (value: string, event: any) => {
        if (value) {
            setEnderecoValue('codigoPostal', value);
            if (event?.source === 'event') {
                onSearchCep(value);
            }
        } else {
            setEnderecoValue('codigoPostal', null);
        }
    };

    const onSearchCep = (cep: string) => {
        if (cep?.length === 8) {
            service.findCep(cep)
                .then(({ data }) => {
                    const { endereco, municipio } = data;
                    if (!endereco.complemento?.trim()) {
                        endereco.complemento = null;
                    }
                    setEnderecoFormValue(endereco);
                    setEnderecoValue('municipio', municipio);
                })
                .catch(() => {
                    addError({ id: 'cep-nao-encontrado-error', message: 'CEP não encontrado', closeable: true, persist: false, preventDuplicate: true });
                });
        }
    };

    const onSearchCnpj = () => {
        const cnpj = getRawValue('identificacao');
        if (cnpj.length === 14 && !hasError('cpfCnpjInvalid')) {
            service.searchCnpj(cnpj)
                .then(({ data }) => {
                    if (data.identificacao) {
                        mergeFindedCnpj(data);
                        onChangeTipoContribuinte(getRawValue('tipoContribuinte'));
                    } else {
                        addError({ id: 'cnpj-not-found-error', message: 'Não foi possivel encontrar o CNPJ abc', persist: false, closeable: true, preventDuplicate: true });
                    }
                })
                .catch(() => {
                    addError({ id: 'cnpj-not-found-error', message: 'Não foi possivel encontrar o CNPJ asdfasdasd', persist: false, closeable: true, preventDuplicate: true });
                });
        }
    };

    const findTelefoneByTipo = (tipo: 'COMERCIAL' | 'CELULAR' | 'RESIDENCIAL', contato: Contato) => {
        return contato.telefones?.find(s => s.tipo === tipo)?.telefone ?? '';
    };

    const mergeFindedCnpj = (p: Pessoa) => {
        setFormValue(p);

        const endereco = p.enderecos?.find(s => s.tipo === 'PRINCIPAL') ?? p.enderecos?.[0];
        if (endereco) {
            if (!endereco.complemento?.trim()) {
                endereco.complemento = null;
            }
            setEnderecoFormValue(endereco);
        }

        const contato = p.contatos?.find(s => s.tipo === 'PRINCIPAL') ?? p.contatos?.[0];
        if (contato) {
            const enderecoEletronico = contato.enderecoEletronicos?.[0];
            const newContato = {
                ...contato,
                enderecoEletronicos: enderecoEletronico ? [enderecoEletronico] : [],
                telefones: [
                    {
                        uuid: '',
                        tipo: 'COMERCIAL',
                        telefone: findTelefoneByTipo('COMERCIAL', contato),
                    },
                    {
                        uuid: '',
                        tipo: 'CELULAR',
                        telefone: findTelefoneByTipo('CELULAR', contato)
                    },
                    {
                        uuid: '',
                        tipo: 'RESIDENCIAL',
                        telefone: findTelefoneByTipo('RESIDENCIAL', contato)
                    },
                ]
            };

            setContatoState(prev => ({
                ...prev,
                inputChipsValue: enderecoEletronico ? [enderecoEletronico.endereco] : [],
                contato: newContato
            }));
        }
    };

    return (
        <>
            <CustomDialog
                id="cadastro-pessoa-dialog"
                open={props.open ?? false}
                fullWidth={true}
                maxWidth={'lg'}
                onClose={onClose}
            >

                <form onSubmit={onConfirm} style={{ gap: 0 }}>
                    <DialogTitle id="dialog-title" textAlign={'center'}>
                        <Typography fontSize={30}>Cadastro de Pessoa</Typography>
                    </DialogTitle>

                    <DialogContent id="dialog-content" sx={{ display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'center' }}>
                        <Grid container spacing={2}>

                            {/* TIPO PESSOA */}
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth variant="standard" error={false}>
                                    <InputLabel id="tipo-pessoa-label" htmlFor="tipo-pessoa-input">Tipo de pessoa</InputLabel>
                                    <Select
                                        id="tipo-pessoa-component"
                                        inputProps={{ id: 'tipo-pessoa-input' }}
                                        value={getRawValue('tipoPessoa')}
                                        onChange={(e) => { setControlValue('tipoPessoa', e.target.value); }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 48 * 4.5 + 8,
                                                    width: 100
                                                },
                                            },
                                        }}
                                        disabled={isEditing}
                                    >
                                        {tipoPessoaAC.map(tp => (
                                            <MenuItem
                                                key={randomUuid()}
                                                value={tp.name}
                                            >
                                                {(tp as any).label}
                                            </MenuItem>
                                        ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* IDENTIFICACAO */}
                            <PatternFormatCustom
                                gridSize={{ xs: 12, md: 8 }}
                                id='identificacao-social'
                                label={`${getValueByTipoPessoa('CNPJ', 'CPF', "IDENTIFICACAO")} ${isEditing ? '' : '*'}`}
                                format={getValueByTipoPessoa('##.###.###/####-##', '###.###.###-##', "####################")}
                                value={getRawValue('identificacao')}
                                onChange={({ value }) => onChangeIdentificacao(value)}
                                disabled={isEditing}
                                InputProps={{
                                    endAdornment:
                                        <>
                                            {isPessoaJuridica() &&
                                                <Button
                                                    sx={{
                                                        width: 'fit-content',
                                                        minWidth: 'auto',
                                                    }}
                                                    onClick={onSearchCnpj}
                                                    disabled={isEditing}
                                                >
                                                    <Icon
                                                        baseClassName='fa-solid fa-search'
                                                        sx={{
                                                            color: isEditing ? 'grey' : 'var(--laranja)',
                                                            fontSize: '15px',
                                                        }}
                                                    />
                                                </Button>
                                            }
                                        </>
                                }}
                                formError={[
                                    { hasError: localState.existIdentification, message: 'Documento de identificação já registrado' },
                                    { hasError: localState.invalidCpfCnpj, message: `${getValueByTipoPessoa('CNPJ', 'CPF', 'IDENTIFICACAO')} inválido` },
                                    { hasError: localState.submitted && hasError('required', 'identificacao'), message: 'Este campo é obrigatório' },
                                ]}
                            />

                            {/* NOME RAZAO SOCIAL */}
                            <TextFieldCustom
                                gridSize={{ xs: 12, md: getValueByTipoPessoa(6, 12, 12) }}
                                id='nome-razao-social'
                                label={`${getValueByTipoPessoa('Razão social', 'Nome', 'Nome')} ${isEditing ? '' : '*'}`}
                                value={getRawValue('nomeRazaoSocial')}
                                onChange={(e) => {
                                    setControlValue('nomeRazaoSocial', e.target.value.toUpperCase());
                                }}
                                formError={[
                                    { hasError: localState.submitted && hasError('required', 'nomeRazaoSocial'), message: 'Este campo é obrigatório' },
                                ]}
                                disabled={isEditing}
                            />

                            {/* NOME FANTASIA */}
                            <TextFieldCustom
                                gridSize={{ xs: 12, md: 6 }}
                                id='nome-fantasia'
                                label="Nome fantasia"
                                value={getRawValue('nomeFantasia')}
                                visible={isPessoaJuridica()}
                                onChange={(e) => {
                                    setNullableInPessoa('nomeFantasia', e.target.value, 'toUpperCase');
                                }}
                            />

                            {/* TIPO CONTRIBUINTE */}
                            {isPessoaJuridica() &&
                                (<>
                                    <Grid item xs={12} md={getRawValue('tipoContribuinte') !== 'CONTRIBUINTE_ISENTO' ? 6 : 12}>
                                        <FormControl fullWidth variant="standard" error={false}>
                                            <InputLabel id="tipo-contribuinte-label" htmlFor="tipo-contribuinte-input">Tipo de contribuinte</InputLabel>
                                            <Select
                                                id="tipo-contribuinte-component"
                                                inputProps={{ id: 'tipo-contribuinte-input' }}
                                                value={getRawValue('tipoContribuinte')}
                                                onChange={(e) => { onChangeTipoContribuinte(e.target.value); }}
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 48 * 4.5 + 8,
                                                            width: 100
                                                        },
                                                    },
                                                }}
                                            >

                                                {tipoContribuinteAC.map(tp => (
                                                    <MenuItem
                                                        key={randomUuid()}
                                                        value={tp.name}
                                                        sx={{
                                                            whiteSpace: 'break-spaces'
                                                        }}
                                                    >
                                                        {(tp as any).descricao}
                                                    </MenuItem>
                                                ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    {/* Inscrição estadual */}
                                    <PatternFormatCustom
                                        gridSize={{ xs: 12, md: 6 }}
                                        id='inscricao-estadual'
                                        label={`Inscrição estadual ${getRawValue('tipoContribuinte') === 'CONTRIBUINTE_ICMS' ? '*' : ''}`}
                                        format={'##############'}
                                        visible={getRawValue('tipoContribuinte') !== 'CONTRIBUINTE_ISENTO'}
                                        value={getRawValue('inscricaoEstadual')}
                                        onChange={({ value }) => {
                                            if (!value) { return setControlValue('inscricaoEstadual', null); }
                                            setControlValue('inscricaoEstadual', value);
                                        }}
                                        formError={[
                                            { hasError: localState.submitted && hasError('invalidInscricaoEstadual'), message: 'Valor inválido' },
                                            { hasError: localState.submitted && hasError('inscricaoEstadualrequired'), message: 'Este campo é obrigatório' },
                                        ]}
                                    />

                                    {/* Inscrição Municipal */}
                                    <PatternFormatCustom
                                        gridSize={{ xs: 12, md: 6 }}
                                        id='inscricao-municipal'
                                        label="Inscrição Municipal"
                                        format='###########'
                                        visible={true}
                                        value={getRawValue('inscricaoMunicipal')}
                                        onChange={({ value }) => {
                                            if (!value) { return setControlValue('inscricaoMunicipal', null); }
                                            return setControlValue('inscricaoMunicipal', value);
                                        }}
                                    />

                                    {/* Inscrição Suframa */}
                                    <PatternFormatCustom
                                        gridSize={{ xs: 12, md: 6 }}
                                        id='inscricao-suframa'
                                        label="Inscrição Suframa"
                                        format='#########'
                                        visible={true}
                                        value={getRawValue('inscricaoSuframa')}
                                        onChange={({ value }) => {
                                            if (!value) {
                                                setControlValue("impostosBeneficioSuframa", []);
                                                setControlValue('inscricaoSuframa', null);
                                                return;
                                            }
                                            return setControlValue('inscricaoSuframa', value);
                                        }}
                                    />

                                    {getRawValue('inscricaoSuframa') !== null &&
                                        <Grid item xs={12} md={getValueByTipoPessoa(6, 6, 6)} lg={getValueByTipoPessoa(6, 6, 6)}>
                                            <FormControl fullWidth variant="standard" sx={{ width: '100%' }} error={false}>
                                                <InputLabel id="papel-label" htmlFor="papel-input">Impostos a serem deduzidos do total da nota</InputLabel>
                                                <Select
                                                    id="imposto-component"
                                                    multiple
                                                    inputProps={{ id: "imposto-input" }}
                                                    value={getRawValue('impostosBeneficioSuframa')}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (Array.isArray(value)) {
                                                            setControlValue('impostosBeneficioSuframa', value);
                                                        }
                                                    }}
                                                    renderValue={(selected) => (
                                                        <>
                                                            {selected.map((value: any) => {
                                                                return (<Chip key={value} sx={{ height: '1.2rem', marginRight: '2px', backgroundColor: 'var(--laranja)', color: '#fff' }} label={value} />
                                                                );
                                                            })}
                                                        </>
                                                    )}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: 48 * 4.5 + 8,
                                                                width: 100
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {impostosAc.current.map(p => (
                                                        <MenuItem
                                                            key={randomUuid()}
                                                            value={p}>
                                                            <Checkbox
                                                                id={`item-${p.toLowerCase()}-input`}
                                                                sx={{
                                                                    '&.MuiCheckbox-root.Mui-checked': {
                                                                        color: 'var(--laranja)'
                                                                    }
                                                                }}
                                                                checked={getRawValue('impostosBeneficioSuframa')?.some((imposto: TImpostosDeduzidos) => imposto === p)}
                                                            />
                                                            <ListItemText primary={p} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    }
                                </>

                                )}

                            {/* PAPEL */}
                            <Grid item xs={12} md={getValueByTipoPessoa(6, 6, 6)} lg={getValueByTipoPessoa(6, 6, 6)}>
                                <FormControl fullWidth variant="standard" sx={{ width: '100%' }} error={false}>
                                    <InputLabel id="papel-label" htmlFor="papel-input" error={localState.submitted && hasError('required', 'papeis',)}>Papéis *</InputLabel>
                                    <Select
                                        error={localState.submitted && hasError('required', 'papeis')}
                                        id="papel-component"
                                        multiple
                                        inputProps={{ id: "papel-input" }}
                                        value={getRawValue('papeis')}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (Array.isArray(value)) {
                                                setControlValue('papeis', value);
                                            }
                                        }}
                                        renderValue={(selected) => (
                                            <>
                                                {selected.map((value: any) => {
                                                    return (<Chip key={value} sx={{ height: '1.2rem', marginRight: '2px', backgroundColor: 'var(--laranja)', color: '#fff' }} label={value} />
                                                    );
                                                })}
                                            </>
                                        )}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 48 * 4.5 + 8,
                                                    width: 100
                                                },
                                            },
                                        }}
                                    >
                                        {papeisAc.map(p => (
                                            <MenuItem
                                                key={randomUuid()}
                                                value={p.name}>
                                                <Checkbox
                                                    id={`item-${p.name.toLowerCase()}-input`}
                                                    sx={{
                                                        '&.MuiCheckbox-root.Mui-checked': {
                                                            color: 'var(--laranja)'
                                                        }
                                                    }}
                                                    checked={getRawValue('papeis')?.some((papel: string) => papel === p.name)}
                                                />
                                                <ListItemText primary={p.descritivo} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {localState.submitted && hasError('required', 'papeis') &&
                                        <FormHelperText className='Mui-error' id='papel-helper-text' color={'error'}>Selecione ao menos um papel</FormHelperText>
                                    }
                                </FormControl>
                            </Grid>

                            {/* NASCIMENTO */}
                            <PatternFormatCustom
                                gridSize={{ xs: 12, md: 3 }}
                                format='##/##/####'
                                label='Nascimento'
                                id='nascimento'
                                visible={!isPessoaEstrangeira() && !isPessoaJuridica()}
                                value={getNascimento()}
                                onChange={({ value, formattedValue }) => {
                                    onChangeDataNascimento(value, formattedValue);
                                }}
                                onBlur={(e) => {
                                    const value = e.target.value?.replaceAll('/', '').replace('_', '');
                                    if (value.length !== 8) {
                                        setControlValue('nascimento', null);
                                    }
                                }}
                            />

                            {/* RG */}
                            <PatternFormatCustom
                                gridSize={{ xs: 12, md: 3 }}
                                format='##.###.###-#'
                                label='RG'
                                id='rg'
                                visible={!isPessoaEstrangeira() && !isPessoaJuridica()}
                                value={getRawValue('rg')}
                                onChange={({ value }) => {
                                    if (value === "") {
                                        return setControlValue("rg", null);
                                    }
                                    setControlValue("rg", value);
                                }}
                            />

                            {/* CONSUMIDOR FINAL */}
                            {isPessoaJuridica() &&
                                <Grid item xs={12} md={4} lg={3}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end'
                                    }}
                                >
                                    <FormControlLabel
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            marginLeft: 0,
                                        }}
                                        label="Consumidor final"
                                        control={
                                            <CustomSwitch
                                                disabled={isDisabledControl('consumidorFinal')}
                                                id='consumidor-final-input'
                                                checked={getRawValue('consumidorFinal')}
                                                onChange={(e, value) => {
                                                    setControlValue('consumidorFinal', value);
                                                }}
                                                name="consumidor-final" />
                                        }
                                        labelPlacement="start"
                                    />
                                </Grid>
                            }

                            {/* TABS */}
                            <Grid item xs={12}>
                                <TabContext
                                    value={localState.selectTab}
                                >
                                    {/* TAB HEADER */}
                                    <Box sx={{ marginTop: '20px', borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                                        <TabList
                                            onChange={(e, value) => {
                                                setLocalState(prev => ({
                                                    ...prev,
                                                    selectTab: value
                                                }));
                                            }}
                                            sx={{
                                                width: '100%',
                                                '& .MuiTabs-scroller .MuiTabs-indicator ': {
                                                    backgroundColor: 'var(--laranja)',
                                                },
                                                '& .MuiTabs-flexContainer': {
                                                    display: 'flex',
                                                    justifyContent: 'space-around'
                                                }
                                            }}
                                        >
                                            <Tab
                                                sx={tabHeaderStyle}
                                                label="Endereço"
                                                value="endereco"
                                            />

                                            <Tab
                                                sx={tabHeaderStyle}
                                                label="Contato"
                                                value="contato"
                                            />

                                            <Tab
                                                sx={tabHeaderStyle}
                                                label="Observação"
                                                value="observacao"
                                            />
                                        </TabList>
                                    </Box>

                                    {/* TAB CONTENT ENDERECO */}
                                    <TabPanel
                                        sx={tabContentStyle}
                                        value="endereco"
                                    >
                                        <Grid container spacing={2}>

                                            {/* CEP */}
                                            <PatternFormatCustom
                                                gridSize={{ xs: 12, md: 4 }}
                                                id='cep'
                                                label={`${isPessoaEstrangeira() ? "Código Postal" : "CEP"}`}
                                                format='#####-###'
                                                value={getRawEnderecoValue('codigoPostal')}
                                                onChange={(event, source) => {
                                                    onChangeCep(event.value, source);
                                                }}
                                                InputProps={{
                                                    endAdornment:
                                                        <Button
                                                            sx={{
                                                                width: 'fit-content',
                                                                minWidth: 'auto',
                                                            }}
                                                            onClick={() => {
                                                                onSearchCep(getRawEnderecoValue('codigoPostal'));
                                                            }}
                                                        >
                                                            <Icon
                                                                baseClassName='fa-solid fa-search'
                                                                sx={{
                                                                    color: 'var(--laranja)',
                                                                    fontSize: '15px',
                                                                }}
                                                            />
                                                        </Button>
                                                }}
                                            />

                                            {/* LOGRADOURO */}
                                            <TextFieldCustom
                                                gridSize={{ xs: 12, md: 8 }}
                                                id='logradouro'
                                                label='Logradouro *'
                                                value={getRawEnderecoValue('logradouro')}
                                                onChange={(e) => {
                                                    setEnderecoValue('logradouro', e.target.value);
                                                }}
                                                formError={[
                                                    { hasError: localState.submitted && hasEnderecoError('required', 'logradouro'), message: 'Este campo é obrigatório' },
                                                    { hasError: localState.submitted && hasEnderecoError('minSize3', 'logradouro'), message: 'Campo logradouro é obrigatório preenchimento de no mínimo três caracteres' },
                                                ]}
                                            />

                                            {/* NUMERO */}
                                            <TextFieldCustom
                                                gridSize={{ xs: 12, md: 4 }}
                                                id='numero'
                                                label='Número *'
                                                value={getRawEnderecoValue('numero')}
                                                onChange={(e) => {
                                                    setEnderecoValue('numero', e.target.value);
                                                }}
                                                formError={[
                                                    { hasError: localState.submitted && hasEnderecoError('required', 'numero'), message: 'Este campo é obrigatório' },
                                                ]}
                                            />

                                            {/* COMPLEMENTO */}
                                            <TextFieldCustom
                                                gridSize={{ xs: 12, md: 8 }}
                                                id='complemento'
                                                label='Complemento'
                                                value={getRawEnderecoValue('complemento')}
                                                onChange={(e) => {
                                                    setEnderecoValue('complemento', e.target.value);
                                                }}
                                            />

                                            {/* BAIRRO */}
                                            <TextFieldCustom
                                                gridSize={{ xs: 12, md: 4 }}
                                                id='bairro'
                                                label='Bairro *'
                                                value={getRawEnderecoValue('bairro')}
                                                onChange={(e) => {
                                                    setEnderecoValue('bairro', e.target.value);
                                                }}
                                                formError={[
                                                    { hasError: localState.submitted && hasEnderecoError('required', 'bairro',), message: 'Este campo é obrigatório' },
                                                ]}
                                            />

                                            {/* MUNUCIPIO */}
                                            <Grid item xs={12} md={8}>
                                                <FormControl fullWidth variant="standard" error={localState.submitted && hasEnderecoError('required', 'municipio')}>
                                                    <Autocomplete
                                                        onBlur={() => getMunicipiosAC()}
                                                        value={getRawEnderecoValue('municipio')}
                                                        id="acCliente"
                                                        options={municipioAC}
                                                        getOptionLabel={(option) => option.descritivo}
                                                        onInputChange={(e, value) => {
                                                            getMunicipiosAC(value);
                                                        }}
                                                        filterOptions={(x) => x}
                                                        noOptionsText={'Nenhum resultado encontrado'}
                                                        onChange={(e, a) => {
                                                            if (a) {
                                                                setEnderecoValue('municipio', a);
                                                            }
                                                        }}
                                                        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                                                        renderInput={
                                                            (params) => <TextField
                                                                {...params}
                                                                label="Município *"
                                                                variant="standard"
                                                                error={localState.submitted && hasEnderecoError('required', 'municipio')}
                                                            />}
                                                    />
                                                    {localState.submitted && hasEnderecoError('required', 'municipio') &&
                                                        <FormHelperText className='Mui-error' id='papel-helper-text' color={'error'}>Este campo é obrigatório</FormHelperText>
                                                    }
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>

                                    {/* TAB CONTENT CONTATO */}
                                    <TabPanel
                                        sx={tabContentStyle}
                                        value="contato">
                                        <Grid container spacing={2}>

                                            {/* TELEFONE COMERCIAL */}
                                            <PatternFormatCustom
                                                gridSize={{ xs: 12, md: 4 }}
                                                id='telefone-comercial'
                                                label='Telefone comercial'
                                                format='(##) #####-####'
                                                value={contatoState.contato.telefones[0].telefone}
                                                onChange={({ value }) =>
                                                    setContatoState(prev => ({
                                                        ...prev,
                                                        contato: {
                                                            ...prev.contato,
                                                            telefones: [
                                                                { ...prev.contato.telefones[0], telefone: value },
                                                                prev.contato.telefones[1],
                                                                prev.contato.telefones[2],
                                                            ]
                                                        }
                                                    }))
                                                }
                                            />

                                            {/* TELEFONE MOVEL */}
                                            <PatternFormatCustom
                                                gridSize={{ xs: 12, md: 4 }}
                                                id='celular'
                                                label='Celular'
                                                format='(##) #####-####'
                                                value={contatoState.contato.telefones[1].telefone}
                                                onChange={({ value }) =>
                                                    setContatoState(prev => ({
                                                        ...prev,
                                                        contato: {
                                                            ...prev.contato,
                                                            telefones: [
                                                                prev.contato.telefones[0],
                                                                { ...prev.contato.telefones[1], telefone: value },
                                                                prev.contato.telefones[2],
                                                            ]
                                                        }
                                                    }))
                                                }
                                            />

                                            {/* TELEFONE RESIDENCIAL */}
                                            <PatternFormatCustom
                                                gridSize={{ xs: 12, md: 4 }}
                                                id='telefone-residencial'
                                                label='Telefone residencial'
                                                format='(##) #####-####'
                                                value={contatoState.contato.telefones[2].telefone}
                                                onChange={({ value }) =>
                                                    setContatoState(prev => ({
                                                        ...prev,
                                                        contato: {
                                                            ...prev.contato,
                                                            telefones: [
                                                                prev.contato.telefones[0],
                                                                prev.contato.telefones[1],
                                                                { ...prev.contato.telefones[2], telefone: value },
                                                            ]
                                                        }
                                                    }))
                                                }
                                            />

                                            {/* EMAIL */}
                                            <Grid item xs={12} md={8}>
                                                <Tooltip
                                                    title="Digite o email e aperte enter"
                                                    arrow
                                                    followCursor
                                                >
                                                    <FormControl fullWidth variant="standard" error={false}>
                                                        <Autocomplete
                                                            options={[]}
                                                            freeSolo
                                                            multiple
                                                            renderTags={(value, props) =>
                                                                value.map((option, index) => (
                                                                    <Chip
                                                                        label={option}
                                                                        {...props({ index })}
                                                                        sx={{
                                                                            height: '1.2rem',
                                                                            minHeight: 'auto',
                                                                            padding: 0,
                                                                            backgroundColor: 'var(--laranja)',
                                                                            color: '#fff',
                                                                            '& .MuiChip-deleteIcon': {
                                                                                color: '#FFF',
                                                                                fontSize: '15px'
                                                                            }
                                                                        }}
                                                                    />
                                                                ))
                                                            }

                                                            onBlur={(e: any) => {
                                                                if (e.target.value) {
                                                                    if (isValidEmail(e.target.value)) {
                                                                        onChangeEmail([...contatoState.inputChipsValue, e.target.value])
                                                                    } else {
                                                                        addError({ message: "O e-mail fornecido não é válido", closeable: true, persist: false, preventDuplicate: false, id: "email-invalid-error" })
                                                                    }
                                                                    setContatoState(prev => ({ ...prev, inputValue: '' }));
                                                                }
                                                            }}
                                                            inputValue={contatoState.inputValue}
                                                            onInputChange={(e, value) => {
                                                                setContatoState(prev => ({ ...prev, inputValue: value }));
                                                            }}
                                                            clearIcon={false}
                                                            value={contatoState.inputChipsValue}
                                                            onChange={(e, value) => {
                                                                onChangeEmail(value);
                                                            }}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    id={`email-input`}
                                                                    fullWidth={true}
                                                                    variant="standard"
                                                                    label='Email'
                                                                    value={contatoState.inputValue}
                                                                />}
                                                        />

                                                    </FormControl>
                                                </Tooltip>
                                            </Grid>

                                            {/* ENVIAR NOTA */}
                                            <Grid item xs={12} md={4}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'flex-end'
                                                }}
                                            >
                                                <FormControlLabel
                                                    sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        marginLeft: 0,
                                                    }}
                                                    disabled={!contatoState.contato.enderecoEletronicos.length}
                                                    label="Enviar nota fiscal?"
                                                    control={
                                                        <CustomSwitch
                                                            disabled={undefined}
                                                            id='enviar-nota-fiscal-input'
                                                            checked={contatoState.enviarNotaFiscal}
                                                            onChange={onChangeEnviarNF}
                                                            name="consumidor-final" />
                                                    }
                                                    labelPlacement="start"
                                                />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>

                                    {/* TAB CONTENT OBSERVACAO */}
                                    <TabPanel
                                        sx={tabContentStyle}
                                        value="observacao"
                                    >
                                        {/* OBSERVACAO */}
                                        <FormControl fullWidth variant="standard" error={false}>
                                            <TextField
                                                inputProps={{ id: 'observacao-input', maxLength: 999 }}
                                                multiline
                                                rows={6}
                                                value={getRawValue('observacoes')[0]?.observacao ?? ''}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (!value.trim()) {
                                                        setControlValue('observacoes', []);
                                                    } else {
                                                        setControlValue('observacoes', [{
                                                            usuario: userName!,
                                                            dataHora: new Date().toISOString(),
                                                            observacao: value,
                                                        }]);
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </TabPanel>

                                </TabContext>
                            </Grid>

                        </Grid>
                    </DialogContent >

                    <DialogActions
                        sx={{
                            justifyContent: 'space-between'
                        }}>
                        <CustomButton onClick={onClose} variant="contained" color="error" startIcon={<Icon className='fa-solid fa-xmark' />}>
                            Cancelar
                        </CustomButton>
                        <CustomButton type="submit" disabled={getDisabled()} variant="contained" color="success" startIcon={<Icon className='fa-solid fa-check' />}>
                            {isEditing ? "Confirmar" : "Salvar"}
                        </CustomButton>
                    </DialogActions>
                </form >
            </CustomDialog >
        </>
    );
};

export default CadastroPessoa;

export const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: 42,
    height: 24,
    transform: 'translateX(1px)',
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 3,
        color: '#fff',
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(18px)',
            color: 'var(--laranja)',
            '& + .MuiSwitch-track': {
                backgroundColor: '#d6b794',
                opacity: .7,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: .5,
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: 'var(--laranja)',
                opacity: .4,
            },
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: '#fff',
            opacity: .7,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: .3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18,
        height: 18,
    },
    '& .MuiSwitch-track': {
        borderRadius: 10,
        backgroundColor: '#aeaeae',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 300,
        }),
    },
}));

type TextFieldProps = {
    formError?: Array<{
        hasError: boolean | undefined;
        message: string;
    }>;
    gridSize: {
        xs: number;
        sm?: number;
        md?: number;
        lg?: number;
    };
    visible?: boolean;
    id: string;
    onChange: (e: any, source?: any) => void;
    onBlur?: (e: any) => void;
    value: string | number | null;
    label: string;
    InputProps?: Partial<FilledInputProps> | Partial<OutlinedInputProps> | Partial<InputProps>;
    disabled?: boolean;
};

const TextFieldCustom = (props: TextFieldProps) => {
    let visible = props.visible !== undefined ? props.visible : true;
    const hasError = props.formError?.some(s => s.hasError);
    return (
        <>
            {visible &&
                <Grid item xs={props.gridSize.xs} md={props.gridSize.md}>
                    <FormControl fullWidth variant="standard" error={hasError}>
                        <TextField
                            error={hasError}
                            id={`${props.id}-input`}
                            fullWidth={true}
                            variant="standard"
                            label={props.label}
                            onChange={props.onChange}
                            value={props.value ?? ''}
                            InputProps={props.InputProps}
                            onBlur={props.onBlur}
                            disabled={props.disabled}
                        />
                        {hasError &&
                            props.formError?.filter(f => f.hasError).map((item, idx) => (
                                <FormHelperText key={idx} id={`${props.id}-helper-text`} color={'error'}>{item.message}</FormHelperText>
                            ))
                        }
                    </FormControl>
                </Grid>
            }
        </>
    );
};

const PatternFormatCustom = (props: TextFieldProps & { format: string; }) => {
    let visible = props.visible !== undefined ? props.visible : true;
    const hasError = props.formError?.some(s => s.hasError);
    return (
        <>
            {visible &&
                <Grid item xs={props.gridSize.xs} md={props.gridSize.md}>
                    <FormControl fullWidth variant="standard" error={hasError}>
                        <PatternFormat
                            error={hasError}
                            id={`${props.id}-input`}
                            label={props.label}
                            InputLabelProps={{ id: `${props.id}-label` }}
                            format={props.format}
                            allowEmptyFormatting={false}
                            mask='_'
                            customInput={TextField}
                            variant="standard"
                            value={props.value ?? ''}
                            onValueChange={props.onChange}
                            InputProps={props.InputProps}
                            onBlur={props.onBlur}
                            disabled={props.disabled}
                        />
                        {hasError &&
                            props.formError?.filter(f => f.hasError).map((item, idx) => (
                                <FormHelperText key={idx} id={`${props.id}-helper-text`} color={'error'}>{item.message}</FormHelperText>
                            ))
                        }
                    </FormControl>
                </Grid>
            }
        </>
    );
};
