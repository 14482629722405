import { Box, Chip, Icon, Tooltip } from "@mui/material"
import { IAbstractModel } from "model/abstract.model"
import MasterCardImage from '../../src/assets/masterCard.png'
import AmexCardImage from "../../src/assets/Amex.png"
import VisaCardImage from "../../src/assets/Visa.png"
import HiperCardImage from "../../src/assets/HiperCard.png"
import EloCardImage from "../../src/assets/Elo.png"
import DinersCardImage from '../../src/assets/Diners.png'
import AleloCardImage from '../../src/assets/Alelo.png'
import { memo, useCallback } from "react"
import { DadosPagamentoCartaoBandeiraTO, BandeiraCartaoTO } from "pages/pagamento/pagamento.model"

type BandeiraCardProps = {
    credenciadoraSelecionada: IAbstractModel
    bandeiras: Array<DadosPagamentoCartaoBandeiraTO>
    bandeiraSelecionada: BandeiraCartaoTO | null
    onSelectBandeira: (uuid: BandeiraCartaoTO) => void;
}

const BandeiraCard = (props: BandeiraCardProps) => {

    const getImage = useCallback((descritivo: string) => {
        const upperCaseDescritivo = descritivo.toUpperCase();

        if (upperCaseDescritivo.includes("MASTER")) return MasterCardImage;
        if (upperCaseDescritivo.includes("EXPRESS") || upperCaseDescritivo.includes("AMEX")) return AmexCardImage;
        if (upperCaseDescritivo.includes("VISA")) return VisaCardImage;
        if (upperCaseDescritivo.includes("HIPER")) return HiperCardImage;
        if (upperCaseDescritivo.includes("ELO")) return EloCardImage;
        if (upperCaseDescritivo.includes("DINERS") || upperCaseDescritivo.includes("DINNERS")) return DinersCardImage
        if (upperCaseDescritivo.includes("ALELO")) return AleloCardImage;
        return "";
        //eslint-disable-next-line
    }, [])

    const getImageOrIcon = useCallback((descritivo: string) => {
        if (getImage(descritivo)) {
            return <img src={getImage(descritivo)} style={{
                objectFit: "contain",
                width: '45px',
                height: '45px'
            }} alt="MasterCard" />
        }
        return (
            <Icon
                sx={{ fontSize: '18px', width: '40px' }}
                className="fa-regular fa-credit-card"
            />
        )
        //eslint-disable-next-line
    }, [])
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: !props.bandeiraSelecionada ? 'flex-end' : 'flex-start',
            overflow: 'auto',
            maxHeight: '35vh'
        }}>

            {!props.bandeiraSelecionada && props.bandeiras &&
                <>
                    {props.bandeiras && props.bandeiras.find(e => e.credenciadoraUUID === props.credenciadoraSelecionada.uuid)?.bandeiras
                        .map((bandeira, index) => {
                            return (
                                <Tooltip
                                    title={bandeira.descritivo}
                                    key={bandeira.uuidDadoCredenciadora}>
                                    <Chip
                                        sx={{
                                            margin: '5px',
                                            bgcolor: 'transparent',
                                            border: '1px solid var(--laranja)',
                                            width: '300px',
                                            minHeight: '50px',
                                            justifyContent: 'flex-start',
                                            padding: '10px',
                                        }}
                                        key={bandeira.uuidDadoCredenciadora}
                                        label={bandeira.descritivo}
                                        icon={getImageOrIcon(bandeira.descritivo)}
                                        onClick={props.onSelectBandeira.bind(null, bandeira)}
                                    />
                                </Tooltip>
                            )
                        })
                    }
                </>
            }
            {props.bandeiraSelecionada &&
                <Chip sx={{
                    margin: '5px',
                    bgcolor: 'transparent',
                    border: '1px solid var(--laranja)',
                    minHeight: "50px",
                    minWidth: '240px',
                    maxWidth: '300px',
                    justifyContent: 'flex-start',
                    padding: '10px',
                    pointerEvents: props.bandeiraSelecionada ? 'none' : 'auto',
                }}
                    key={props.bandeiraSelecionada.uuidDadoCredenciadora}
                    label={props.bandeiraSelecionada.descritivo}
                    icon={getImageOrIcon(props.bandeiraSelecionada.descritivo)}
                />
            }
        </Box>

    )
}

export default memo(BandeiraCard);