import { AxiosResponse } from "axios";
import { IAbstractModel } from "model/abstract.model";
import { IConsumidor } from "model/cliente.model";


export const currencyOf = (value: number | null = 0) => {
    return (value ?? 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
};

// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 900px
// lg, large: 1200px
// xl, extra-large: 1536px

export const isLayoutXs = () => {
    return window.innerWidth < 600;
};

export const isLayoutSm = () => {
    return window.innerWidth >= 600 && window.innerWidth < 900;
};

export const isLayoutMd = () => {
    return window.innerWidth >= 900 && window.innerWidth < 1200;
};

export const isLayoutLg = () => {
    return window.innerWidth >= 1200 && window.innerWidth < 1536;
};

export const isLayoutXl = () => {
    return window.innerWidth >= 1536;
};

export const fakeAxiosPromise = <T>(value: T, timeMin: number, timeMax: number): Promise<AxiosResponse<T>> => {
    const minCeiled = Math.ceil(timeMin);
    const maxFloored = Math.floor(timeMax);
    const delay = Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled);
    return new Promise((resolve, reject) => {
        const toReturn: AxiosResponse<T> = {
            data: value,
        } as AxiosResponse<T>;
        setTimeout(() => {
            resolve(toReturn);
        }, delay);
    });
};

/**
 * Remove os zeros após a vírgula de uma string, se todos os dígitos após a vírgula forem zeros.
 *
 * @param {string} str - A string de entrada a ser limpa.
 * @returns {string} A string limpa, ou a string original se não houver zeros após a vírgula.
 */
export const limpaRef = (str: string) => {
    // eslint-disable-next-line
    const [, parteDecimal] = str.match(/,(\d*)/) || [, ''];
    if (/^0*$/.test(parteDecimal)) {
        return str.replace(/,0*$/, '');
    } else {
        return str;
    }
};

export const limpaString = (str: string) => {
    return str.replace(/\D/g, '');
};

export const convertDate = (inputDate: string) => {
    const dateObject = new Date(inputDate);

    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getFullYear();
    const hours = (dateObject.getHours()).toString().padStart(2, '0');
    const minutes = dateObject.getMinutes().toString().padStart(2, '0');
    const seconds = dateObject.getSeconds().toString().padStart(2, '0');

    return `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;

};

/**
 * Arredonda um número para duas casas decimais e o converte em um número de ponto flutuante.
 *
 * @param {number} value - O número que deve ser arredondado.
 * @returns {number} O número arredondado com duas casas decimais.
 */

export const toFixed = (value: number): number => {
    return parseFloat(value.toFixed(2));
};

/**
 * Verifica se dois objetos são iguais, comparando suas representações JSON.
 *
 * @param {any} obj1 - O primeiro objeto a ser comparado.
 * @param {any} obj2 - O segundo objeto a ser comparado.
 * @returns {boolean} Retorna true se os objetos forem iguais, caso contrário, retorna false.
 *
 * @example
 * const obj1 = { nome: 'Alice', idade: 30 };
 * const obj2 = { nome: 'Alice', idade: 30 };
 * const saoIguais = equalsObj(obj1, obj2);
 * console.log(saoIguais); // true
 */
export const equalsObj = (obj1: any, obj2: any): boolean => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
};

/**
 * Obtém as chaves (nomes) associadas aos valores específicos de um enum.
 *
 * @template T - O tipo do enum.
 * @template U - O tipo dos valores do enum.
 * 
 * @param {Array<U>} enumValues - Um array contendo os valores do enum.
 * @param {T} enumType - O enum para o qual deseja obter as chaves.
 * 
 * @returns {Array<keyof T>} - Um array contendo as chaves (nomes) associadas aos valores fornecidos.
 * 
 * @throws {Error} Será lançado um erro se o valor do enum não for encontrado.
 * 
 * @example
 * const enumValues = [StatusPedido.FATURADO, StatusPedido.ABERTO];
 * // ou const enumValues = ['Faturado', 'Aberto'];
 * const enumType = StatusPedido;
 * const keys = getKeysOfEnum(enumValues, enumType);
 * console.log(keys); // ['FATURADO', 'ABERTO']
 */
export const getKeysOfEnum = <T extends Record<string, U>, U>(enumValues: U[], enumType: T): Array<keyof T> => {
    return enumValues.map(value => {
        const find = Object.keys(enumType).find(key => enumType[key] === value);
        if (!find) {
            throw new Error('Valor do enum não encontrado');
        }
        return find as keyof T;
    });
};

export const getOneKeyOfEnum = <T extends Record<string, U>, U>(enumValue: U, enumType: T): keyof T => {
    return getKeysOfEnum([enumValue], enumType)[0];
};

/**
 * Capitaliza uma string ou as primeiras letras de todas as palavras em uma string.
 *
 * @param {string} string - A string a ser capitalizada.
 * @param {Object} opt - Opções para controle do processo de capitalização.
 * @param {boolean} opt.firstLetterOnly - Se true, apenas a primeira letra da string será capitalizada.
 *                                       Se false ou undefined, a primeira letra de cada palavra será capitalizada.
 * @param {boolean} opt.defaultCaptalize - Se false, as letras serão capitalizadas de forma invertida.
 *                                         Se true ou undefined, as letras serão capitalizadas normalmente.
 * @returns {string} - A string capitalizada.
 *
 * @example
 * const minhaString = "hello world";
 * const stringCapitalizada = capitalizeString(minhaString, { firstLetterOnly: true, defaultCaptalize: true });
 * console.log(stringCapitalizada); // Saída: Hello world
 *
 * @example
 * const minhaOutraString = "hello world";
 * const stringComPalavrasCapitalizadas = capitalizeString(minhaOutraString, { firstLetterOnly: false, defaultCaptalize: true });
 * console.log(stringComPalavrasCapitalizadas); // Saída: Hello World
 */
export const captalizeString = (string: string, opt?: { firstLetterOnly: boolean, defaultCaptalize: boolean; }) => {
    const captalize = (str: string) => {
        if (opt?.defaultCaptalize === false) {
            const stringUpperCase = str.toUpperCase();
            return stringUpperCase.charAt(0).toLowerCase() + stringUpperCase.slice(1);
        }
        const stringLowerCase = str.toLowerCase();
        return stringLowerCase.charAt(0).toUpperCase() + stringLowerCase.slice(1);
    };

    if (opt?.firstLetterOnly !== false) {
        return captalize(string);
    }

    const words = string.split(' ');
    const capitalizedWords = words.map(word => {
        if (word.length > 0) {
            return captalize(word);
        }
        return word;
    });

    return capitalizedWords.join(' ');
};

export const showOnlyDate = (inputDate: string | Date) => {
    const dateObject = new Date(inputDate);

    const day = dateObject.getUTCDate().toString().padStart(2, '0');
    const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getUTCFullYear();

    return `${day}/${month}/${year}`;
};

export const showOnlyDateTwoDigts = (inputDate: string) => {
    const dateObject = new Date(inputDate);

    const day = dateObject.getUTCDate().toString().padStart(2, '0');
    const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getUTCFullYear().toString().slice(-2); // Get last two digits of the year

    return `${day}/${month}/${year}`;
};



export const convertRelatorioDate = (inputDate: string) => {
    const day = inputDate.padStart(2, "0").substring(8);
    const month = inputDate.padStart(2, "0").substring(5, 7);
    const year = inputDate.padStart(2, "0").substring(0, 4);
    return `${day}/${month}/${year}`;
};

/**
 * Cria uma cópia profunda do objeto fornecido usando serialização e análise JSON.
 *
 * @template T
 * @param {T} obj - O objeto a ser copiado profundamente.
 * @returns {T} - Um novo objeto que é uma cópia profunda do objeto de entrada.
 * @throws {SyntaxError} Se o objeto de entrada não puder ser serializado e analisado usando JSON.
 *
 * @example
 * const objetoOriginal = { chave: 'valor', aninhado: { chaveInterna: 'valorInterno' } };
 * const objetoCopiado = deepCopy(objetoOriginal);
 * console.log(objetoCopiado); // Saída: { chave: 'valor', aninhado: { chaveInterna: 'valorInterno' } }
 */
export const deepCopy = <T>(obj: T): T => {
    return JSON.parse(JSON.stringify(obj)) as T;
};

/**
 * Reseta as propriedades do objeto de estado fornecido para os valores no estado inicial.
 *
 * @template T - O tipo do objeto de estado.
 * @param {T} state - O objeto de estado a ser resetado.
 * @param {T} initialState - O estado inicial para resetar o objeto.
 * @returns {T} - O objeto de estado modificado após o reset.
 *
 * @example
 * // Exemplo de uso:
 * const meuEstado = { prop1: 'valor1', prop2: 'valor2' };
 * const estadoInicial = { prop1: 'valorInicial1', prop2: 'valorInicial2' };
 * const novoEstado = resetState(meuEstado, estadoInicial);
 * console.log(novoEstado); // Saída: { prop1: 'valorInicial1', prop2: 'valorInicial2' }
 */
export const deepReset = <T extends Record<string, any>>(state: T, initialState: T): T => {
    for (let prop in state) {
        delete state[prop as keyof T];
    }
    return Object.assign(state, initialState);
};

/**
 * Trunca um número dado para um número especificado de casas decimais sem arredondamento.
 *
 * Esta função multiplica o número de entrada por 10 elevado ao número especificado
 * de casas decimais, trunca o resultado para um inteiro usando `Math.floor`,
 * e depois divide de volta para atingir o número desejado de casas decimais.
 *
 * @param {number} numero - O número a ser truncado.
 * @param {number} casasDecimais - O número de casas decimais a manter.
 * @returns {number} O número truncado com o número especificado de casas decimais.
 *
 * @example
 * // Truncar 5.6789 para 2 casas decimais
 * const resultado = cortarCasasDecimais(5.6789, 2); // resultado é 5.67
 *
 * @example
 * // Truncar -3.4567 para 3 casas decimais
 * const resultado = cortarCasasDecimais(-3.4567, 3); // resultado é -3.456
 */
export const cortarCasasDecimais = (numero: number, casasDecimais: number): number => {
    const multiplicador = Math.pow(10, casasDecimais);
    const numeroCortado = Math.floor(numero * multiplicador) / multiplicador;
    return numeroCortado;
};

export const roundToTwoDecimalPlaces = (value: number) => {
    const fixedNumber = value.toFixed(10);  // Use a larger number to ensure precision
    const roundedNumber = parseFloat(fixedNumber);
    return Number(roundedNumber);  // Convert the formatted string back to a number
};

/**
 * Arredonda um número para o modo "round half to even" (bankers rounding).
 *
 * @param {number} value - O número a ser arredondado.
 * @param {number} decimalPlaces - O número de casas decimais para arredondar.
 * @returns {number} - O número arredondado.
 *
 * @description
 * Arredonda para o vizinho mais próximo, a menos que ambos os vizinhos sejam equidistantes. 
 * Nesse caso, arredonda para o vizinho par. Esse é o modo de arredondamento que minimiza 
 * o erro acumulativo quando aplicado repetidamente sobre uma sequência de cálculos.
 *
 * @example
 * console.log(roundHalfToEven(3.5, 0)) // 4
 * console.log(roundHalfToEven(2.5, 0)) // 2
 * console.log(roundHalfToEven(19.905, 2)) // 19.90
 */
export const roundHalfToEven = (value: number, decimalPlaces: number): number => {
    const factor = 10 ** decimalPlaces;
    const valueFactor = cortarCasasDecimais(value * factor, 2);
    const hasExactlyDot5 = valueFactor % 1 === 0.5;
    const roundedValue = Math.round(value * factor);
    const isOdd = roundedValue % 2 !== 0;
    if (hasExactlyDot5 && isOdd) {
        return (roundedValue - 1) / factor;
    }
    return roundedValue / factor;
};
/**
 * Limita um valor dentro de um intervalo especificado.
 * 
 * @param value - O valor a ser limitado.
 * @param min - O valor mínimo no intervalo.
 * @param max - O valor máximo no intervalo.
 * @returns O valor limitado dentro do intervalo especificado.
 */
export const clamp = (value: number, min: number, max: number): number => {
    // Math.max(min, Math.min(max, value)) assegura que o valor seja no mínimo min
    // e no máximo max. Se o valor for menor que min, é ajustado para min. Se o valor
    // for maior que max, é ajustado para max.
    return Math.max(min, Math.min(max, value));
};

/**
 * Ordena um array de objetos ou valores primitivos alfanumericamente.
 * OBS: ordena números baseado na ordem natural. Ex: 1, 2, 11, 12.
 *
 * @template T - O tipo de elementos no array.
 * @param {Array<T>} array - O array a ser ordenado.
 * @param {Object} [opt] - Opções de ordenação.
 * @param {keyof T} [opt.fieldToSort] - O nome do campo a ser usado para ordenação (aplicável apenas a arrays de objetos).
 * @param {'ASC' | 'DESC'} [opt.orderDirection='ASC'] - A direção da ordenação ('ASC' para ascendente, 'DESC' para descendente).
 * @throws {Error} Se `fieldToSort` não for fornecido para um array de objetos.
 * @returns {Array<T>} - O array ordenado.
 */
export const sortArray = <T extends {} | string | number>(
    array: Array<T>,
    opt?: {
        fieldToSort?: keyof T,
        orderDirection?: 'ASC' | 'DESC';
    }
): Array<T> => {
    const isObjArray = typeof array[0] === 'object';
    if (isObjArray && (opt?.fieldToSort === undefined)) {
        throw new Error('Deve informar fieldToSort se o array for de objeto');
    }
    const field = opt?.fieldToSort!;

    const compareFunction = (a: any, b: any): number => {
        const valueA = (isObjArray ? a[field] : a).toString();
        const valueB = (isObjArray ? b[field] : b).toString();
        const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
        const comparison = collator.compare(valueA, valueB);
        return opt?.orderDirection === 'DESC' ? -comparison : comparison;
    };

    return Array.from(array).sort(compareFunction);
};

/**
 * Converte uma string ou objeto Date para uma string representando uma data no formato pt-BR 'dd/MM/yyyy'.
 * @param {string | Date} string - A string ou objeto Date a ser convertido.
 * @returns {string} A data no formato local.
 * 
 * @example 
 * console.log(toLocaleDateString());                            // new Date() 'dd/MM/yyyy'
 * console.log(toLocaleDateString('2024-03-11'));                // '11/03/2024'
 * console.log(toLocaleDateString('2001-03-20T23:59:50-03:00')); // '20/03/2001'
 * console.log(toLocaleDateString('2024-03-12T23:59:59.999z'));  // '12/03/2024'
 */
export const toLocaleDateString = (string: string | Date = new Date()): string => {
    if (!(string instanceof Date) && string.length === 10) {
        return convertRelatorioDate(string);
    }
    const date = new Date(string);
    return date.toLocaleDateString('pt-BR');
};


export const toLocaleDateTimeString = (isoString: string | Date = new Date()): string => {
    const date = new Date(isoString);

    const dateOptions: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    };

    const timeOptions: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false, // Use 24-hour format
    };

    const formattedDate = date.toLocaleDateString('pt-BR', dateOptions);
    const formattedTime = date.toLocaleTimeString('pt-BR', timeOptions);

    return `${formattedDate} às ${formattedTime}`;
};


/**
 * Converte uma data em formato string ou objeto Date para uma string no formato 'YYYY-MM-DD', 
 * adequada para envio para o backend (LocalDate).
 * @param {string | Date} date A data para ser convertida. Pode ser uma string no formato de data válido ou um objeto Date.
 * @returns {string} Uma string no formato 'YYYY-MM-DD' representando a data fornecida.
 */
export const toLocalDateBackEndFriendly = (date: string | Date): string => {
    return new Date(date).toISOString().split("T")[0];
};

/**
 * Verifica se uma data ocorre antes de outra data.
 * @param {string | Date} date1 - A primeira data a ser comparada.
 * @param {Date} [date2=new Date()] - A segunda data a ser comparada. Se não fornecido, será a data atual.
 * @returns {boolean} Retorna verdadeiro se `date1` ocorrer antes de `date2`, caso contrário, retorna falso.
 */

export const getByTitle = (title: string) => {
    switch (title) {
        case "CREDITOLOJA":
            return "Crédito da loja";
        case "CREDIARIO":
            return "Crediário";
        case "DEPOSITOBANCARIO":
            return "Depósito bancário";
        default: return title;
    }
};

export const isConsumidor = (cliente: IAbstractModel | IConsumidor): cliente is IConsumidor => {
    return (cliente as IConsumidor).tipoPessoa !== undefined;
};

export const extrairNumeroInfoAdicionalDescritivo = (texto: string | null): number | null => {
    if (!texto) return null;
    // Usando expressão regular para encontrar o número
    const match: RegExpMatchArray | null = texto.match(/\d+/);

    if (match) {
        // Convertendo o número encontrado para inteiro
        return parseInt(match[0], 10);
    } else {
        // Retorna null se nenhum número for encontrado
        return null;
    }
}

export const isValidEmail = (email: string): boolean => {
    // Check if email is empty or contains spaces
    if (email.trim().length === 0 || email.includes(' ')) {
        return false;
    }

    let somaArroba = 0;
    const specialCharacters = "áéóã!#$%¨&*()<>/|*=:;,^~+-?°ºª[]{}";

    for (let i = 0; i < email.length; i++) {
        if (email[i] === '@') {
            somaArroba += 1;
        }
        if (specialCharacters.includes(email[i])) {
            return false;
        }
    }

    // Check if there is exactly one '@' symbol
    if (somaArroba !== 1) {
        return false;
    }

    // Check if '@' is not the last character and there's at least one '.'
    if (email.indexOf('@') === email.length - 1 || !email.includes('.')) {
        return false;
    }

    return true;
};

export const formatPhoneNumber = (number: string): string => {
    const cleaned = limpaString(number);
    if (cleaned.length === 11) {
        return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 7)}-${cleaned.slice(7)}`;
    } else if (cleaned.length === 10) {
        return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 6)}-${cleaned.slice(6)}`;
    }
    return number;
}


export const isAtivo = (str: string | undefined): boolean => {
    if (!str) return false;
    if (str.toUpperCase() === "ATIVO") return true
    return false
}