import { Container, FormControl, FormHelperText, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useMemo, useRef } from 'react';
import { useAppSelector } from 'redux/hooks';
import { roundHalfToEven } from 'utils/util';
import { IInfoAdicional, TdadosGeneric, TipoInformacaoAdicional } from 'pages/pagamento/pagamento.model';
import { FormaPagamentoDialogContentProps } from '../formapagamento.model';
import LinhasDadosGeneric from '../LinhasDadosGeneric';
import { useSnackbarCustom } from 'hooks/useSnackCustom';
import FaltaReceberComponent from 'components/FaltaReceberComponent';



const GenericOutrasFormasPagamento = (props: FormaPagamentoDialogContentProps & { tipo: TipoInformacaoAdicional; }) => {
    const { dadosPagamento, setDadosPagamento } = props.valorRecebido;
    const { faltaReceber } = useAppSelector(s => s.pagamento);
    const { hasError, setHasError } = props.error;
    const dadosPagamentoAsInfoAdicional = dadosPagamento.infoAdicional as IInfoAdicional;
    const { addError } = useSnackbarCustom();
    const isFirstRenderRef = useRef(true);
    const { tableRows } = useAppSelector(e => e.pagamento)

    const tableRowsValue = useMemo(() => {
        return tableRows.filter(e => !e.isPago).reduce((prev, act) => prev += act.valor, 0)
    }, [tableRows])

    useEffect(() => {
        if (props.isNew && isFirstRenderRef.current) {
            setDadosPagamento(prev => {
                return ({
                    ...prev, infoAdicional: {
                        descritivo: '',
                        numeroParcelas: 1,
                        tipo: props.tipo,
                        dadosGeneric: [{
                            valor: faltaReceber - tableRowsValue,
                            vencimento: "",
                            vencimentoDisplay: ""
                        }],
                        observacao: null
                    },
                });
            });
        }
        if (!props.isNew) {
            isFirstRenderRef.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isNew]);

    useEffect(() => {
        if (isFirstRenderRef.current && dadosPagamento.infoAdicional?.dadosGeneric?.length && dadosPagamento.infoAdicional.dadosGeneric[0].valor === faltaReceber) {
            isFirstRenderRef.current = false;
        }
        //eslint-disable-next-line
    }, [dadosPagamento]);

    const rateiaFaltaReceberEntreParcelas = (numeroParcela: number) => {
        if (dadosPagamento.infoAdicional) {
            const faltaReceberCentavos = faltaReceber * 100;
            const sobraCentavos = faltaReceberCentavos % numeroParcela;
            const valorRateadoCentavos = (faltaReceberCentavos - sobraCentavos) / numeroParcela;
            const ultimaParcelaCentavos = valorRateadoCentavos + sobraCentavos;
            const updatedDadosGeneric = dadosPagamento.infoAdicional.dadosGeneric!;

            for (let index = 0; index < numeroParcela; index++) {
                const isUltimaParcela = index === numeroParcela - 1;
                updatedDadosGeneric[index] = {
                    valor: (isUltimaParcela ? ultimaParcelaCentavos : valorRateadoCentavos) / 100,
                    vencimento: updatedDadosGeneric[index]?.vencimento ?? "",
                    vencimentoDisplay: updatedDadosGeneric[index]?.vencimentoDisplay ?? ""
                };
            }

            const updatedInfoAdicional = Object.assign({}, dadosPagamento.infoAdicional, {
                dadosGeneric: updatedDadosGeneric,
            });


            setDadosPagamento((prevDadosPagamento) => ({
                ...prevDadosPagamento,
                infoAdicional: updatedInfoAdicional,
            }));
        }
    };

    const handleChangeParcela = (value: number) => {
        if (value < 0 || value === 0 || Number.isNaN(value)) return;
        if (value > 12) {
            addError({ message: "O máximo de parcelas permitido são 12", closeable: true, id: "maxParcelasGenericFormasPagamentoError" });
            rateiaFaltaReceberEntreParcelas(12);
            return setDadosPagamento(prev => ({
                ...prev, infoAdicional: {
                    descritivo: "",
                    numeroParcelas: 12,
                    tipo: props.tipo,
                    dadosGeneric: prev.infoAdicional?.dadosGeneric,
                    observacao: prev.infoAdicional?.observacao
                }
            }));
        }

        rateiaFaltaReceberEntreParcelas(value);

        setDadosPagamento(prev => {
            return ({
                ...prev, infoAdicional: {
                    descritivo: '',
                    numeroParcelas: value,
                    tipo: props.tipo,
                    dadosGeneric: prev.infoAdicional?.dadosGeneric,
                    observacao: prev.infoAdicional?.observacao
                },
            });
        });
    };

    const handleInfoAdicionalObservacao = (observacao: string) => {
        if ((dadosPagamentoAsInfoAdicional)) {
            setDadosPagamento(prevDadosPagamento => ({
                ...prevDadosPagamento,
                infoAdicional: {
                    ...prevDadosPagamento.infoAdicional!,
                    observacao
                },
            }));
        }
    };

    const handleChangeValor = (index: number, valor: number = 0) => {
        if (valor > faltaReceber) {
            setHasError(true);
        } else {
            setHasError(false);
        }
        if ((dadosPagamentoAsInfoAdicional)) {
            const updatedDadosGeneric = [...(dadosPagamentoAsInfoAdicional).dadosGeneric!];

            if (index >= 0 && index < updatedDadosGeneric.length) {
                updatedDadosGeneric[index] = { ...updatedDadosGeneric[index], valor };

                setDadosPagamento(prevDadosPagamento => ({
                    ...prevDadosPagamento,
                    infoAdicional: {
                        ...prevDadosPagamento.infoAdicional!,
                        dadosGeneric: updatedDadosGeneric,
                    },
                }));
            }
        }
    };

    const somaGeneric = (infos: IInfoAdicional) => {
        let soma = 0;
        infos.dadosGeneric?.forEach((dado: TdadosGeneric) => {
            soma += dado.valor;
        });
        return soma;
    };

    const calcularNovaData = (dataAntiga: dayjs.Dayjs | Date) => {
        return dayjs(dataAntiga).add(1, 'month').toDate();
    };

    const handleTableRow = (numeroParcela: number) => {
        const dadosGeneric = Array.from((dadosPagamentoAsInfoAdicional).dadosGeneric ?? []);

        if (dadosGeneric.length > numeroParcela && numeroParcela > 0) {
            const parcelasExtra = dadosGeneric.length - numeroParcela;
            for (let i = 0; i < parcelasExtra; i++) {
                dadosGeneric.pop();
            }
        }

        for (let i = dadosGeneric.length; i < numeroParcela; i++) {
            dadosGeneric.push({
                valor: faltaReceber,
                vencimento: "",
                vencimentoDisplay: ""
            });
        }

        setDadosPagamento(prevDadosPagamento => ({
            ...prevDadosPagamento,
            infoAdicional: {
                ...prevDadosPagamento.infoAdicional!,
                dadosGeneric: dadosGeneric,
                observacao: prevDadosPagamento.infoAdicional?.observacao ?? null
            },
        }));
    };

    useEffect(() => {
        if (dadosPagamento.infoAdicional?.dadosGeneric?.length && !isFirstRenderRef.current) {
            const total = somaGeneric((dadosPagamentoAsInfoAdicional));
            setDadosPagamento(prev => ({ ...prev, valorRecebido: total }));
            faltaReceber - roundHalfToEven(total, 2) >= 0 ? setHasError(false) : setHasError(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(dadosPagamentoAsInfoAdicional)?.dadosGeneric]);

    useEffect(() => {
        if (dadosPagamento.infoAdicional?.dadosGeneric?.length && !isFirstRenderRef.current) {
            handleTableRow((dadosPagamentoAsInfoAdicional)?.numeroParcelas ?? 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(dadosPagamentoAsInfoAdicional)?.numeroParcelas]);


    const handleDatavencimento = (index: number, vencimentoDisplay: string) => {
        if ((dadosPagamentoAsInfoAdicional)) {
            const novosGeneric = [...(dadosPagamentoAsInfoAdicional).dadosGeneric!];
            if (index === 0) {
                novosGeneric[0] = { ...novosGeneric[0], vencimentoDisplay: vencimentoDisplay };
                let primeiraData = calcularNovaData(dayjs(vencimentoDisplay));

                for (let i = 1; i < novosGeneric.length; i++) {
                    novosGeneric[i] = { ...novosGeneric[i], vencimentoDisplay: primeiraData.toString() };
                    primeiraData = calcularNovaData(primeiraData);
                }
            } else {
                novosGeneric[index] = { ...novosGeneric[index], vencimentoDisplay: vencimentoDisplay };
            }

            for (let i = 0; i < novosGeneric.length; i++) {
                if (vencimentoDisplay.length === 58 || vencimentoDisplay.length === 29) {
                    novosGeneric[i] = { ...novosGeneric[i], vencimento: new Date(novosGeneric[i].vencimentoDisplay).toISOString() };
                }
            }

            setDadosPagamento((prevDadosPagamento) => ({
                ...prevDadosPagamento,
                infoAdicional: {
                    ...prevDadosPagamento.infoAdicional!,
                    dadosGeneric: novosGeneric,
                },
            }));
        }
    };

    return (
        <>
            <FormControl
                sx={{ width: '5rem' }}
            >
                <TextField
                    variant="standard"
                    inputProps={{
                        style: {
                            textAlign: 'center',
                            fontSize: '24px',
                            fontWeight: 700,
                            fontFamily: 'Work Sans, sans-serif'
                        },
                    }}
                    id={'parcelas'}
                    name='parcelas'
                    label="Parcelas"
                    type='number'
                    inputMode='numeric'
                    value={(dadosPagamentoAsInfoAdicional)?.numeroParcelas ?? 1}
                    onChange={e => { handleChangeParcela(parseInt(e.target.value)); }}
                />
            </FormControl>

            <Grid container >
                <TableContainer
                    sx={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: 1,
                        position: 'relative',
                        boxSizing: 'border-box',
                        height: '100%',
                        maxHeight: '320px',
                        minHeight: '320px',
                    }}
                    className="color-orange"
                >
                    <Table
                        id="content-table"
                        stickyHeader
                        sx={{
                            borderRadius: 1,
                        }}
                        size="small"
                    >

                        <TableHead>
                            <TableRow >
                                <TableCell sx={{ width: '3rem' }} align="center">
                                    Valor
                                </TableCell>

                                <TableCell sx={{ width: '3rem' }} align="center">
                                    Vencimento
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {dadosPagamento.infoAdicional && (dadosPagamentoAsInfoAdicional).dadosGeneric?.map((row, index) => (
                                <LinhasDadosGeneric
                                    row={row}
                                    index={index}
                                    handleChangeValor={handleChangeValor}
                                    handleDatavencimento={handleDatavencimento}
                                    hasError={hasError}
                                    key={index}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Container>
                    {hasError && <FormHelperText sx={{ textAlign: 'center', fontWeight: 600, fontSize: 15, color: '#c62828' }}>O valor não pode ser maior que a venda :(</FormHelperText>}
                    {!hasError && <FormHelperText sx={{ textAlign: 'center', fontWeight: 600, fontSize: 15 }}> </FormHelperText>}
                </Container>
            </Grid>

            <TextField
                label="Observação"
                multiline
                fullWidth
                value={dadosPagamento.infoAdicional?.observacao ?? ""}
                id="boleto-dadospagamento-infoadicional-observacao"
                onChange={(e) => handleInfoAdicionalObservacao(e.target.value)}
                inputProps={{ maxLength: 200 }}
                color='warning'
                focused
            />

            <FaltaReceberComponent
                valorRecebido={dadosPagamento.valorRecebido}
                tableRowsValue={tableRowsValue}
                isAbsolute
            />
        </>
    );
};

export default GenericOutrasFormasPagamento;
