import { Autocomplete, Box, Button, ButtonGroup, CircularProgress, ClickAwayListener, Container, DialogActions, DialogTitle, FormControl, FormHelperText, Grow, Icon, InputLabel, MenuItem, MenuList, Paper, Popper, Select, TextField } from "@mui/material"
import { FlowCreatePedidoCieloTO, FormaPagamentoDialogContentProps } from "../formapagamento.model"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { currencyOf, extrairNumeroInfoAdicionalDescritivo, isAtivo, roundHalfToEven } from "utils/util";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { IAbstractModel } from "model/abstract.model";
import CredenciadoraCard from "components/CredenciadoraCard";
import BandeiraCard from "components/BandeiraCard";
import { CustomButton } from "components/roundedbutton/CustomButton";
import { NumericFormat } from "react-number-format";
import { BandeiraCartaoTO, CredenciadoraTaxaTO, FormaPagamentoName, IPagamentoRow, TDadosCartao, TipoInformacaoAdicional } from "pages/pagamento/pagamento.model";
import NsuNumControle from "pages/pagamento/NsuNumControle";
import { useSnackbarCustom } from "hooks/useSnackCustom";
import RegistraVendaService from "pages/registra-venda/registra-venda.service";
import { addRow, setTempCartaoRow } from "redux/features/pagamentoSlice";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import FaltaReceberComponent from "components/FaltaReceberComponent";


const initialState = {
    telas: {
        credenciadora: true as boolean,
        hasIntegracao: false as boolean,
        bandeira: false as boolean,
        modoPagamento: false as boolean
    },
    credenciadoraSelecionada: null as IAbstractModel | null,
    bandeiraSelecionada: null as BandeiraCartaoTO | null,
    modoPagamentoSelecionado: null as CredenciadoraTaxaTO | null,
    filtroModoPagamento: "" as string,
    nsu: "" as string,
    numControle: "" as string,
    isLoadingToMaquininha: false as boolean,
    isInsertManual: false as boolean,
    isOpen: false as boolean,
    selectedIndex: 0 as number
}

const CartoesDialogContent: React.FC<FormaPagamentoDialogContentProps & {
    handleGenericFormaPagamento: (title: FormaPagamentoName, handle: boolean) => void, dadosPagamentoCartao: TDadosCartao
}> =
    ({ isNew, error, valorRecebido, onClose, dadosPagamentoCartao, formasPagamentoCartao, onSincronizarPagamento }) => {

        const { dadosPagamento, setDadosPagamento } = valorRecebido;
        const { faltaReceber, selectedRow } = useAppSelector(s => s.pagamento);
        const [stateLocal, setStateLocal] = useState(initialState);
        const { hasError, setHasError } = error;
        const registraVendaService = RegistraVendaService();
        const { addError } = useSnackbarCustom()
        const dispatch = useAppDispatch()
        const { uuid: uuidPedido } = useAppSelector(e => e.state.pedido)

        const options = ['Enviar pagamento', 'Inserir manualmente', "Atualizar pagamento"];
        const anchorRef = useRef<HTMLDivElement>(null);

        const handleMenuItemClick = useCallback((index: number) => {
            setStateLocal(prevState => ({
                ...prevState,
                selectedIndex: index,
                isOpen: false
            }))
            //eslint-disable-next-line
        }, []);

        const getDisabledOption = (index: number) => {
            if (index === 0 && !isNew) return true;
            if (index === 2 && isNew) return true
            return false
        }

        const handleClick = () => {
            if (stateLocal.selectedIndex === 0) return onEnviaMaquininha(dadosPagamento.valorRecebido, faltaReceber)
            if (stateLocal.selectedIndex === 2) {
                if (onClose) onClose("CARTOES");
                return onSincronizarPagamento!(selectedRow!.row)
            }
            return setStateLocal(prevState => ({
                ...prevState,
                isInsertManual: true,
                telas: {
                    ...prevState.telas,
                    hasIntegracao: false,
                    bandeira: true
                }
            }))
        };

        const handleToggle = () => {
            setStateLocal(prevState => ({ ...prevState, isOpen: !prevState.isOpen }))
        };

        const onClickAway = useCallback(() => {
            setStateLocal(prevState => ({ ...prevState, isOpen: false }))
            //eslint-disable-next-line
        }, [])

        useEffect(() => {
            if (dadosPagamento.infoAdicional && dadosPagamento.infoAdicional.descritivo === "AGUARDANDO PAGAMENTO") {
                setDadosPagamento(prevState => ({
                    ...prevState, infoAdicional: {
                        ...prevState.infoAdicional!,
                        descritivo: ""
                    }
                }))
                setTimeout(() => {
                    setStateLocal(prevState => ({
                        ...prevState,
                        selectedIndex: 1,
                        telas: {
                            ...prevState,
                            credenciadora: false,
                            bandeira: true,
                            modoPagamento: false,
                            hasIntegracao: false
                        }
                    }))
                }, 100);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])


        useEffect(() => {
            if (isNew) setDadosPagamento(prev => ({
                valorRecebido: faltaReceber - tableRowsValue,
                infoAdicional: undefined
            }));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const selectCredenciadora = useCallback((uuid: string) => {
            const credenciadora = dadosPagamentoCartao.credenciadoras.find(e => e.uuid === uuid);
            if (credenciadora) {
                setStateLocal(prevState => ({
                    ...prevState,
                    credenciadoraSelecionada: credenciadora,
                    telas: {
                        credenciadora: false,
                        hasIntegracao: isAtivo(credenciadora.status),
                        bandeira: !isAtivo(credenciadora.status),
                        modoPagamento: false
                    }
                }))
                setDadosPagamento(prev => ({
                    ...prev,
                    infoAdicional: {
                        ...prev.infoAdicional!,
                        dadosCartao: {
                            ...prev.infoAdicional?.dadosCartao!,
                            credenciadora: credenciadora
                        }
                    }
                }));
            }
            //eslint-disable-next-line
        }, [])

        const selectBandeira = useCallback((bandeiraCartaoTO: BandeiraCartaoTO) => {
            const bandeira = dadosPagamentoCartao.bandeiras
                .filter(e => e.credenciadoraUUID === stateLocal.credenciadoraSelecionada?.uuid)[0]?.bandeiras
                .find(e => e === bandeiraCartaoTO);
            if (bandeira) {
                setStateLocal(prevState => ({
                    ...prevState,
                    bandeiraSelecionada: bandeira,
                    telas: {
                        credenciadora: false,
                        bandeira: false,
                        hasIntegracao: false,
                        modoPagamento: true
                    }
                }))
                setDadosPagamento(prev => ({
                    ...prev,
                    infoAdicional: {
                        ...prev.infoAdicional!,
                        dadosCartao: {
                            ...prev.infoAdicional?.dadosCartao!,
                            bandeiraCartao: bandeira
                        }
                    }
                }));
            }
            //eslint-disable-next-line
        }, [stateLocal.credenciadoraSelecionada])


        const getDisabled = useCallback((credenciadoraSelecionada: IAbstractModel | null,
            bandeiraSelecionada: BandeiraCartaoTO | null,
            modoPagamentoSelecionado: CredenciadoraTaxaTO | null
        ) => {
            return !(credenciadoraSelecionada && bandeiraSelecionada && modoPagamentoSelecionado);
        }, [])

        const { tableRows } = useAppSelector(e => e.pagamento)

        const tableRowsValue = useMemo(() => {
            return tableRows.filter(e => !e.isPago).reduce((prev, act) => prev += act.valor, 0)
        }, [tableRows])


        const onEnviaMaquininha = (valorRecebido: number, faltaReceber: number) => {
            if (valorRecebido > faltaReceber) return addError({
                message: `O valor não pode ser maior que a venda :(`,
                closeable: true,
                id: "falta-receber-menor-que-zero"
            })
            setStateLocal(prevState => ({ ...prevState, isLoadingToMaquininha: true }))

            const flowCreatePedidoCieloTO: FlowCreatePedidoCieloTO = {
                valorEmCentavos: roundHalfToEven(dadosPagamento.valorRecebido * 100, 2),
                credenciadoraUUID: stateLocal.credenciadoraSelecionada!.uuid,
                uuidOrigem: !!uuidPedido ? uuidPedido : tableRows.filter(e => e.uuidOrigem)[0]?.uuidOrigem ?? null
            }
            registraVendaService.createFlowPagamentoCartao(flowCreatePedidoCieloTO)
                .then(({ data }) => {
                    const rowToAdd: IPagamentoRow = {
                        name: "CARTOES",
                        descritivoFormaPagamento: "CARTÃO".concat(" - ").concat(data.reference),
                        formaPagamento: {
                            uuid: formasPagamentoCartao![0].uuid,
                            descritivo: formasPagamentoCartao![0].descritivo
                        },
                        isEditavel: true,
                        isPago: false,
                        uuidOrigem: data.uuidOrigem,
                        credenciadoraUUID: data.credenciadoraUUID,
                        valor: dadosPagamento.valorRecebido,
                        infoAdicional: {
                            numeroParcelas: 1,
                            descritivo: "AGUARDANDO PAGAMENTO",
                            tipo: TipoInformacaoAdicional.CARTAO,
                            uuidOrigem: data.uuidOrigem,
                            credenciadoraUUID: data.credenciadoraUUID
                        },
                    }

                    dispatch(addRow(rowToAdd))
                    dispatch(setTempCartaoRow(rowToAdd))
                    setDadosPagamento(prevstate => ({ ...prevstate, valorRecebido: prevstate.valorRecebido - rowToAdd.valor }))
                    setStateLocal(prevState => ({ ...prevState, isLoadingToMaquininha: false }))
                    if (onClose) onClose("CARTOES");
                }).catch((err) => {
                    if (err.response.data.description) {
                        const errMessage = err.response.data.description
                        addError({ id: `${errMessage}_error`, message: errMessage, closeable: true, preventDuplicate: true })
                    }
                    setStateLocal(prevState => ({ ...prevState, isLoadingToMaquininha: false }))
                })
        }

        const onGoBack = () => {
            if (stateLocal.telas.credenciadora && onClose) onClose("CARTOES");
            if (stateLocal.telas.hasIntegracao) {
                return setStateLocal(prevState => ({
                    ...prevState,
                    credenciadoraSelecionada: null,
                    telas: {
                        credenciadora: true,
                        bandeira: false,
                        modoPagamento: false,
                        hasIntegracao: false
                    },
                }))
            }
            if (stateLocal.telas.bandeira) {
                setStateLocal(prevState => ({
                    ...prevState,
                    credenciadoraSelecionada: prevState.isInsertManual ? prevState.credenciadoraSelecionada : null,
                    telas: {
                        credenciadora: prevState.isInsertManual ? false : true,
                        bandeira: false,
                        modoPagamento: false,
                        hasIntegracao: prevState.isInsertManual ? true : false
                    },
                    isInsertManual: !prevState.isInsertManual,
                    selectedIndex: 0
                }))
            }
            if (stateLocal.telas.modoPagamento) {
                setStateLocal(prevState => ({
                    ...prevState,
                    bandeiraSelecionada: null,
                    telas: {
                        credenciadora: false,
                        bandeira: true,
                        modoPagamento: false,
                        hasIntegracao: false
                    },
                    modoPagamentoSelecionado: null,
                    filtroModoPagamento: "",
                    nsu: '',
                    numControle: ''
                }))
            }
        }

        const getModoPagamentos = () => {
            const modosPagamento = dadosPagamentoCartao.modosPagamento
                .find(e => e.credenciadoraUUID === stateLocal.credenciadoraSelecionada?.uuid
                    && e.bandeiraUUID === stateLocal.bandeiraSelecionada?.uuidDadoCredenciadora
                )?.modosPagamentos

            return (modosPagamento && modosPagamento?.filter(e => e.modoPagamento.toUpperCase().includes(stateLocal.filtroModoPagamento.toUpperCase()))) ?? []
            //eslint-disable-next-line
        }

        const isCreditoParcelado = useCallback((modoPagamento?: string): boolean => {
            if (!modoPagamento || modoPagamento === "DÉBITO" || modoPagamento === "CRÉDITO A VISTA" || modoPagamento === "PIX") return false
            return true
        }, [])


        useEffect(() => {
            if (stateLocal.isInsertManual) {
                setTimeout(() => {
                    setStateLocal(prevState => ({
                        ...prevState,
                        telas: {
                            ...prevState.telas,
                            hasIntegracao: false,
                            bandeira: true
                        }
                    }))
                }, 500);
            }
        }, [stateLocal.isInsertManual])

        const onChangeValorRecebido = (value: number = 0) => {
            setDadosPagamento(prev => ({
                ...prev,
                valorRecebido: value,
                infoAdicional: {
                    ...prev.infoAdicional!,
                    numeroParcelas: extrairNumeroInfoAdicionalDescritivo(prev.infoAdicional!.dadosCartao?.modoPagamento?.modoPagamento ?? null) ?? 1,
                    tipo: prev.infoAdicional?.tipo ?? TipoInformacaoAdicional.CARTAO,
                    descritivo: isCreditoParcelado(prev.infoAdicional?.dadosCartao?.modoPagamento?.modoPagamento) ? getParcelasDescritivo(
                        extrairNumeroInfoAdicionalDescritivo(
                            prev.infoAdicional?.dadosCartao?.modoPagamento?.modoPagamento ?? null
                        ) ?? 1,
                        value
                    ) : "",
                }
            }));
            if (roundHalfToEven(value, 2) > roundHalfToEven(faltaReceber, 2)) {
                setHasError(true);
            } else {
                setHasError(false);
            }
        };

        const getParcelasValue = (): number | '' => {
            const parcelas = dadosPagamento.infoAdicional?.numeroParcelas ?? 1;
            return parcelas;
        };

        const onChangePrazos = (modoPagamento: CredenciadoraTaxaTO | null) => {
            if (!modoPagamento) return;

            setDadosPagamento(prev => ({
                ...prev,
                infoAdicional: {
                    ...prev.infoAdicional!,
                    numeroParcelas: extrairNumeroInfoAdicionalDescritivo(modoPagamento.modoPagamento) ?? 1,
                    tipo: prev.infoAdicional?.tipo!,
                    descritivo: isCreditoParcelado(modoPagamento.modoPagamento) ?
                        getParcelasDescritivo(extrairNumeroInfoAdicionalDescritivo(modoPagamento.modoPagamento) ?? 1, dadosPagamento.valorRecebido)
                        : "",
                    dadosCartao: {
                        ...prev.infoAdicional?.dadosCartao!,
                        modoPagamento: modoPagamento,
                    }
                }
            }));
            //eslint-disable-next-line
        }

        const getParcelasDescritivo = useCallback((parcela: number, valorRecebido: number) => {
            return `${parcela}x - ${currencyOf(valorRecebido / parcela)}`;
        }, []);

        const changeNsu = useCallback((value: string) => {
            setStateLocal(prevState => ({ ...prevState, nsu: value }))
        }, [])

        const changeNumControle = useCallback((value: string) => {
            setStateLocal(prevState => ({ ...prevState, numControle: value }))
        }, [])

        useEffect(() => {
            setDadosPagamento(prev => ({
                ...prev,
                infoAdicional: {
                    descritivo: prev.infoAdicional?.descritivo ?? "",
                    numeroParcelas: prev.infoAdicional?.numeroParcelas ?? 1,
                    tipo: prev.infoAdicional?.tipo ?? TipoInformacaoAdicional.CARTAO,
                    dadosCartao: {
                        ...prev.infoAdicional?.dadosCartao!,
                        nsu: stateLocal.nsu,
                        numeroControle: stateLocal.numControle
                    }
                }
            })
            );
            //eslint-disable-next-line
        }, [stateLocal.nsu, stateLocal.numControle])


        useEffect(() => {
            if (!stateLocal.modoPagamentoSelecionado) {
                setDadosPagamento(prev => ({
                    ...prev,
                    infoAdicional: {
                        descritivo: prev.infoAdicional?.descritivo ?? "",
                        numeroParcelas: prev.infoAdicional?.numeroParcelas ?? 1,
                        tipo: TipoInformacaoAdicional.CARTAO,
                        dadosCartao: prev.infoAdicional?.dadosCartao
                    }
                })
                );
            }
            //eslint-disable-next-line
        }, [stateLocal.modoPagamentoSelecionado])

        useEffect(() => {
            if (isNew) {
                return setDadosPagamento(prev => ({
                    valorRecebido: faltaReceber - tableRowsValue,
                    infoAdicional: {
                        numeroParcelas: 1,
                        descritivo: '',
                        tipo: TipoInformacaoAdicional.CARTAO,
                        dadosCartao: {
                            nsu: null,
                            numeroControle: null,
                            bandeiraCartao: null,
                            credenciadora: null,
                            modoPagamento: null,
                        }
                    }
                }));
            }

            const credenciadoraSelecionada = dadosPagamentoCartao.credenciadoras.find((e) => e.uuid === selectedRow?.row.credenciadoraUUID)
                ?? dadosPagamento.infoAdicional?.dadosCartao?.credenciadora

            return setStateLocal(prevState => ({
                ...prevState,
                nsu: dadosPagamento.infoAdicional?.dadosCartao?.nsu ?? "",
                numControle: dadosPagamento.infoAdicional?.dadosCartao?.numeroControle ?? "",
                credenciadoraSelecionada: credenciadoraSelecionada ?? null,
                bandeiraSelecionada: dadosPagamento.infoAdicional?.dadosCartao?.bandeiraCartao ?? null,
                modoPagamentoSelecionado: dadosPagamento.infoAdicional?.dadosCartao?.modoPagamento ?? null,
                telas: {
                    credenciadora: isNew || (!isNew && !credenciadoraSelecionada) ? true : false,
                    bandeira: !isNew && (credenciadoraSelecionada && !selectedRow?.row.uuidOrigem) ? true : false,
                    modoPagamento: isNew ? true : false,
                    hasIntegracao: (!isNew && credenciadoraSelecionada && selectedRow?.row.uuidOrigem) ? true : false
                },
                selectedIndex: 2
            }))
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const getScreenTitle = useCallback(() => {
            if (stateLocal.isLoadingToMaquininha) return "Enviando o pedido para a máquininha";
            if (stateLocal.telas.credenciadora) return "Selecione a credenciadora";
            if (stateLocal.telas.hasIntegracao) return "Informe o valor para enviar a máquininha";
            if (stateLocal.telas.bandeira) return "Selecione a bandeira";
            if (stateLocal.telas.modoPagamento) return "Selecione a forma de pagamento";
            return "Sem credenciadoras ativas";
            //eslint-disable-next-line
        }, [stateLocal.telas, stateLocal.isLoadingToMaquininha])

        return (
            <>
                <DialogTitle
                    sx={{
                        whiteSpace: 'nowrap',
                        fontSize: "24px",
                        fontWeight: 'bold',
                        color: '#666666',
                        userSelect: "none"
                    }}
                >{getScreenTitle()}
                </DialogTitle>
                <FormControl
                    fullWidth
                    variant="standard"
                    sx={{
                        width: '50rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    error={false}
                >
                    {stateLocal.telas.credenciadora &&
                        <Container sx={{
                            display: 'grid',
                            gridTemplateColumns: "repeat(3,1fr)",
                            maxHeight: '35vh',
                            overflow: 'auto'
                        }}>
                            {dadosPagamentoCartao.credenciadoras.map((credenciadora) => {
                                return (
                                    <CredenciadoraCard
                                        key={credenciadora.uuid}
                                        credenciadora={credenciadora}
                                        onSelectCredenciadora={selectCredenciadora}
                                        credenciadoraSelecionada={stateLocal.credenciadoraSelecionada}
                                    />
                                );
                            })}
                        </Container>
                    }
                    {stateLocal.telas.hasIntegracao &&
                        <Container sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',

                        }}>
                            <Box sx={{
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>

                                <CredenciadoraCard
                                    key={stateLocal.credenciadoraSelecionada?.uuid}
                                    credenciadora={stateLocal.credenciadoraSelecionada!}
                                    onSelectCredenciadora={selectCredenciadora}
                                    credenciadoraSelecionada={stateLocal.credenciadoraSelecionada}
                                />
                                <FormHelperText
                                    sx={{
                                        fontSize: '24px',
                                        marginTop: '20px',
                                        textAlign: 'center'
                                    }} disabled={!isNew}>
                                    Valor
                                </FormHelperText>
                                <NumericFormat sx={{
                                    width: '250px'
                                }}
                                    id="generic_forma_pagamento_div"
                                    value={dadosPagamento.valorRecebido}
                                    onValueChange={({ floatValue }) => onChangeValorRecebido(floatValue)}
                                    disabled={stateLocal.isLoadingToMaquininha || !isNew}
                                    error={hasError}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    defaultValue={0}
                                    allowedDecimalSeparators={[',']}
                                    customInput={TextField}
                                    decimalSeparator=","
                                    prefix={'R$ '}
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator="."
                                    variant="standard"
                                    allowNegative={false}
                                    inputProps={{
                                        id: "generic_forma_pagamento_value",
                                        style: {
                                            textAlign: 'center',
                                            fontSize: '24px',
                                            color: hasError ? 'var(--error)' : '#df6e0b',
                                            fontWeight: 700,
                                            fontFamily: 'Work Sans, sans-serif'
                                        }
                                    }}
                                />
                                {hasError &&
                                    <FormHelperText
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            color: 'var(--error)'
                                        }}
                                    >
                                        O valor não pode ser maior que a venda :(
                                    </FormHelperText>
                                }
                                <FaltaReceberComponent
                                    valorRecebido={dadosPagamento.valorRecebido}
                                    tableRowsValue={tableRowsValue}
                                    marginTop='30px'
                                />
                                {/* <Icon
                                    className="fa-solid fa-chevron-down"
                                    onClick={() => { }}
                                /> */}


                            </Box>
                        </Container>
                    }

                    {stateLocal.telas.bandeira &&
                        <Container sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <CredenciadoraCard
                                key={stateLocal.credenciadoraSelecionada?.uuid}
                                credenciadora={stateLocal.credenciadoraSelecionada!}
                                onSelectCredenciadora={selectCredenciadora}
                                credenciadoraSelecionada={stateLocal.credenciadoraSelecionada}
                            />
                            <Icon className="fa-solid fa-chevron-right" sx={{
                                fontSize: '50px',
                                color: "lightgray",
                                position: 'absolute',
                                left: '45%'
                            }} />
                            <BandeiraCard
                                key={stateLocal.bandeiraSelecionada?.uuidDadoCredenciadora}
                                credenciadoraSelecionada={stateLocal.credenciadoraSelecionada!}
                                bandeiras={dadosPagamentoCartao.bandeiras}
                                bandeiraSelecionada={stateLocal.bandeiraSelecionada}
                                onSelectBandeira={selectBandeira}
                            />
                        </Container>
                    }

                    {stateLocal.telas.modoPagamento &&
                        <>
                            <Container sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>

                                <CredenciadoraCard
                                    key={stateLocal.credenciadoraSelecionada?.uuid}
                                    credenciadora={stateLocal.credenciadoraSelecionada!}
                                    onSelectCredenciadora={selectCredenciadora}
                                    credenciadoraSelecionada={stateLocal.credenciadoraSelecionada}
                                />
                                <Icon className="fa-solid fa-chevron-right"
                                    sx={{
                                        color: "lightgray",
                                        fontSize: '40px',
                                        paddingLeft: '10px',
                                        paddingRight: '10px'
                                    }} />
                                <BandeiraCard
                                    key={stateLocal.bandeiraSelecionada?.uuidDadoCredenciadora}
                                    credenciadoraSelecionada={stateLocal.credenciadoraSelecionada!}
                                    bandeiras={dadosPagamentoCartao.bandeiras}
                                    bandeiraSelecionada={stateLocal.bandeiraSelecionada}
                                    onSelectBandeira={selectBandeira}
                                />
                            </Container>
                            <FormHelperText sx={{
                                fontSize: '24px',
                                marginTop: '20px'
                            }}>
                                Valor
                            </FormHelperText>
                            <NumericFormat
                                id="generic_forma_pagamento_div"
                                value={dadosPagamento.valorRecebido}
                                onValueChange={({ floatValue }) => onChangeValorRecebido(floatValue)}
                                error={hasError}
                                decimalScale={2}
                                fixedDecimalScale
                                defaultValue={0}
                                allowedDecimalSeparators={[',']}
                                customInput={TextField}
                                decimalSeparator=","
                                prefix={'R$ '}
                                thousandsGroupStyle="thousand"
                                thousandSeparator="."
                                variant="standard"
                                allowNegative={false}
                                inputProps={{
                                    id: "generic_forma_pagamento_value",
                                    style: {
                                        textAlign: 'center',
                                        fontSize: '24px',
                                        color: hasError ? 'var(--error)' : '#df6e0b',
                                        fontWeight: 700,
                                        fontFamily: 'Work Sans, sans-serif'
                                    }
                                }}
                            />
                            {hasError &&
                                <FormHelperText
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        color: 'var(--error)'
                                    }}
                                >
                                    O valor não pode ser maior que a venda :(
                                </FormHelperText>}

                            <Box sx={{
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'row',
                                justifyContent: 'space-evenly',
                                marginTop: '40px'
                            }}>
                                <Autocomplete sx={{
                                    width: '40%'
                                }}
                                    value={stateLocal.modoPagamentoSelecionado}
                                    id="acModoPagamento"
                                    options={getModoPagamentos()}
                                    getOptionLabel={(option) => option.modoPagamento}
                                    onInputChange={(e, value) => {
                                        setStateLocal(prevState => ({
                                            ...prevState,
                                            filtroModoPagamento: value
                                        }))
                                    }}
                                    filterOptions={(x) => x}
                                    noOptionsText={'Nenhum resultado encontrado'}
                                    onChange={(_, modoPagamento) => {
                                        onChangePrazos(modoPagamento)
                                        setStateLocal(prevState => ({
                                            ...prevState,
                                            modoPagamentoSelecionado: modoPagamento
                                        }))
                                    }}
                                    isOptionEqualToValue={(option, value) => option.uuidCredenciadoraTaxa === value.uuidCredenciadoraTaxa && option.modoPagamento === value.modoPagamento}
                                    renderInput={
                                        (params) => <TextField
                                            {...params}
                                            label={`Modo pagamento *`}
                                            variant="standard"
                                        />}
                                />
                                <FormControl fullWidth variant="standard" sx={{ width: '15rem' }} error={false}>
                                    <InputLabel id="prazos-label">Parcelas</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="parcelas_select"
                                        value={getParcelasValue()}
                                        disabled
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 48 * 4.5 + 8,
                                                    width: 100
                                                },
                                            },
                                        }}
                                    >
                                        {Array.from([].constructor(12)).map((s, index) => {
                                            const parcela = ++index;
                                            return (
                                                <MenuItem
                                                    id={`menu_item_outros_${index}`}
                                                    key={index}
                                                    value={parcela}
                                                >
                                                    {getParcelasDescritivo(parcela, dadosPagamento.valorRecebido)}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                            <FormControl fullWidth variant="standard" sx={{ width: '100%' }} error={false}>
                                <NsuNumControle
                                    nsu={stateLocal.nsu}
                                    numControle={stateLocal.numControle}
                                    onChangeNsu={changeNsu}
                                    onChangeNumControle={changeNumControle}
                                />
                            </FormControl>
                        </>
                    }
                </FormControl>

                <DialogActions
                    sx={{
                        justifyContent: 'space-between',
                        width: '98%'
                    }}>
                    <CustomButton sx={{
                        width: stateLocal.telas.hasIntegracao ? '214.58px' : undefined,
                        height: stateLocal.telas.hasIntegracao ? "55px" : undefined
                    }}
                        onClick={onGoBack}
                        variant="contained"
                        color="error"
                        startIcon={<Icon className={!stateLocal.credenciadoraSelecionada ? "fa-solid fa-close" : "fa-solid fa-arrow-left"} />
                        }
                    >
                        {!stateLocal.credenciadoraSelecionada ? "Cancelar" : "Voltar"}
                    </CustomButton>
                    {stateLocal.telas.hasIntegracao &&
                        <>
                            <ButtonGroup
                                variant="contained"
                                ref={anchorRef}
                                aria-label="Button group with a nested menu"
                                sx={{
                                    height: '55px',
                                    '.MuiButtonGroup-firstButton': {
                                        borderColor: "#FFF"
                                    },
                                    '.MuiButtonGroup-middleButton': {
                                        borderColor: "#FFF"
                                    },
                                }}
                            >
                                {options[stateLocal.selectedIndex] === options[0] &&
                                    <Box sx={{
                                        height: '55px',
                                        bgcolor: 'green',
                                        alignItems: 'center',
                                        display: 'flex'
                                    }}>
                                        <img
                                            alt="Máquina de cartão"
                                            src={require("../../../../assets/cardMachine.png")}
                                            style={{
                                                width: "40px",
                                                height: "40px"
                                            }}
                                        />
                                    </Box>
                                }
                                <Button
                                    color="success"
                                    onClick={handleClick}
                                    href=""
                                    sx={{
                                        width: stateLocal.isLoadingToMaquininha ? '120px' : undefined
                                    }}
                                >
                                    {!stateLocal.isLoadingToMaquininha && options[stateLocal.selectedIndex]}
                                    {stateLocal.isLoadingToMaquininha && !selectedRow?.row &&
                                        < CircularProgress
                                            size={24}
                                            color='warning'
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px'
                                            }}
                                        />
                                    }
                                </Button>
                                <Button
                                    size="small"
                                    color="success"
                                    aria-controls={stateLocal.isOpen ? 'split-button-menu' : undefined}
                                    aria-expanded={stateLocal.isOpen ? 'true' : undefined}
                                    aria-label="select merge strategy"
                                    aria-haspopup="menu"
                                    onClick={handleToggle}
                                >
                                    <ArrowDropDownIcon />
                                </Button>
                            </ButtonGroup>

                            <Popper
                                sx={{ zIndex: 1 }}
                                open={stateLocal.isOpen}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                transition
                                disablePortal
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin:
                                                placement === 'bottom' ? 'center top' : 'center bottom',
                                        }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={onClickAway}>
                                                <MenuList id="split-button-menu" autoFocusItem>
                                                    {options.map((option, index) => (
                                                        <MenuItem
                                                            key={option}
                                                            selected={index === stateLocal.selectedIndex}
                                                            disabled={getDisabledOption(index)}
                                                            onClick={() => handleMenuItemClick(index)}
                                                        >
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </>

                    }
                    {stateLocal.telas.modoPagamento &&
                        <FaltaReceberComponent
                            valorRecebido={dadosPagamento.valorRecebido}
                            tableRowsValue={tableRowsValue}
                        />
                    }
                    {!stateLocal.telas.hasIntegracao &&
                        <CustomButton type="submit"
                            disabled={getDisabled(stateLocal.credenciadoraSelecionada, stateLocal.bandeiraSelecionada, stateLocal.modoPagamentoSelecionado)}
                            variant="contained"
                            color="success"
                            startIcon={<Icon className='fa-solid fa-check' />}
                        >
                            Confirmar
                        </CustomButton>
                    }

                </DialogActions>

            </>

        )
    }

export default CartoesDialogContent