import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { Document, PDFViewer, Page, Text, View } from '@react-pdf/renderer';
import { mascaraCpfCnpj } from 'pages/cpfNota/CpfNota';
import { useEffect, useState } from 'react';
import { currencyOf, roundHalfToEven, showOnlyDate } from 'utils/util';
import RenderComprovanteRow from './RenderComprovanteRow';
import { IPdfComprovanteTemplate, TDadosPedido, TDadosPessoa } from './comprovante-nota-fiscal-outros.model';
import ComprovanteNFOutrosService from './comprovante-nota-fiscal-outros.service';
import { branco, s } from './comprovanteCss';

const initialState = {
    cnpj: '' as string,
    inscricaoEstadual: '' as string,
    enderecoCompleto: '' as string,
    cidadeEstado: '' as string,
    cliente: '' as string,
    documentoNumero: 0 as number,
    emissao: '' as string,
    observacao: '' as string,
};

const PdfComprovanteTemplate = (props: IPdfComprovanteTemplate) => {
    const itensQtd = props.flowVendaTO.itens.reduce((prev, current) => prev + (current.quantidade), 0);
    const [stateLocal, setStateLocal] = useState(initialState);
    const comprovanteService = ComprovanteNFOutrosService();

    useEffect(() => {
        let dadosPessoa: TDadosPessoa = {
            cidadeEstado: '',
            cnpj: '',
            enderecoCompleto: '',
            inscricaoEstadual: ''
        };
        let dadosPedido: TDadosPedido = {
            cliente: '',
            documentoNumero: 0,
            emissao: '',
            observacao: '',
            pedidoNumero: 0
        };

        comprovanteService.getDadosPessoa()
            .then(({ data }: { data: TDadosPessoa; }) => {
                dadosPessoa = data;
                return comprovanteService.getDadosPedido(props.flowVendaTO.numero);
            }).then(({ data }: { data: TDadosPedido; }) => {
                dadosPedido = data;
            }).catch(() => {
            }).finally(() => {
                if (dadosPessoa.cnpj) {
                    setStateLocal(prevState => ({
                        ...prevState,
                        cnpj: dadosPessoa.cnpj,
                        inscricaoEstadual: dadosPessoa.inscricaoEstadual,
                        enderecoCompleto: dadosPessoa.enderecoCompleto,
                        cidadeEstado: dadosPessoa.cidadeEstado,
                        cliente: dadosPedido.cliente ? dadosPedido.cliente : 'CONSUMIDOR',
                        emissao: dadosPedido.emissao,
                        observacao: dadosPedido.observacao ? dadosPedido.observacao : '',
                        documentoNumero: dadosPedido.documentoNumero,
                    }));
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFormasPamento = (): Array<{ descritivo: string, valor: number, parcelas: number; }> => {
        if (!props.isPedidoRecuperado) {
            return props.flowVendaTO.itensPagamento.map(item => {
                return {
                    descritivo: item.descritivoFormaPagamento.toUpperCase(),
                    valor: item.valorOriginal ?? item.valor,
                    parcelas: item.infoAdicional?.numeroParcelas ?? 1,
                };
            });
        }

        const formasPagamentoObj = _getGroupedFormasPagamento();
        return Object.keys(formasPagamentoObj).map(key => {
            const item = formasPagamentoObj[key];
            return {
                descritivo: key,
                valor: item.valor,
                parcelas: item.qtdParcelas
            };
        });
    };

    const _getGroupedFormasPagamento = () => {
        const toReturn = {} as any;
        for (let item of props.flowVendaTO.itensPagamento) {
            const descritivo = item.descritivoFormaPagamento.toUpperCase();

            if (!toReturn[descritivo]) {
                toReturn[descritivo] = {
                    valor: item.valor,
                    qtdParcelas: 1,
                };
            } else {
                const oldItem = toReturn[descritivo];
                toReturn[descritivo] = {
                    valor: oldItem.valor + item.valor,
                    qtdParcelas: oldItem.qtdParcelas + 1
                };
            }
        }
        return toReturn;
    };

    const isPacelavel = (descritivo: string) => {
        return descritivo === 'OUTROS'
            || descritivo === 'CARTÃO DE CRÉDITO'
            || descritivo === 'CHEQUE';
    };

    const _possuiBrinde = () => {
        return props.flowVendaTO.itens.some(s => s.isBrinde);
    }

    const _getValorDesconto = () => {
        const pedido = props.flowVendaTO;
        const desconto = pedido.pedidoDesconto;
        let toReturn = desconto.valorDesconto;
        if (_possuiBrinde()) {
            toReturn = pedido.valorTotalBruto * (desconto.percentualDesconto / 100);
        }
        return toReturn;
    }

    const _getValorTotalLiquido = () => {
        const pedido = props.flowVendaTO;
        let toReturn = pedido.valorTotalLiquido;
        if (_possuiBrinde()) {
            toReturn = pedido.valorTotalBruto - _getValorDesconto()
        }
        return toReturn ?? 0;
    }

    return (
        <>
            <IconButton
                sx={{
                    zIndex: 100000,
                    position: 'absolute',
                    bottom: 20,
                    right: 30,
                    backgroundColor: 'var(--laranja)',
                    ':hover': {
                        backgroundColor: 'var(--laranja)',
                        filter: 'grayscale(.2) brightness(.8) contrast(1.5)'
                    }
                }}
                onClick={props.onCloseComprovante}
            >
                <CloseIcon sx={{ color: '#fff', fontSize: 45 }} />
            </IconButton>

            <PDFViewer
                style={s.pdfView}
                showToolbar={true}
            >
                <Document
                    title='comprovante-não-fiscal'
                    author={''}
                    language='pt-BR'
                >
                    <Page
                        size={'A7'}
                        orientation='portrait'
                        wrap={false}
                        style={s.page}
                    >
                        <View style={{ ...s.tableRow }} fixed>
                            <View >
                                <View style={{ display: "flex", alignItems: 'center', flexDirection: 'column', borderBottom: "1px solid black", paddingBottom: '5px', paddingTop: '5px', marginHorizontal: '10px' }}>
                                    <Text id="comprovante-nao-fiscal-pessoa-descritivo" style={{ fontSize: '8px', fontWeight: 'medium' }}>{props.descritivo.substring(8)}</Text>
                                    <Text id="comprovante-nao-fiscal-pessoa-cnpj" style={{ fontSize: '8px' }}>CNPJ: {mascaraCpfCnpj(stateLocal.cnpj)} </Text>
                                    <Text id="comprovante-nao-fiscal-pessoa-inscricao-estadual" style={{ fontSize: '8px' }}>I.E {stateLocal.inscricaoEstadual} </Text>
                                    <Text id="comprovante-nao-fiscal-pessoa-endereco-completo" style={{ fontSize: '8px' }}>{stateLocal.enderecoCompleto}</Text>
                                    <Text id="comprovante-nao-fiscal-pessoa-cidade-estado" style={{ fontSize: '8px' }}>{stateLocal.cidadeEstado}</Text>
                                </View>
                                <View style={{ display: "flex", alignItems: 'center', flexDirection: 'column', borderBottom: "1px solid black", paddingBottom: '5px', paddingTop: '5px', marginHorizontal: '10px' }}>
                                    <Text id="comprovante-nao-fiscal-info-0" style={{ fontSize: '8px' }}>CÓPIA DE PEDIDO</Text>
                                    <Text id="comprovante-nao-fiscal-pessoa-info-1" style={{ fontSize: '8px' }}>DOCUMENTO NÃO FISCAL</Text>
                                </View>
                                <View style={{ paddingHorizontal: '10px' }}>
                                    <View style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', paddingTop: '5px' }}>
                                        <Text id="comprovante-nao-fiscal-header-produto" style={{ fontSize: '8px', fontWeight: 'bold' }}>PRODUTO</Text>
                                        <Text id="comprovante-nao-fiscal-header-descricao" style={{ fontSize: '8px', fontWeight: 'bold' }}>DESCRIÇÃO</Text>
                                        <Text id="comprovante-nao-fiscal-header-traco-0" style={{ fontSize: '8px', fontWeight: 'bold', color: branco }}>------</Text>
                                        <Text id="comprovante-nao-fiscal-header-traco-1" style={{ fontSize: '8px', fontWeight: 'bold', color: branco }}>-------</Text>
                                    </View>
                                    <View style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                        <Text id="comprovante-nao-fiscal-header-detalhe-qtd" style={{ fontSize: '8px', fontWeight: 'bold' }}>QTD</Text>
                                        <Text id="comprovante-nao-fiscal-header-detalhe-valor-unitario" style={{ fontSize: '8px', fontWeight: 'bold' }}>VL UNIT</Text>
                                        <Text id="comprovante-nao-fiscal-header-detalhe-descricao" style={{ fontSize: '8px', fontWeight: 'bold' }}>DESC</Text>
                                        <Text id="comprovante-nao-fiscal-header-detalhe-total" style={{ fontSize: '8px', fontWeight: 'bold' }}>TOTAL</Text>
                                    </View>
                                    <View >
                                        <Text id="comprovante-nao-fiscal-separador-0" style={{ fontSize: '8px' }}>--------------------------------------------------------------------------</Text>
                                        {props.flowVendaTO && props.flowVendaTO.itens.map((e, i) => {
                                            return (
                                                <RenderComprovanteRow key={i}
                                                    index={i}
                                                    elemento={e}
                                                    length={props.flowVendaTO.itens.length ?? 0}
                                                    isNew={props.showPopUp}
                                                    valorDescontoPedido={props.flowVendaTO.pedidoDesconto.valorDesconto ?? 0}
                                                />
                                            );
                                        })}
                                        <Text id="comprovante-nao-fiscal-separador-1" style={{ fontSize: '8px' }}>--------------------------------------------------------------------------</Text>
                                        <View style={{ width: '100%' }}>
                                            <View style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                                                <Text style={{ fontSize: '8px' }}>QTD TOTAL DE ITENS</Text>
                                                <Text style={{ fontSize: '8px' }}>{itensQtd}</Text>
                                            </View>
                                            <View style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                                                <Text style={{ fontSize: '8px' }}>VALOR TOTAL</Text>
                                                <Text style={{ fontSize: '8px' }}>{currencyOf((props.flowVendaTO.valorTotalBruto ?? 0))}</Text>
                                            </View>
                                            <View style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                                                <Text style={{ fontSize: '8px' }}>DESCONTO</Text>
                                                <Text style={{ fontSize: '8px' }}>{currencyOf(_getValorDesconto())}</Text>
                                            </View>
                                            <View style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                                                <Text style={{ fontSize: '8px' }}>VALOR À PAGAR</Text>
                                                <Text style={{ fontSize: '8px' }}>{currencyOf(_getValorTotalLiquido())}</Text>
                                            </View>
                                        </View>
                                        <Text id="comprovante-nao-fiscal-separador-2" style={{ fontSize: '8px' }}>--------------------------------------------------------------------------</Text>
                                        <View>
                                            <Text style={{ fontSize: '8px', fontWeight: 'bold' }}>Forma de pagamento</Text>
                                            {props.flowVendaTO && getFormasPamento().map((item, i) => {
                                                return (
                                                    <View key={i} id={`forma_pagamento_${item.descritivo}_${i}`}
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            flexDirection: 'row'
                                                        }}>
                                                        <Text id={`forma_pagamento_${item.descritivo}_${i}_descritivo`} style={{ fontSize: '8px' }}>
                                                            {item.descritivo}
                                                            <Text style={{ color: isPacelavel(item.descritivo) ? '#000' : 'transparent' }}>
                                                                {' ' + item.parcelas + 'x'}
                                                            </Text>
                                                        </Text>
                                                        <Text id={`forma_pagamento_${item.descritivo}_${i}_valor`} style={{ fontSize: '8px' }}>{currencyOf(roundHalfToEven(item.valor, 2))}</Text>
                                                    </View>
                                                );
                                            })}
                                        </View>
                                        <Text id="comprovante-nao-fiscal-separador-3" style={{ fontSize: '8px' }}>--------------------------------------------------------------------------</Text>
                                        <View style={{ marginTop: '5px' }}>
                                            <Text id="comprovante-nao-fiscal-pedido-cliente" style={{ fontSize: '8px' }}>Cliente: {stateLocal.cliente} </Text>
                                            <Text id="comprovante-nao-fiscal-pedido-numero-pedido" style={{ fontSize: '8px' }}>Pedido número: {props.flowVendaTO.numero}</Text>
                                            {stateLocal.documentoNumero &&
                                                <Text id="comprovante-nao-fiscal-pedido-numero-documento" style={{ fontSize: '8px' }}>Documento número: {stateLocal.documentoNumero} </Text>
                                            }
                                            <Text id="comprovante-nao-fiscal-emissao" style={{ fontSize: '8px' }}>Emissão: {stateLocal.emissao ? showOnlyDate(stateLocal.emissao) : props.flowVendaTO.emissao ? showOnlyDate(props.flowVendaTO.emissao.toString()) : stateLocal.emissao}</Text>
                                            {stateLocal.observacao &&
                                                <Text id="comprovante-nao-fiscal-pedido-observacoes" style={{ fontSize: '8px' }}>Observações: {stateLocal.observacao}</Text>
                                            }
                                            <Text id="comprovante-nao-fiscal-pedido-observacoes" style={{ fontSize: '8px' }}>Endereço: Avenida Paisssandu, 718 - Andar 1, Zona 5, Maringá-PR</Text>
                                            <Text id="informacao-possui-brinde" style={{ fontSize: '8px', marginTop: '4px' }}>* Produto de brinde</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        </>
    );
};

export default PdfComprovanteTemplate;
