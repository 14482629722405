import { Autocomplete, Box, CircularProgress, DialogActions, DialogContent, DialogTitle, Icon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import CustomDialog from 'components/custom/CustomDialog';
import { CustomButton } from "components/roundedbutton/CustomButton";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { currencyOf } from "utils/util";
import VendaService from "../venda.service";
import { TCancelaPedido, TMotivo, TPedidoDialog } from "../vendas.model";


const initialState: TPedidoDialog = {
    cliente: null,
    entrega: 0,
    numeroPedido: 0,
    quantidadeTotal: 0,
    status: '',
    uuid: '',
    valorTotalBruto: 0,
    motivo: {
        descritivo: '',
        uuid: '',
        id: 0
    },
    motivos: [{ descritivo: '', id: 0, uuid: '' }],
    motivoFiltro: ''
};

const cancelaPedidoHeader = ["Status", "Pedido", "Cliente", "Quantidade Total", "Valor Total"];

const CancelaPedidoDialog = (props: TCancelaPedido) => {
    const [stateLocal, setStateLocal] = useState(initialState);
    const vendasService = VendaService();

    const getMotivos = useCallback(() => {
        vendasService.getMotivos()
            .then(({ data }: { data: Array<TMotivo>; }) => {
                setStateLocal(prevState => ({ ...prevState, motivos: data }));
            })
            .catch(() => { });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.itemRef.current?.uuidPedido) {
            vendasService.getPedido(props.itemRef.current!.uuidPedido)
                .then(({ data }: { data: TPedidoDialog; }) => {
                    setStateLocal(prevState => ({
                        ...prevState,
                        cliente: data.cliente,
                        entrega: data.entrega,
                        numeroPedido: data.numeroPedido,
                        quantidadeTotal: data.quantidadeTotal,
                        status: data.status,
                        uuid: data.uuid,
                        valorTotalBruto: data.valorTotalBruto
                    }));
                })
                .catch(() => { });
        }
        getMotivos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSelectMotivo = useCallback((e: SyntheticEvent<Element, Event>, value: TMotivo | null) => {
        if (value) { return setStateLocal(prevState => ({ ...prevState, motivo: value })); }
        return setStateLocal(prevState => ({ ...prevState, motivo: initialState.motivo }));
    }, []);

    const onInputChangeMotivosAc = (e: SyntheticEvent<Element, Event>, value: string) => {
        setStateLocal(prevState => ({ ...prevState, motivoFiltro: value }));
    };

    const motivosOptions = () => {
        const motivos = stateLocal.motivos;
        const filtro = stateLocal.motivoFiltro.toLowerCase();

        if (!filtro) {
            return motivos;
        }

        return motivos.filter(e =>
            e.descritivo.toLowerCase().includes(filtro)
        );
    };
    const onBlurMotivos = () => {
        if (!stateLocal.motivo.descritivo.length) {
            getMotivos();
        }
    };

    return (
        <CustomDialog
            // id="cadastro-pessoa-dialog"
            open={true}
            fullWidth={true}
            maxWidth={'md'}
            onClose={props.onCloseCancelarModal}
        >
            <DialogTitle sx={{ textAlign: 'center', fontSize: '24px', fontWeight: '600' }}>Deseja cancelar o pedido?</DialogTitle>
            <DialogContent sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <TableContainer sx={{ width: '100%' }}>
                    <Table id="content-table"
                        stickyHeader
                        size="small"
                        sx={{ tableLayout: "fixed" }}
                    >
                        <TableHead sx={{ width: '100%', bgcolor: 'green' }}>
                            <TableRow>
                                {cancelaPedidoHeader.map((e) => (
                                    <TableCell
                                        sx={{ whiteSpace: 'nowrap', textAlign: 'center', fontWeight: 'bold' }}
                                        key={`cancelamento_${e}`}
                                    >
                                        {e}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }} key={`cancelamento-status`}>{props.itemRef.current?.statusPedido}</TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }} key={`cancelamento-numero-pedido`}>{props.itemRef.current?.numeroPedido}</TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }} key={`cancelamento-cliente`}>{props.itemRef.current?.cliente.substring(0, 15)} {props.itemRef.current?.cliente && props.itemRef.current?.cliente.length >= 15 && "..."}</TableCell>
                            {stateLocal.quantidadeTotal ?
                                <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }} key={`cancelamento-qtd-pecas`}>{stateLocal.quantidadeTotal} PC</TableCell>
                                :
                                <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }} key={`cancelamento-qtd-pecas-loading`}>
                                    <CircularProgress
                                        size={18}
                                        color='warning'
                                    />
                                </TableCell>
                            }
                            {stateLocal.valorTotalBruto ?
                                <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }} key={`cancelamento-qtd-pecas-monetario`}>{currencyOf(stateLocal.valorTotalBruto)}</TableCell>
                                :
                                <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'center' }} key={`cancelamento-qtd-pecas-loading-dois`}>
                                    <CircularProgress
                                        size={18}
                                        color='warning'
                                    />
                                </TableCell>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Autocomplete
                    style={{ width: '100%', marginTop: '20px' }}
                    onBlur={onBlurMotivos}
                    id="auto-complete-motivos-cancelamento"
                    value={stateLocal.motivos.find(option => option.uuid === stateLocal.motivo.uuid) || null}
                    options={motivosOptions()}
                    getOptionLabel={(option) => option.descritivo}
                    isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                    onInputChange={onInputChangeMotivosAc}
                    onChange={onSelectMotivo}
                    filterOptions={(x) => x}
                    noOptionsText={'Nenhum resultado encontrado'}
                    renderInput={
                        (params) => <TextField  {...params}
                            label="Motivo *"
                            variant="standard"
                        />}
                />

            </DialogContent>
            <DialogActions
                sx={{
                    flexDirection: 'column',
                }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: '20px' }}>
                    <CustomButton onClick={props.onCloseCancelarModal} variant="contained" color="error" startIcon={<Icon className='fa-solid fa-xmark' />} >
                        Não
                    </CustomButton>
                    <CustomButton onClick={() => {
                        props.onCancelaPedido(props.itemRef.current!.uuidPedido, stateLocal.motivo);
                        props.onCloseCancelarModal();
                    }} type="submit" variant="contained" color="info" startIcon={<Icon className='fa-solid fa-check' />}
                        disabled={stateLocal.motivo.descritivo.length === 0}>
                        Sim
                    </CustomButton>
                </Box>
            </DialogActions>
        </CustomDialog>
    );
};

export default CancelaPedidoDialog;
