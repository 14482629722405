import { IAbstractModel } from "model/abstract.model";
import { ConfiguracaoValePresente } from "pages/Configuracoes/configuracaovalepresente/configuracaovalepresente.model";
import { IPagamentoRow } from "pages/pagamento/pagamento.model";
import { getOneKeyOfEnum } from "utils/util";


export type ValePresenteTO = {
    itensPagamento: Array<IPagamentoRow>;
} & ValePresenteDialogFormState;
export interface ValePresenteDialogProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export type ValePresenteState = {
    consumidoresOptions: Array<IAbstractModel>;
    submitted: boolean;
    configValePresente: ConfiguracaoValePresente | null;
};

export type ValePresenteDialogFormState = {
    cliente: IAbstractModel;
    destinatario?: string;
    valor: number;
    validade: string | Date;
};

export type ValePresenteResponseTO = {
    code: string;
    valor: number;
    validade: string | Date;
    destinatario: string;
    cliente: string;
    estabelecimento: string;
};

export type BaseValePresenteTO = {
    uuid: string;
    descricao: string;
    emissao: string | Date;
    vencimento: string | Date;
    valorAberto: number;
    isVencido: boolean
};

export interface ContaPagarValePresenteTO extends BaseValePresenteTO {
    valorUtilizado: number;
    permiteParcial: boolean;
};

export interface VisualizacaoContaPagarValePresenteTO extends BaseValePresenteTO {
    pessoa: string;
    documento: number;
    parcela: number;
    tipo: string;
    status: string;
    valor: number;
    competencia: string | Date;
}

export enum LabelsDialogVisualizar {
    DESCRICAO = "Descrição",
    PESSOA = "Pessoa",
    DOCUMENTO = "Número do documento",
    PARCELA = "Parcela",
    TIPO = "Tipo",
    STATUS = "Status parcela",
    EMISSAO = "Emissão",
    COMPETENCIA = "Competência",
    VENCIMENTO = "Vencimento",
    VALOR = "Valor",
    VALORABERTO = "Valor em aberto",
};

const getKey = (key: LabelsDialogVisualizar) => {
    return getOneKeyOfEnum(key, LabelsDialogVisualizar);
};

export const labelsDialogVisualizarProps: { [key: string]: { type: 'string' | 'number' | 'date' | 'currency', label: LabelsDialogVisualizar; }; } = {
    [getKey(LabelsDialogVisualizar.DESCRICAO)]: {
        type: 'string',
        label: LabelsDialogVisualizar.DESCRICAO,
    },
    [getKey(LabelsDialogVisualizar.PESSOA)]: {
        type: 'string',
        label: LabelsDialogVisualizar.PESSOA,
    },
    [getKey(LabelsDialogVisualizar.DOCUMENTO)]: {
        type: 'number',
        label: LabelsDialogVisualizar.DOCUMENTO,
    },
    [getKey(LabelsDialogVisualizar.PARCELA)]: {
        type: 'number',
        label: LabelsDialogVisualizar.PARCELA,
    },
    [getKey(LabelsDialogVisualizar.TIPO)]: {
        type: 'string',
        label: LabelsDialogVisualizar.TIPO,
    },
    [getKey(LabelsDialogVisualizar.STATUS)]: {
        type: 'string',
        label: LabelsDialogVisualizar.STATUS,
    },
    [getKey(LabelsDialogVisualizar.EMISSAO)]: {
        type: 'string',
        label: LabelsDialogVisualizar.EMISSAO,
    },
    [getKey(LabelsDialogVisualizar.COMPETENCIA)]: {
        type: 'date',
        label: LabelsDialogVisualizar.COMPETENCIA,
    },
    [getKey(LabelsDialogVisualizar.VENCIMENTO)]: {
        type: 'string',
        label: LabelsDialogVisualizar.VENCIMENTO,
    },
    [getKey(LabelsDialogVisualizar.VALOR)]: {
        type: 'currency',
        label: LabelsDialogVisualizar.VALOR,
    },
    [getKey(LabelsDialogVisualizar.VALORABERTO)]: {
        type: 'currency',
        label: LabelsDialogVisualizar.VALORABERTO,
    },
};

export type ValePresenteFiltroAvancadoTO = {
    uuidPessoa: string | null;
    destinatario: string | null;
    vencimentoInicio: Date | string;
    vencimentoFim: Date | string;
    valorInicial: number | null;
    valorFinal: number | null;
};
