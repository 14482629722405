import { Autocomplete, AutocompleteInputChangeReason, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Icon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import CustomDialog from 'components/custom/CustomDialog';
import { CustomButton } from 'components/roundedbutton/CustomButton';
import { useSnackbarCustom } from 'hooks/useSnackCustom';
import { IAbstractModel } from 'model/abstract.model';
import { BaseValePresenteTO } from 'pages/vale-presente/vale-presente.model';
import ValePresenteService from 'pages/vale-presente/vale-presente.service';
import { FormEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Tipo, completeLoading, loadingWithOpt } from "redux/features/blockuiSlice";
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import MotivoService from 'service/motivo.service';
import { currencyOf, toLocaleDateString } from 'utils/util';
import CaixaService from '../caixa.service';
import { setCaixa } from 'redux/features/caixaSlice';

type VisualizarDialogProps = {
    open: boolean;
    onClose: () => void;
    row: BaseValePresenteTO;
};

const initialState = {
    motivosAc: [] as Array<IAbstractModel>,
    motivo: null as IAbstractModel | null,
    submitted: false,
};

const ExcluirDialog = (props: VisualizarDialogProps) => {
    const service = ValePresenteService();
    const motivoService = MotivoService();
    const motivoRef = useRef<HTMLInputElement>(null);
    const [localState, setLocalState] = useState(initialState);
    const { addSuccess, addError } = useSnackbarCustom();
    const dispatch = useAppDispatch();


    useEffect(() => {
        if (props.open) {
            getMotivosAc();
            setTimeout(() => {
                motivoRef.current?.focus();
            },);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);


    const getMotivosAc = (toSearch: string = '') => {
        motivoService.getMotivosFinanceirosAc(toSearch)
            .then(({ data }) => {
                setLocalState(prev => ({ ...prev, motivosAc: data.values }));
            });
    };

    const onInputChangeMotivos = (e: SyntheticEvent<Element, Event>, value: string = '', reason: AutocompleteInputChangeReason) => {
        if (reason !== 'reset') {
            getMotivosAc(value);
        }
    };

    const onSelectMotivos = (e: SyntheticEvent<Element, Event>, motivo: IAbstractModel | null) => {
        setLocalState(prev => ({ ...prev, motivo }));
    };

    const caixaService = CaixaService();
    const { caixa: caixaState } = useAppSelector(e => e.caixa.caixaAtual);

    const onConfirm = (e: FormEvent) => {
        e.preventDefault();
        setLocalState(prev => ({ ...prev, submitted: true }));

        if (!localState.motivo) {
            return motivoRef.current?.focus();
        }

        dispatch(loadingWithOpt({
            loading: true,
            tipo: Tipo.CIRCULAR,
            zIndexCustom: 1000,
        }));

        service.cancelarVale(props.row.uuid, localState.motivo!.uuid)
            .then(() => {
                onCloseLocal();
                if (caixaState) {
                    caixaService.getSaldoConta(caixaState?.uuid)
                        .then(({ data }) => {
                            dispatch(setCaixa({
                                caixa: data.caixa,
                                contaSelecionada: null,
                                saldo: data.saldo,
                                dataAbertura: data.dataAbertura,
                                caixaAberto: data.caixaAberto
                            }));
                        })
                        .catch(() => { });
                }
                addSuccess({
                    id: 'cancelar-sucess',
                    message: 'Vale cancelado com sucesso',
                    closeable: true,
                    hideDuration: 3000,
                    persist: false,
                    preventDuplicate: true,
                });
            })
            .catch(() => {
                addError({
                    id: 'cancelar-error',
                    message: 'Erro ao cancelar o vale',
                    closeable: true,
                    hideDuration: 3000,
                    persist: false,
                    preventDuplicate: true,
                });
            })
            .finally(() => {
                dispatch(completeLoading());
            });

    };

    const onCloseLocal = () => {
        props.onClose();
        setTimeout(() => {
            setLocalState(initialState);
        }, 200);
    };

    return (
        <CustomDialog
            open={props.open}
            onClose={onCloseLocal}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>
                <Typography textAlign="center" fontSize={28}>Cancelar vale presente?</Typography>
            </DialogTitle>

            <form onSubmit={onConfirm}>
                <DialogContent sx={{ paddingX: 0, display: 'flex', flexDirection: 'column', rowGap: '16px', overflow: 'hidden' }}>
                    <TableContainer sx={{ width: '100%' }}>
                        <Table
                            id="content-table"
                            stickyHeader
                            size="small"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Descrição</TableCell>
                                    <TableCell align="center">Data emissão</TableCell>
                                    <TableCell align="center">Data vencimento</TableCell>
                                    <TableCell align="center">Valor</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                    <TableCell>{props.row.descricao}</TableCell>
                                    <TableCell align="center">{toLocaleDateString(props.row.emissao)}</TableCell>
                                    <TableCell align="center">{toLocaleDateString(props.row.vencimento)}</TableCell>
                                    <TableCell align="center">{currencyOf(props.row.valorAberto)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>


                    <FormControl error={localState.submitted && !localState.motivo}>
                        <Autocomplete
                            value={localState.motivo}
                            id="motivos-ac"
                            options={localState.motivosAc}
                            getOptionLabel={(option) => option.descritivo}
                            onInputChange={onInputChangeMotivos}
                            filterOptions={(x) => x}
                            noOptionsText={'Nenhum resultado encontrado'}
                            onChange={onSelectMotivos}
                            isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.uuid}>
                                        {option.descritivo}
                                    </li>
                                );
                            }}
                            renderInput={
                                (params) => <TextField {...params}
                                    error={localState.submitted && !localState.motivo}
                                    label="Motivo *"
                                    variant="standard"
                                    inputRef={motivoRef}
                                />}
                        />

                        {(localState.submitted && !localState.motivo) &&
                            <FormHelperText sx={{ margin: 0, paddingX: 0 }} id='motivo-ac-helper-text' color={'error'}>{'Este campo é obrigatório'}</FormHelperText>
                        }
                    </FormControl>
                </DialogContent>

                <DialogActions sx={{ paddingX: '0', justifyContent: 'space-between' }}>
                    <CustomButton
                        onClick={onCloseLocal}
                        variant="contained"
                        color="error"
                        startIcon={
                            <Icon className={`fa-solid fa-xmark`} />}
                    >
                        Não
                    </CustomButton>

                    <CustomButton
                        type='submit'
                        variant="contained"
                        color="success"
                        startIcon={
                            <Icon className={`fa-solid fa-check`} />}
                    >
                        Sim
                    </CustomButton>
                </DialogActions>
            </form>
        </CustomDialog>
    );
};

export default ExcluirDialog;
