import Topbar from 'layout/topbar/Topbar';
import { Outlet } from 'react-router-dom';
import Stepper from 'layout/stepper/StepperComponent';
import { useAppSelector } from 'redux/hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';

const initialState = {
    showConfiguracoes: false,
    refreshCounter: 0
};

const BaseLayout = () => {
    const { activeStep, stepsLabel } = useAppSelector(s => s.stepper);
    const [stateLocal, setStateLocal] = useState(initialState);
    const canPressButton = useRef(true);

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'F5' && !e.ctrlKey) {
            e.preventDefault();
            if (canPressButton.current) {
                canPressButton.current = false;
                setStateLocal(prevState => ({ ...prevState, refreshCounter: stateLocal.refreshCounter += 1 }));
                setTimeout(() => {
                    canPressButton.current = true;
                }, 1000);
            }
        }
    };


    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onOpen = useCallback(() => {
        setStateLocal(prevState => ({ ...prevState, showConfiguracoes: true }));
    }, []);

    const onClose = useCallback(() => {
        setStateLocal(prevState => ({ ...prevState, showConfiguracoes: false }));
    }, []);

    return (
        <>
            <Topbar
                showConfiguracoes={stateLocal.showConfiguracoes}
                onOpen={onOpen}
                onCloseConfiguracoesMenu={onClose}
            />
            <Box onClick={() => stateLocal.showConfiguracoes && onClose()}>
                <Stepper
                    activeStep={activeStep}
                    stepsLabel={stepsLabel}
                />
                <Outlet key={stateLocal.refreshCounter} />
            </Box>
        </>

    );
};

export default BaseLayout;
