import { Delete } from "@mui/icons-material";
import { TableRow, TableCell, TextField, IconButton, Icon, Tooltip } from "@mui/material";
import DescontoInput from "pages/registra-venda/DescontoInput";
import { headerWidths, IProdutoRow } from "pages/registra-venda/registra-venda.model";
import { memo } from "react";
import { globalStyles } from "styles/global";
import useCustomLocation from "utils/useCustomLocation";
import { currencyOf } from "utils/util";

type ItemRegistraVendaTrocaProps = {
    row: IProdutoRow;
    onChangeQuantidadeTroca: (uuid: string, value: string) => void;
    changeDescontoTroca: (value: number, uuid: string) => void;
    toggleTipoDescontoTroca: (uuid: string) => void;
    onBlurDescontoItemTroca: (value: number, uuid: string) => void;
    saveTempDescontoTroca: (prevDesconto: number, uuid: string) => void;
    onClickDeleteTroca: (uuid: string, nomeProduto: string) => void;
    isVisualizar: boolean
};

const ItemRegistraVendaTroca = (props: ItemRegistraVendaTrocaProps) => {
    const {
        isTelaRegistraVenda
    } = useCustomLocation();

    return (
        <TableRow
            key={props.row.produto.codigoSku + props.row.uuid}
            sx={{ '&:last-child td, &:last-child th': { border: 0 }, bgcolor: '#FF9F9F' }}
        >
            <TableCell sx={{
                width: headerWidths.produtoVenda,
                minWidth: headerWidths.produtoVenda,
                maxWidth: headerWidths.produtoVenda,
                color: props.isVisualizar ? globalStyles.DISABLED_GREY : globalStyles.PRETO,
                padding: 0,
                paddingLeft: '15px'
            }}>
                {`${props.row.produto.codigoSku} - ${props.row.produto.nome} / ${props.row.produto.cor} / ${props.row.produto.tamanho}`}
            </TableCell>

            <TableCell align="center" sx={{
                width: headerWidths.estoque,
                minWidth: headerWidths.estoque,
                maxWidth: headerWidths.estoque,
                padding: 0,
                paddingLeft: '10px',
                textAlign: 'center',
                color: props.isVisualizar ? globalStyles.DISABLED_GREY : globalStyles.PRETO
            }}>
                -
            </TableCell>

            <TableCell align="center" sx={{
                width: headerWidths.quantidade,
                color: props.isVisualizar ? globalStyles.DISABLED_GREY : globalStyles.PRETO,
                minWidth: headerWidths.quantidade,
                maxWidth: headerWidths.quantidade,
                padding: 0,
                textAlign: 'center',
                paddingBottom: '10px'
            }}>
                <TextField
                    disabled={props.isVisualizar}
                    inputProps={{ inputMode: 'numeric', min: 1, style: { textAlign: 'center' } }}
                    value={props.row.quantidade}
                    onKeyDown={e => e.key === '.' && e.preventDefault()}
                    onChange={q => { props.onChangeQuantidadeTroca(props.row.uuid, q.target.value); }
                    }
                    variant="standard"
                    type="number"
                />
            </TableCell>

            <TableCell align="center" sx={{
                width: headerWidths.valorUnitario,
                color: props.isVisualizar ? globalStyles.DISABLED_GREY : globalStyles.PRETO,
                minWidth: headerWidths.valorUnitario,
                maxWidth: headerWidths.valorUnitario,
                padding: 0,
                textAlign: 'center'
            }}   >
                {currencyOf(props.row.produto.preco)}
            </TableCell>

            <TableCell align="center" sx={{
                width: headerWidths.desconto,
                color: props.isVisualizar ? globalStyles.DISABLED_GREY : globalStyles.PRETO,
                minWidth: headerWidths.desconto,
                maxWidth: headerWidths.desconto,
                padding: 0,
                textAlign: 'center',
                paddingBottom: '18px'
            }}>
                <DescontoInput
                    isVisualizar={props.isVisualizar}
                    item={{
                        tipoDesconto: props.row.itemDesconto.tipoDesconto,
                        valorDesconto: props.row.itemDesconto.valorDesconto,
                        percentualDesconto: props.row.itemDesconto.percentualDesconto,
                        valorTotalBruto: props.row.produto.preco * props.row.quantidade,
                        uuid: props.row.uuid,
                        onChangeDesconto: props.changeDescontoTroca,
                    }}
                    inputProps={{ style: { textAlign: 'center', } }}
                    iconButtonProps={{
                        onToggleTipoDesconto: props.toggleTipoDescontoTroca,
                        percentoProps: {
                            color: '#404040',
                        },
                        valorProps: {
                            color: '#404040',
                        }
                    }}
                    onBlur={props.onBlurDescontoItemTroca}
                    onSaveTempDesconto={props.saveTempDescontoTroca}
                />
            </TableCell>

            <TableCell align="center" sx={{
                color: (props.row.isBrinde || props.isVisualizar) ? globalStyles.DISABLED_GREY : globalStyles.PRETO,
                width: headerWidths.total,
                minWidth: headerWidths.total,
                maxWidth: headerWidths.total,
                padding: 0,
                textAlign: 'center'
            }}>
                {currencyOf(props.row.valorTotal)}
            </TableCell>

            <TableCell align="right" sx={{
                color: (props.row.isBrinde || props.isVisualizar) ? globalStyles.DISABLED_GREY : globalStyles.PRETO,
                width: headerWidths.brinde,
                minWidth: headerWidths.brinde,
                maxWidth: headerWidths.brinde,
                textAlign: 'center'
            }}>
                {isTelaRegistraVenda() &&
                    <IconButton
                        disabled={props.isVisualizar}
                        style={{ color: "green" }}
                    >
                        <Tooltip title="Produto da troca">
                            <Icon className="fa-solid fa-retweet" sx={{ color: "green", width: "30px" }} />
                        </Tooltip>
                    </IconButton>
                }
            </TableCell>

            <TableCell align="right" sx={{
                width: headerWidths.lixeira,
                minWidth: headerWidths.lixeira,
                maxWidth: headerWidths.lixeira,
                textAlign: 'center'
            }}>
                <IconButton
                    disabled={props.isVisualizar}
                    onClick={() => props.onClickDeleteTroca(props.row.uuid, props.row.produto.nome)}
                    style={{ color: props.isVisualizar ? "#9F9F9F" : "#404040", }}
                >
                    <Delete />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default memo(ItemRegistraVendaTroca);
