import { FormControl, FormHelperText, TextField } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { NumericFormat } from 'react-number-format';
import { useAppSelector } from 'redux/hooks';
import { roundHalfToEven } from 'utils/util';
import { FormaPagamentoDialogContentProps } from '../formapagamento.model';
import FaltaReceberComponent from 'components/FaltaReceberComponent';

const GenericDialogContent = ({ error, valorRecebido, isNew, id }: FormaPagamentoDialogContentProps) => {
    const { hasError, setHasError } = error;
    const { dadosPagamento, setDadosPagamento } = valorRecebido;
    const { faltaReceber } = useAppSelector(s => s.pagamento);
    const { tableRows } = useAppSelector(e => e.pagamento)

    useEffect(() => {
        if (isNew) setDadosPagamento(prev => ({ valorRecebido: faltaReceber, infoAdicional: undefined }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tableRowsValue = useMemo(() => {
        return tableRows.filter(e => !e.isPago).reduce((prev, act) => prev += act.valor, 0)
    }, [tableRows])

    const onChangeValorRecebido = (value: number = 0) => {
        if (roundHalfToEven(value + tableRowsValue, 2) > roundHalfToEven(faltaReceber, 2)) {
            setHasError(() => true);
        } else {
            setHasError(() => false);
        }
        setDadosPagamento(prev => ({ ...prev, valorRecebido: value }));
    };

    useEffect(() => {
        setDadosPagamento(prevState => ({
            ...prevState,
            valorRecebido: prevState.valorRecebido - tableRowsValue
        }))
        //eslint-disable-next-line
    }, [])

    return (
        <>
            <FormControl
                fullWidth
                variant="standard"
                sx={{ width: '15rem' }}
                error={hasError}
            >

                <NumericFormat
                    id="generic_forma_pagamento_div"
                    value={dadosPagamento.valorRecebido}
                    onValueChange={({ floatValue }) => onChangeValorRecebido(floatValue)}
                    error={hasError}
                    decimalScale={2}
                    fixedDecimalScale
                    defaultValue={0}
                    allowedDecimalSeparators={[',']}
                    customInput={TextField}
                    decimalSeparator=","
                    prefix={'R$ '}
                    thousandsGroupStyle="thousand"
                    thousandSeparator="."
                    variant="standard"
                    allowNegative={false}
                    inputProps={{
                        id: "generic_forma_pagamento_value",
                        style: {
                            textAlign: 'center',
                            fontSize: '24px',
                            color: hasError ? 'var(--error)' : '#df6e0b',
                            fontWeight: 700,
                            fontFamily: 'Work Sans, sans-serif'
                        }
                    }}
                />

                {hasError &&
                    <FormHelperText
                        sx={{
                            whiteSpace: 'nowrap'
                        }}
                    >
                        O valor não pode ser maior que a venda :(
                    </FormHelperText>}
                {!hasError && <FormHelperText> </FormHelperText>}
            </FormControl>
            <FaltaReceberComponent
                valorRecebido={dadosPagamento.valorRecebido}
                tableRowsValue={tableRowsValue}
            />

        </>
    );
};

export default GenericDialogContent;
