import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { Autocomplete, Box, Breakpoint, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useSnackbarCustom } from 'hooks/useSnackCustom';
import { GenericTO } from "model/generic.model";
import { IProduto } from 'pages/registra-venda/registra-venda.model';
import RegistraVendaService from 'pages/registra-venda/registra-venda.service';
import React, { SyntheticEvent, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useAppSelector } from "redux/hooks";
import ConsultaEstoqueService, { TConsultaEstoque } from "./ConsultaEstoque.service";

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CloseIcon from '@mui/icons-material/Close';
import DialogContext from 'components/confirm-dialog-context';
import { IAbstractModel } from 'model/abstract.model';
import EstabelecimentoService from 'service/Estabelecimento.service';
import { currencyOf } from 'utils/util';
import Sound from '../../assets/barcode_error.mp3';
import BoxesStacked from './BoxesStacked';
import { CustomButton } from 'components/roundedbutton/CustomButton';




interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClose?: () => void;
    maxWidth?: Breakpoint | false;
    fullWidth?: boolean;
}

const BEEPSOUND = new Audio(Sound);

const ConsultaEstoque = (props: Props) => {
    const dialog = useContext(DialogContext);

    const configuracaoBalcao = useAppSelector(e => e.configuracoes.configuracaoPedidoBalcao);
    const registraVendaService = RegistraVendaService();
    const estabelecimentoService = EstabelecimentoService();
    const consultaEstoqueService = ConsultaEstoqueService();
    const { addError } = useSnackbarCustom();

    const procurandoPorProdutoRef = useRef(false);
    const codigoBarrasRef = useRef<HTMLInputElement>(null);
    const skuRef = useRef<HTMLInputElement>(null);
    const skusRef = useRef<boolean>(false);

    type TConsultaEstoqueInitialState = {
        rows: TConsultaEstoque[];
        tabelaPreco: IAbstractModel | null;
        tabelasPreco: GenericTO[];
        skus: IProduto[];
        skuFiltro: string;
        skuSelecionada: IProduto | null;
        estabelecimentos: GenericTO[];
        estabelecimentosSelecionados: GenericTO[];
        footerQuantidadeTotal: number;
        procurandoPorProduto: boolean;
    };

    const initialState: TConsultaEstoqueInitialState = {
        rows: [],
        tabelaPreco: configuracaoBalcao.tabelaPreco,
        tabelasPreco: [],
        skus: [],
        skuFiltro: "",
        skuSelecionada: null,
        estabelecimentos: [],
        estabelecimentosSelecionados: [],
        footerQuantidadeTotal: 0,
        procurandoPorProduto: procurandoPorProdutoRef.current
    };


    const [stateLocal, setStateLocal] = useState(initialState);

    const handleClose = () => {
        props.setOpen(false);
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        switch (e.key) {
            case 'e':
                if (e.altKey) {
                    e.preventDefault();
                    props.setOpen(false);
                }
                break;
        }
    };

    const toggleAndResetProcurandoPorProduto = (conditionToToggle: boolean) => {
        if (conditionToToggle) {
            procurandoPorProdutoRef.current = !procurandoPorProdutoRef.current;
            setStateLocal(prevState => ({
                ...prevState,
                procurandoPorProduto: procurandoPorProdutoRef.current
            }));
        };
        resetFocus();
    };

    const resetFocus = () => {
        setTimeout(() => {
            if (procurandoPorProdutoRef.current) {
                skuRef.current?.focus();
            } else {
                codigoBarrasRef.current?.focus();
            }
        },);
    };

    const handleF4 = (e: KeyboardEvent) => {
        switch (e.key) {
            case 'F4':
                e.preventDefault();
                const conditionToToggle = document.activeElement?.id.indexOf('acSku') !== -1;
                toggleAndResetProcurandoPorProduto(conditionToToggle);
                break;
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleF4);
        return () => {
            window.removeEventListener('keydown', handleF4);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateLocal.procurandoPorProduto]);

    const getEstabelecimentoAc = () => {
        estabelecimentoService.getEstabelecimentosAtivosQuePossuemConfiguracaoPedidoBalcao()
            .then(({ data }) => {
                setStateLocal(prevState => ({
                    ...prevState,
                    estabelecimentos: data,
                    stabelecimentosSelecionados: data
                }));
            }).catch(error => {
                addError({ message: error.response.data.extra[0], closeable: true });
            });
    };

    const onSelectEstabelecimento = (e: SyntheticEvent<Element, Event>, value: any) => {
        if (!value) return;
        setStateLocal(prevState => ({
            ...prevState,
            estabelecimentosSelecionados: value
        }));
    };

    const getSkusAc = (toSearch: string = '') => {
        if (!configuracaoBalcao.tabelaPreco) return;
        registraVendaService.getSkusAc(toSearch, stateLocal.tabelaPreco?.uuid ?? configuracaoBalcao.tabelaPreco!.uuid)
            .then(({ data }) => {
                setStateLocal(prevState => ({
                    ...prevState,
                    skus: data
                }));
            }).catch(error => {
                addError({ message: error.response.data.extra[0], closeable: true });
            });
    };

    const onInputCodigoBarras = (e: React.KeyboardEvent<HTMLDivElement>) => {
        const inputValue = codigoBarrasRef.current?.value;
        if (e.key === 'Enter' && inputValue) {
            registraVendaService.getProdutoByCodigo(inputValue, stateLocal.tabelaPreco?.uuid ?? configuracaoBalcao.tabelaPreco!.uuid)
                .then(({ data }) => {
                    const skuSelecionada: IProduto = data;
                    consultaEstoqueService.getConsultaEstoque(
                        data.uuid,
                        stateLocal.tabelaPreco?.uuid ?? configuracaoBalcao.tabelaPreco!.uuid,
                        stateLocal.estabelecimentosSelecionados.flatMap(e => e.uuid)
                    ).then(({ data }) => {
                        setStateLocal(prevState => ({
                            ...prevState,
                            rows: data,
                            skuSelecionada: skuSelecionada,
                            footerQuantidadeTotal: data.reduce((sum, row) => sum + row.sku.estoque.saldoEstoque, 0)
                        }));
                    }).catch((err) => {
                        addError({ message: err.response.data.extra[0], closeable: true });
                        resetConsultaEstoque();
                    });
                }).catch(error => {
                    BEEPSOUND.play();
                    dialog.confirm({
                        error: true,
                        title: 'Atenção',
                        message: `CÓDIGO DE BARRAS ${inputValue} NÃO ENCONTRADO NA TABELA DE PREÇO ${configuracaoBalcao.tabelaPreco?.descritivo ?? ""}`,
                        onConfirm: () => { }
                    });
                });
            codigoBarrasRef.current.value = '';
            resetFocus();
        }
    };

    const skusOptions = () => {
        const skusList = Array.isArray(stateLocal.skus) ? stateLocal.skus : [];
        const filtro = stateLocal.skuFiltro;

        if (!filtro) { return skusList; }

        const toReturn = skusList.filter(e => {
            let skuFormat = `${e.codigoSku}/${e.nome}/${e.cor}/${e.tamanho}`;
            return skuFormat.toLowerCase().includes(filtro.toLowerCase());
        });

        if (!toReturn.length && filtro) {
            skusRef.current = true;
            return skusList;
        }

        return toReturn;
    };

    useEffect(() => {
        if (skusRef.current || !stateLocal.skuFiltro.length) {
            skusRef.current = false;
            getSkusAc(stateLocal.skuFiltro);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skusRef.current, stateLocal.skuFiltro]);

    const onBlurSkus = () => {
        if (!stateLocal.skuFiltro) {
            getSkusAc();
        }
    };

    const onInputChangeSkuAc = useCallback((e: SyntheticEvent<Element, Event>, value: string) => {
        setStateLocal(prevState => ({ ...prevState, skuFiltro: value }));
    }, []);

    const onSelectProduto = (e: SyntheticEvent<Element, Event> | null, value: IProduto | null) => {
        if (!value) return;
        consultaEstoqueService.getConsultaEstoque(
            value.uuid,
            stateLocal.tabelaPreco?.uuid ?? configuracaoBalcao.tabelaPreco!.uuid,
            stateLocal.estabelecimentosSelecionados.flatMap(e => e.uuid)
        ).then(({ data }) => {
            setStateLocal(prevState => ({
                ...prevState,
                rows: data,
                footerQuantidadeTotal: data.reduce((sum, row) => sum + row.sku.estoque.saldoEstoque, 0)
            }));
            setStateLocal(prevState => ({ ...prevState, skuSelecionada: value }));
        }).catch((err) => {
            addError({ message: err.response.data.extra[0], closeable: true });
            resetConsultaEstoque();
        });

    };

    const getTabelasPrecosAc = useCallback((toSearch: string = ' ') => {
        consultaEstoqueService.getTabelaPreco(toSearch)
            .then(({ data }) => {
                setStateLocal(prevState => ({ ...prevState, tabelasPreco: data }));
            }).catch(() => { });
        //eslint-disable-next-line
    }, []);

    const onInputChangeTabelaPrecoAc = useCallback((e: React.ChangeEvent<{}>, value: string) => {
        getTabelasPrecosAc(value);
        //eslint-disable-next-line
    }, []);

    const onSelectTabelaPreco = useCallback((e: SyntheticEvent<Element, Event>, value: GenericTO | null) => {
        if (!value) return;
        setStateLocal(prevState => ({ ...prevState, tabelaPreco: value }));
        //eslint-disable-next-line
    }, []);

    const resetConsultaEstoque = useCallback(() => {
        setStateLocal(prevState => ({ ...prevState, rows: [], skuSelecionada: null, footerQuantidadeTotal: 0 }));
        resetFocus();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (props.open === true) {
            getTabelasPrecosAc();
            getSkusAc();
            getEstabelecimentoAc();
            resetFocus();
            setStateLocal(prevState => ({
                ...prevState,
                tabelaPreco: configuracaoBalcao.tabelaPreco
            }));
        }
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);

    useEffect(() => {
        onSelectProduto(null, stateLocal.skuSelecionada);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateLocal.tabelaPreco, stateLocal.estabelecimentosSelecionados]);

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'xl'}
                open={props.open}
                onClose={handleClose}
            >
                <DialogTitle style={{ textAlign: 'center', color: 'var(--laranja)' }}>
                    <Container
                        maxWidth="xl"
                        sx={{
                            marginTop: '20px',
                        }}>
                        <Box sx={{
                            width: '100%',
                            borderTop: '1px solid rgba(0, 0, 0, .1)',
                            borderBottom: '1px solid rgba(0, 0, 0, .1)',
                            padding: '15px 0',
                            display: 'flex',
                            justifyContent: 'center',
                        }}>

                            <Box id='vendaScreenBox' sx={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                <BoxesStacked id='vendaShoppingCarIcon' color='var(--laranja)' fontSize='45px' />
                                <Typography id='vendaScreenTitle' sx={{ paddingLeft: '25px', fontSize: '45px', fontWeight: '600', color: 'var(--laranja)', userSelect: 'none' }}>Consulta estoque</Typography>
                            </Box>
                        </Box>
                    </Container >
                </DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={4} lg={4} padding={1}>
                            <Autocomplete
                                value={stateLocal.tabelaPreco}
                                disablePortal
                                id="acTabelaPreco"
                                options={stateLocal.tabelasPreco}
                                getOptionLabel={(option) => option.descritivo}
                                onInputChange={onInputChangeTabelaPrecoAc}
                                filterOptions={(x) => x}
                                noOptionsText={'Nenhum resultado encontrado'}
                                onChange={onSelectTabelaPreco}
                                isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                                renderInput={
                                    (params) => <TextField {...params}
                                        label="Tabela de preço"
                                        variant="standard"
                                        InputProps={{
                                            ...params.InputProps,
                                        }}
                                    />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} padding={1}>
                            {!stateLocal.procurandoPorProduto &&
                                <TextField
                                    fullWidth={true}
                                    variant="standard"
                                    placeholder={stateLocal.tabelaPreco?.descritivo ?? ""}
                                    label='Código de barras | Código do sku'
                                    id="acSku-inputConsultaEstoque"
                                    InputProps={{
                                        endAdornment: (
                                            <React.Fragment>
                                                <h4 className="h4-formatado">(F4)</h4>
                                                <IconButton
                                                    onClick={() => {
                                                        toggleAndResetProcurandoPorProduto(true);
                                                    }}
                                                    sx={{ height: 0, padding: 0, paddingLeft: '5px' }}
                                                >
                                                    <Icon className="fa-solid fa-barcode" />
                                                </IconButton>
                                            </React.Fragment>
                                        )
                                    }}
                                    onKeyUp={onInputCodigoBarras}
                                    inputRef={codigoBarrasRef}
                                />}
                            {stateLocal.procurandoPorProduto &&
                                <Autocomplete
                                    onBlur={onBlurSkus}
                                    disablePortal
                                    id="acSkuConslutaEstoque-input"
                                    options={skusOptions()}
                                    getOptionLabel={o => `${o.codigoSku}/${o.nome}/${o.cor}/${o.tamanho}`}
                                    isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                                    onInputChange={onInputChangeSkuAc}
                                    onChange={onSelectProduto}
                                    value={null}
                                    blurOnSelect={true}
                                    filterOptions={(x) => x}
                                    noOptionsText={'Nenhum resultado encontrado.'}
                                    renderInput={
                                        (params) => <TextField {...params}
                                            label="Nome tamanho cor do sku"
                                            variant="standard"
                                            placeholder={stateLocal.tabelaPreco?.descritivo}
                                            inputRef={skuRef}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        <h4 className="h4-formatado">(F4)</h4>
                                                        <IconButton
                                                            onClick={() => {
                                                                toggleAndResetProcurandoPorProduto(true);
                                                            }}
                                                            sx={{ height: 0, padding: 0, paddingLeft: '5px' }}
                                                        >
                                                            <LocalOfferIcon />
                                                        </IconButton>
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />}

                                />}
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} padding={1}>
                            <Autocomplete
                                multiple
                                id="multiAcEstabelecimentos"
                                options={stateLocal.estabelecimentos}
                                value={stateLocal.estabelecimentosSelecionados}
                                onChange={onSelectEstabelecimento}
                                disableCloseOnSelect
                                limitTags={1}
                                noOptionsText={'Nenhum resultado encontrado.'}
                                getOptionLabel={e => e.descritivo}
                                isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            checked={selected}
                                        />
                                        {option.descritivo}
                                    </li>
                                )
                                }
                                renderInput={(params) => (
                                    <TextField {...params} label="Estabelecimentos" variant='standard' />
                                )} sx={{ '.MuiAutocomplete-tag': { backgroundColor: '#ffff', height: '1.2rem' } }}
                            />
                        </Grid>
                    </Grid>
                    <TableContainer sx={{ height: 500 }} >
                        <Table sx={{ minWidth: 650 }} >
                            <TableHead>
                                <TableRow>
                                    <TableCell align='left'>Estabelecimento</TableCell>
                                    <TableCell align='left'>Produto</TableCell>
                                    <TableCell align="left">Quantidade</TableCell>
                                    <TableCell align="left">Depósito</TableCell>
                                    <TableCell align="left">Valor</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {stateLocal.rows.map((row, index) => (
                                    <TableRow
                                        key={'row_' + index}
                                        sx={{ height: '25px' }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.estabelecimento}
                                        </TableCell>
                                        <TableCell align='left'>{row.sku.descritivo}</TableCell>
                                        <TableCell align="left">{row.sku.estoque.saldoEstoque}</TableCell>
                                        <TableCell align="left">{row.sku.estoque.nomeDeposito}</TableCell>
                                        <TableCell align="left">{row.sku.preco ? currencyOf(row.sku.preco) : 'R$ -'}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            {stateLocal.rows.length > 0 && <TableFooter>
                                <TableRow>
                                    <TableCell align='left' colSpan={2}></TableCell>
                                    <TableCell colSpan={3} align="left" sx={{ padding: 1, paddingLeft: 2, fontWeight: 800, fontSize: 14 }}>{stateLocal.rows.length > 0 ? stateLocal.footerQuantidadeTotal ?? 0 : null}</TableCell>
                                </TableRow>
                            </TableFooter>
                            }
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <CustomButton id={"close_consulta_estoque_btn"}
                        onClick={handleClose}
                        variant="contained"
                        color="error"
                        startIcon={<Icon className={'fa-solid fa-close'} sx={{ width: 'fit-content' }} />}
                    >
                        Fechar
                    </CustomButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ConsultaEstoque;
