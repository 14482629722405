import { AxiosResponse } from "axios";
import { GenericTO } from "model/generic.model";
import LoginService from "pages/login/login.service";
import { axiosRequest } from "../axios/axios.config";


const EstabelecimentoService = () => {
    const loginService = LoginService();

    return {
        getEstabelecimentosAtivosQuePossuemConfiguracaoPedidoBalcao(): Promise<AxiosResponse<GenericTO[]>> {
            return axiosRequest.get(`estabelecimento/possuiconfiguracaopedidobalcao`,
                {
                    headers: loginService.getHeaders(),
                });
        },

    };

};

export default EstabelecimentoService;
