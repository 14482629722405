import { Chip, Icon, Tooltip } from "@mui/material";
import { IAbstractModel } from "model/abstract.model";
import { memo } from "react";
import { isAtivo } from "utils/util";

type CredenciadoraCardProps = {
    credenciadora: IAbstractModel,
    onSelectCredenciadora: (uuid: string) => void;
    credenciadoraSelecionada: IAbstractModel | null
}

const CredenciadoraCard = (props: CredenciadoraCardProps) => {

    return (
        <Tooltip
            title={props.credenciadora?.descritivo.concat(isAtivo(props.credenciadora.status) ? " - integração ativa" : " - integração inativa")}
            key={props.credenciadora?.uuid}
        >
            <Chip sx={{
                margin: '5px',
                bgcolor: 'transparent',
                border: '1px solid var(--laranja)',
                height: "50px",
                minWidth: '240px',
                padding: '10px',
                pointerEvents: props.credenciadoraSelecionada ? 'none' : 'auto',
                position: 'relative'
            }}
                label={props.credenciadora?.descritivo}
                key={props.credenciadora?.uuid}
                onClick={() => !props.credenciadoraSelecionada && props.onSelectCredenciadora(props.credenciadora.uuid)}
                icon={
                    <>
                        <Icon sx={{
                            fontSize: '18px',
                            width: '25px',
                            padding: '3px',
                            pointerEvents: props.credenciadoraSelecionada ? 'none' : 'auto',
                            color: isAtivo(props.credenciadora.status) ? "var(--laranja)" : undefined,
                        }}
                            className="fa-regular fa-credit-card"
                            color="action"
                        />
                        {isAtivo(props.credenciadora.status) &&
                            <img
                                src={require("../assets/cardMachine.png")}
                                alt="Máquina de cartão"
                                style={{
                                    width: "30px",
                                    height: "30px",
                                    position: 'absolute',
                                    right: 20
                                }}
                            />
                        }
                    </>
                }
            />
        </Tooltip>
    )
}

export default memo(CredenciadoraCard);